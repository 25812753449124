export const waitForEvent = function (eventName, eventObj) {
    console.log("Waiting for event...", eventName, eventObj);
    return new Promise(resolve => {
        eventObj.addEventListener(eventName, resolve, { once: true });
    });
};

export const waitForDOMContentLoaded = function () {
    if (
        document.readyState === "complete" ||
        document.readyState === "loaded" ||
        document.readyState === "interactive"
    ) {
        return Promise.resolve(null);
    } else {
        return waitForEvent("DOMContentLoaded", window);
    }
};

export const sleep = function (milliseconds) {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
};
