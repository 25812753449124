import React from "react";
import ToolbarButton from "./ToolbarButton";

export default function AddMediaButton(props) {
    if (!props.tutorial && !window.APP.objectHelper.can("can_create")) {
        return null;
    }

    return (
        <ToolbarButton
            variant="purple-dark"
            id="step-toolbar-add"
            icon={<i className="bi bi-plus-lg"></i>}
            tipTitle={"Add Content"}
            tipBody={"Add content"}
            onClick={() => window.APP.scene.emit("left_menu_changed", "upload_menu")}
            rootClose={true}
        />
    );
}
