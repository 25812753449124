import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";

const hubId = document.location.pathname.substring(1).split("/")[0];

const useAvatars = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [avatars, setAvatars] = useState(null);
    const [counter, setCounter] = useState(0);

    const refresh = () => {
        setCounter(counter + 1);
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_MM_API_URL + "avatars/")
            .then(resp => {
                if (!resp.ok) {
                    setAvatars(null);
                    setLoading(false);
                    setError(resp.status);
                    return;
                }
                return resp.json();
            })
            .then(data => {
                setAvatars(data);
                setLoading(false);
                ReactGA.send({ hitType: "pageview", page: hubId });
            })
            .catch(e => {
                console.error("Error", e);
                setAvatars(null);
                setError(e);
                setLoading(false);
            });
    }, [counter]);

    return [avatars, loading, error, refresh];
};

export default useAvatars;
