import React from "react";

import { ChatSystem } from "../ChatSystem";
// import ExportButton from "../buttons/ExportButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export default function LeftToolbar() {
    return (
        <ButtonGroup id="step-toolbar-chat">
            <ChatSystem />
        </ButtonGroup>
    );
}
