import { injectCustomShaderChunks } from "../utils/media-utils";
import { registerComponentInstance, deregisterComponentInstance } from "../utils/component-utils";
import { MediaDevicesEvents } from "../utils/media-devices-utils";
import { createHeadlessModelForSkinnedMesh } from "../utils/three-utils";
import { Layers } from "./layers";

function ensureAvatarNodes(json) {
    const { nodes } = json;
    if (!nodes.some(node => node.name === "Head")) {
        // If the avatar model doesn't have a Head node. The user has probably chosen a custom GLB.
        // So, we need to construct a suitable hierarchy for avatar functionality to work.
        // We re-parent the original root node to the Head node and set the scene root to a new AvatarRoot.

        // Note: We assume that the first node in the primary scene is the one we care about.
        const originalRoot = json.scenes[json.scene].nodes[0];
        nodes.push({ name: "LeftEye", extensions: { MOZ_hubs_components: {} } });
        nodes.push({ name: "RightEye", extensions: { MOZ_hubs_components: {} } });
        nodes.push({
            name: "Head",
            children: [originalRoot, nodes.length - 1, nodes.length - 2],
            extensions: { MOZ_hubs_components: { "scale-audio-feedback": "" } }
        });
        nodes.push({ name: "Neck", children: [nodes.length - 1] });
        nodes.push({ name: "Spine", children: [nodes.length - 1] });
        nodes.push({ name: "Hips", children: [nodes.length - 1] });
        nodes.push({ name: "AvatarRoot", children: [nodes.length - 1] });
        json.scenes[json.scene].nodes[0] = nodes.length - 1;
    }
    return json;
}

AFRAME.registerComponent("player-info", {
    schema: {
        avatarSrc: { type: "string" },
        avatarType: { type: "string", default: "skinnable" },
        muted: { default: false },
        isSharingAvatarCamera: { default: false }
    },
    init() {
        this.applyProperties = this.applyProperties.bind(this);
        this.handleRemoteModelError = this.handleRemoteModelError.bind(this);
        this.update = this.update.bind(this);
        this.onPresenceUpdated = this.onPresenceUpdated.bind(this);
        this.onMicStateChanged = this.onMicStateChanged.bind(this);
        this.onAvatarModelLoaded = this.onAvatarModelLoaded.bind(this);

        this.isLocalPlayerInfo = this.el.id === "avatar-rig";
        this.playerSessionId = null;

        if (!this.isLocalPlayerInfo) {
            NAF.utils.getNetworkedEntity(this.el).then(networkedEntity => {
                this.playerSessionId = NAF.utils.getCreator(networkedEntity);
                const playerPresence = window.APP.hubChannel.presence.state[this.playerSessionId];
                if (playerPresence) {
                    this.updateFromPresenceMeta(playerPresence.metas[0]);
                }
            });
        }

        registerComponentInstance(this, "player-info");
    },

    remove() {
        const avatarEl = this.el.querySelector("[avatar-audio-source]");
        APP.isAudioPaused.delete(avatarEl);
        deregisterComponentInstance(this, "player-info");
    },

    onAvatarModelLoaded(e) {
        this.applyProperties(e);
        const modelEl = this.el.querySelector(".model");
        if (this.isLocalPlayerInfo && e.target === modelEl) {
            let isSkinnedAvatar = false;
            modelEl.object3D.traverse(function (o) {
                if (o.isSkinnedMesh) {
                    const headlessMesh = createHeadlessModelForSkinnedMesh(o);
                    if (headlessMesh) {
                        isSkinnedAvatar = true;
                        o.parent.add(headlessMesh);
                    }
                }
            });
            // This is to support using arbitrary models as avatars.
            // TODO We can drop support for this when we go full VRM, or at least handle it earlier in the process.
            if (!isSkinnedAvatar) {
                modelEl.object3D.traverse(function (o) {
                    if (o.isMesh) o.layers.set(Layers.CAMERA_LAYER_THIRD_PERSON_ONLY);
                });
            }
        }
    },

    play() {
        this.el.addEventListener("model-loaded", this.onAvatarModelLoaded);
        this.el.sceneEl.addEventListener("presence_updated", this.onPresenceUpdated);
        window.APP.store.addEventListener("statechanged", this.update);

        this.el.sceneEl.addEventListener("stateadded", this.update);
        this.el.sceneEl.addEventListener("stateremoved", this.update);

        if (this.isLocalPlayerInfo) {
            APP.dialog.on("mic-state-changed", this.onMicStateChanged);
        }
    },

    pause() {
        this.el.removeEventListener("model-loaded", this.onAvatarModelLoaded);
        this.el.sceneEl.removeEventListener("presence_updated", this.onPresenceUpdated);
        this.el.sceneEl.removeEventListener("stateadded", this.update);
        this.el.sceneEl.removeEventListener("stateremoved", this.update);
        window.APP.store.removeEventListener("statechanged", this.update);

        if (this.isLocalPlayerInfo) {
            APP.dialog.off("mic-state-changed", this.onMicStateChanged);
        }
    },

    onPresenceUpdated(e) {
        this.updateFromPresenceMeta(e.detail);
    },

    updateFromPresenceMeta(presenceMeta) {
        if (!this.playerSessionId && this.isLocalPlayerInfo) {
            this.playerSessionId = NAF.clientId;
        }
        if (!this.playerSessionId) return;
        if (this.playerSessionId !== presenceMeta.sessionId) return;

        this.permissions = presenceMeta.permissions;
    },

    update(oldData) {
        if (this.data.muted !== oldData.muted) {
            this.el.emit("remote_mute_updated", { muted: this.data.muted });
        }
        this.applyProperties();
    },

    can(perm) {
        return !!this.permissions && this.permissions[perm];
    },

    applyProperties(e) {
        const modelEl = this.el.querySelector(".model");
        if (this.data.avatarSrc && modelEl) {
            modelEl.components["gltf-model-plus"].jsonPreprocessor = ensureAvatarNodes;
            modelEl.setAttribute("gltf-model-plus", "src", this.data.avatarSrc);
        }


        if (!e || e.target === modelEl) {
            const uniforms = injectCustomShaderChunks(this.el.object3D);
        }

        const videoTextureTargets = modelEl.querySelectorAll("[video-texture-target]");

        const sessionId = this.isLocalPlayerInfo ? NAF.clientId : this.playerSessionId;

        for (const el of Array.from(videoTextureTargets)) {
            el.setAttribute("video-texture-target", {
                src: this.data.isSharingAvatarCamera ? `hubs://clients/${sessionId}/video` : ""
            });

            if (this.isLocalPlayerInfo) {
                el.setAttribute("emit-scene-event-on-remove", `event:${MediaDevicesEvents.VIDEO_SHARE_ENDED}`);
            }
        }

        const avatarEl = this.el.querySelector("[avatar-audio-source]");
        if (this.data.muted) {
            APP.isAudioPaused.add(avatarEl);
        } else {
            APP.isAudioPaused.delete(avatarEl);
        }
    },

    handleRemoteModelError() {
        this.applyProperties();
    },

    onMicStateChanged({ enabled }) {
        this.el.setAttribute("player-info", { muted: !enabled });
    }
});
