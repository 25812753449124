import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

export default function PreferencesButton() {
    return (
        <>
            <Dropdown.Item onClick={() => window.APP.scene.emit("right_menu_changed", "preferences")}>
                <FontAwesomeIcon icon={faCog} color="#6f6ec4" />
                <span className="ms-2 mb-0">Preferences</span>
            </Dropdown.Item>
        </>
    );
}
