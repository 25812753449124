import { defineQuery, enterQuery, Changed } from "bitecs";
import { GeoGebra } from "../../../bit-components";

const geogebraQuery = defineQuery([GeoGebra]);
const geogebraEntry = enterQuery(geogebraQuery);
const changedGeoGebraQuery = defineQuery([Changed(GeoGebra)]);

const worldPos = new THREE.Vector3();

export function geogebraSystem(world) {
    geogebraEntry(world).forEach(function (eid) {
        const object3d = APP.getString(GeoGebra.obj3d[eid]);
        console.log("ADDED GEOGEBRA", object3d);
    });

    changedGeoGebraQuery(world).forEach(function (eid) {
        console.log("Got change src on GeoGebra");
        const object3d = APP.getString(GeoGebra.obj3d[eid]);

        object3d.element.src = APP.getString(GeoGebra.src[eid]);
    });

    geogebraQuery(world).forEach(function (eid) {
        const parent = world.eid2obj.get(eid);
        const geogebra = APP.getString(GeoGebra.obj3d[eid]);
        const placeholder = APP.getString(GeoGebra.placeholder[eid]);

        const avatarPovObject = document.getElementById("avatar-pov-node").object3D;
        avatarPovObject.getWorldPosition(worldPos);

        /*
        kahoot.position.set(parent.position.x * 1000, parent.position.y * 1000, parent.position.z * 1000);
        kahoot.rotation.set(parent.rotation.x, parent.rotation.y, parent.rotation.z);
        kahoot.scale.set(parent.scale.x, parent.scale.y, parent.scale.z);
        kahoot.updateMatrix();
        */

        geogebra.element.style.width = (parent.scale.x * 16 * 28).toString() + "px";
        geogebra.element.style.height = (parent.scale.y * 12 * 28).toString() + "px";

        const isNearby = worldPos.distanceTo(parent.position) < 0.8 + (parent.scale.x > 1 ? parent.scale.x * 0.4 : 1);

        if (!window.APP.editMode) {
            if (isNearby) {
                placeholder.visible = false;
                geogebra.element.style.pointerEvents = "all";
                geogebra.element.style.opacity = "1";
            } else {
                placeholder.visible = true;
                geogebra.element.style.pointerEvents = "none";
                geogebra.element.style.opacity = "0";
            }
        }
        if (window.APP.editMode) {
            if (isNearby) {
                placeholder.visible = true;
                geogebra.element.style.pointerEvents = "none";
                geogebra.element.style.opacity = "0.2";
            } else {
                placeholder.visible = true;
                geogebra.element.style.pointerEvents = "none";
                geogebra.element.style.opacity = "0";
            }
        }
    });
}

/*
 * o
        if (window.APP.editMode) {
            placeholder.visible = true;

            if (isNearby) {
                youtube.visible = true;
                youtube.element.style.pointerEvents = "none";
                youtube.element.style.opacity = "0.3";
            } else {
                youtube.visible = false;
                youtube.element.style.pointerEvents = "none";
                youtube.element.style.opacity = "0.3";
            }
        } else {

*/
