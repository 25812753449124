import React, { useState, useEffect, useRef } from "react";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Linkify from "linkify-react";
import ChatButton from "./buttons/ChatButton";

export function MobileChatLog(props) {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        window.APP.chatHelper.addEventListener("receive_message", event => {
            setMessages(oldMessages => [...oldMessages, event.detail]);
        });
    }, []);

    return (
        <Row
            className="position-fixed ms-2 bg-transparent rounded ignore-cursor"
            id="MobileChatLog"
            style={{
                left: 0,
                bottom: "92px"
            }}
        >
            <Col className="m-0 p-0">
                <table
                    style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                        alignItems: "start",
                        minHeight: "200px",
                        maxHeight: "200px",
                        overflowY: "scroll",
                        width: "300px"
                    }}
                    className="hide-scrollbar"
                >
                    <tbody className="text-rtl-reset">
                        {messages.slice(-5).map((message, index) => (
                            <tr key={index} className="d-flex justify-content-middle">
                                <td className="d-flex text-start pe-2 ignore-cursor">
                                    <span
                                        style={{ maxWidth: "130px" }}
                                        className="badge bg-semitransparent text-truncate"
                                    >
                                        {message.sender}
                                    </span>
                                </td>
                                <td className="text-white ignore-cursor">
                                    <p className="fw-bold mb-0">
                                        <Linkify
                                            options={{
                                                target: "_blank",
                                                rel: "noopener referrer",
                                                className: "clickable text-purple-dark"
                                            }}
                                        >
                                            {message.message}
                                        </Linkify>
                                    </p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Col>
        </Row>
    );
}

export function MobileChatSystem(props) {
    if (!window.APP.objectHelper.can("can_chat")) {
        return null;
    }
    const [showFullMenu, setShowFullMenu] = useState(false);
    const [text, setText] = useState("");

    const sendWithButton = () => {
        window.APP.chatHelper.send_message(text);
        setText("");
    };

    if (showFullMenu) {
        return (
            <div className="input-group w-100">
                <Button
                    variant="purple-dark"
                    onClick={e => {
                        props.showEverythingElse();
                        setShowFullMenu(false);
                    }}
                    className="input-group-text btn-purple-dark text-white border-0"
                >
                    <i className="bi bi-arrow-left" />
                </Button>
                <input
                    type="text"
                    maxLength="300"
                    className="form-control form-sm border-0"
                    id="floatingInputGroup1"
                    placeholder="Send Message"
                    value={text}
                    onChange={e => setText(e.target.value)}
                />
                <span onClick={e => sendWithButton()} className="input-group-text btn-purple-dark text-white border-0">
                    <i className="bi bi-send" />
                </span>
            </div>
        );
    }

    return (
        <ChatButton
            showFullChat={() => {
                props.hideEverythingElse();
                setShowFullMenu(true);
            }}
        />
    );
}
