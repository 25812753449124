import { addComponent, hasComponent } from "bitecs";
import { MediaImage, Equirectangular, MediaLoader, Networked } from "../bit-components";
import { addObject3DComponent } from "../utils/jsx-entity";
import { create360ImageMesh, createImageMesh } from "../utils/create-image-mesh";
import { ProjectionMode } from "../utils/projection-mode";

export function inflateImage(world, eid, props) {
    const { originalSrc, texture, ratio, projection, alphaMode, cacheKey } = props;

    const mesh =
        projection === ProjectionMode.SPHERE_EQUIRECTANGULAR
            ? create360ImageMesh(texture, ratio, alphaMode)
            : createImageMesh(texture, ratio, alphaMode);

    addObject3DComponent(world, eid, mesh);
    addComponent(world, MediaImage, eid);

    MediaImage.cacheKey[eid] = APP.getSid(cacheKey);
    MediaImage.originalSource[eid] = APP.getSid(originalSrc);

    return eid;
}
