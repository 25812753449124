import { findAncestorWithComponent } from "../utils/scene-graph";
import { waitForDOMContentLoaded } from "../utils/async-utils";
import { easeOutQuadratic } from "../utils/easing";
import { registerComponentInstance, deregisterComponentInstance } from "../utils/component-utils";
import { MediaDevicesEvents } from "../utils/media-devices-utils";

// This computation is expensive, so we run on at most one avatar per frame, including quiet avatars.
// However if we detect an avatar is seen speaking (its volume is above DISABLE_AT_VOLUME_THRESHOLD)
// then we continue analysis for at least DISABLE_GRACE_PERIOD_MS and disable doing it every frame if
// the avatar is quiet during that entire duration (eg they are muted)
const DISABLE_AT_VOLUME_THRESHOLD = 0.00001;
const DISABLE_GRACE_PERIOD_MS = 10000;
const IS_TALKING_THRESHOLD_MS = 1000;
const MIN_VOLUME_THRESHOLD = 0.08;

const calculateVolume = (analyser, levels) => {
    // take care with compatibility, e.g. safari doesn't support getFloatTimeDomainData
    analyser.getByteTimeDomainData(levels);
    let sum = 0;
    for (let i = 0; i < levels.length; i++) {
        const amplitude = (levels[i] - 128) / 128;
        sum += amplitude * amplitude;
    }
    const currVolume = Math.sqrt(sum / levels.length);
    return currVolume;
};

function updateVolume(component) {
    const newRawVolume = calculateVolume(component.analyser, component.levels);

    const newPerceivedVolume = Math.log(THREE.MathUtils.mapLinear(newRawVolume, 0, 1, 1, Math.E));

    component.volume = newPerceivedVolume < MIN_VOLUME_THRESHOLD ? 0 : newPerceivedVolume;

    const s = component.volume > component.prevVolume ? 0.35 : 0.3;
    component.volume = s * component.volume + (1 - s) * component.prevVolume;
    component.prevVolume = component.volume;
}

/**
 * Updates a `volume` property based on a networked audio source
 * @namespace avatar
 * @component networked-audio-analyser
 */
AFRAME.registerComponent("networked-audio-analyser", {
    async init() {
        this.volume = 0;
        this.prevVolume = 0;
        this.disableUpdates = true;
        this.avatarIsTalking = false;

        this._updateAnalysis = this._updateAnalysis.bind(this);
        this._runScheduledWork = this._runScheduledWork.bind(this);
        this.el.sceneEl.systems["frame-scheduler"].schedule(this._runScheduledWork, "audio-analyser");
        this.el.addEventListener(
            "sound-source-set",
            event => {
                const ctx = THREE.AudioContext.getContext();
                this.analyser = ctx.createAnalyser();
                this.analyser.fftSize = 32;
                this.levels = new Uint8Array(this.analyser.fftSize);
                event.detail.soundSource.connect(this.analyser);
            },
            { once: true }
        );

        this.playerSessionId = findAncestorWithComponent(this.el, "player-info").components[
            "player-info"
        ].playerSessionId;
        registerComponentInstance(this, "networked-audio-analyser");
    },

    remove: function () {
        deregisterComponentInstance(this, "networked-audio-analyser");
        this.el.sceneEl.systems["frame-scheduler"].unschedule(this._runScheduledWork, "audio-analyser");
    },

    tick: function (t) {
        if (!this.disableUpdates) {
            this._updateAnalysis(t);
        }
    },

    _runScheduledWork: function () {
        if (this.disableUpdates) {
            this._updateAnalysis();
        }
    },

    // Updates the analysis/volume. If t is passed, that implies this is called via tick
    // and so as a performance optimization will check to see if it's been at least DISABLE_GRACE_PERIOD_MS
    // since the last volume was seen above DISABLE_AT_VOLUME_THRESHOLD, and if so, will disable
    // tick updates until the volume exceeds the level again.
    _updateAnalysis: function (t) {
        if (!this.analyser) return;

        updateVolume(this);

        if (this.volume < DISABLE_AT_VOLUME_THRESHOLD) {
            if (t && this.lastSeenVolume && this.lastSeenVolume < t - DISABLE_GRACE_PERIOD_MS) {
                this.disableUpdates = true;
            }
            if (t && this.lastSeenVolume && this.lastSeenVolume < t - IS_TALKING_THRESHOLD_MS) {
                this.avatarIsTalking = false;
            }
        } else {
            if (t) {
                this.lastSeenVolume = t;
            }

            this.disableUpdates = false;
            this.avatarIsTalking = true;
        }
    }
});

function getAnalyser(el) {
    // Is this the local player
    const ikRootEl = findAncestorWithComponent(el, "ik-root");
    if (ikRootEl && ikRootEl.id === "avatar-rig") {
        return el.sceneEl.systems["local-audio-analyser"];
    } else {
        const analyserEl = findAncestorWithComponent(el, "networked-audio-analyser");
        if (!analyserEl) return null;
        return analyserEl.components["networked-audio-analyser"];
    }
}

/**
 * Calculates volume of the local audio stream.
 */
AFRAME.registerSystem("local-audio-analyser", {
    init() {
        this.volume = 0;
        this.prevVolume = 0;

        this.onMicEnabled = this.onMicEnabled.bind(this);
        this.el.addEventListener(MediaDevicesEvents.MIC_SHARE_STARTED, this.onMicEnabled);
    },

    remove() {
        this.el.removeEventListener(MediaDevicesEvents.MIC_SHARE_STARTED, this.onMicEnabled);
    },

    onMicEnabled() {
        const audioSystem = this.el.sceneEl.systems["hubs-systems"].audioSystem;
        this.analyser = audioSystem.outboundAnalyser;
        this.levels = audioSystem.analyserLevels;
    },

    tick: function () {
        if (!this.analyser) return;

        // TODO Ideally, when muted no audio should ever even make it into the analyser to begin with
        if (APP.dialog.isMicEnabled) {
            updateVolume(this);
        } else {
            this.prevVolume = this.volume;
            this.volume = 0;
        }
    }
});


/**
 * Animates a morph target based on an audio-analyser in a parent entity
 * @namespace avatar
 * @component morph-audio-feedback
 */
AFRAME.registerComponent("morph-audio-feedback", {
    schema: {
        name: { default: "" },
        minValue: { default: 0 },
        maxValue: { default: 2 }
    },

    init() {
        const meshes = [];
        if (this.el.object3DMap.skinnedmesh) {
            meshes.push(this.el.object3DMap.skinnedmesh);
        } else if (this.el.object3DMap.group) {
            // skinned mesh with multiple materials
            this.el.object3DMap.group.traverse(o => o.isSkinnedMesh && meshes.push(o));
        }
        if (meshes.length) {
            this.morphs = meshes
                .map(mesh => {
                    if (mesh.morphTargetDictionary) {
                        return { mesh, morphNumber: mesh.morphTargetDictionary[this.data.name] };
                    }
                })
                .filter(m => m.morphNumber !== undefined);
        }
    },

    tick() {
        if (!this.morphs || !this.morphs.length) return;

        if (!this.analyser) this.analyser = getAnalyser(this.el);

        const { minValue, maxValue } = this.data;
        const morphValue = THREE.MathUtils.mapLinear(
            easeOutQuadratic(this.analyser ? this.analyser.volume : 0),
            0,
            1,
            minValue,
            maxValue
        );
        for (let i = 0; i < this.morphs.length; i++) {
            this.morphs[i].mesh.morphTargetInfluences[this.morphs[i].morphNumber] = morphValue;
        }
    }
});

export function micLevelForVolume(volume) {
    return THREE.MathUtils.clamp(Math.ceil(THREE.MathUtils.mapLinear(volume - 0.05, 0, 1, 0, 7)), 0, 7);
}
