import { FileReplaced } from "../bit-components";
import { defineNetworkSchema } from "./define-network-schema";
import { ArrayVec3 } from "./jsx-entity";
import { deserializerWithMigrations, Migration, NetworkSchema, read, StoredComponent, write } from "./network-schemas";
import type { EntityID } from "./networking-types";

const runtimeSerde = defineNetworkSchema(FileReplaced);

const migrations = new Map<number, Migration>();

function apply(eid: EntityID, { version, data }: StoredComponent) {
    if (version !== 1) return false;

    const { newSrc }: { newSrc: any } = data;
    write(FileReplaced.newSrc, eid, newSrc);

    return true;
}

export const NetworkedFileReplaceSchema: NetworkSchema = {
    componentName: "filereplaced",
    serialize: runtimeSerde.serialize,
    deserialize: runtimeSerde.deserialize,
    serializeForStorage: function serializeForStorage(eid: EntityID) {
        return {
            version: 1,
            data: {
                newSrc: read(FileReplaced.newSrc, eid),
            }
        };
    },
    deserializeFromStorage: deserializerWithMigrations(migrations, apply)
};
