import React, { useState } from "react";
import ToolbarButton from "./ToolbarButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Popover from "react-bootstrap/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsToCircle, faChalkboardUser, faUserLock } from "@fortawesome/free-solid-svg-icons";

export default function TeacherButton(props) {
    const [show, setShow] = useState(false);

    const isTeacher = window.APP.objectHelper.is_teacher();

    if (!props.tutorial && !isTeacher) {
        return null;
    }

    const gather = () => {
        let rig = document.getElementById("avatar-rig");
        let pos = new THREE.Vector3();
        rig.object3D.getWorldPosition(pos);
        window.APP.hubChannel.sendTeleportRequest(pos);
    };

    const menu = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Teacher Menu</Popover.Header>
            <Popover.Body>
                <ListGroup>
                    <ListGroup.Item  as="li" action onClick={() => gather()}>
                        <Row>
                            <Col xs={2} className="d-flex justify-content-center align-items-center">
                                <FontAwesomeIcon icon={faArrowsToCircle} />
                            </Col>
                            <Col xs={10}>
                                <div className="ms-2 me-auto no-select">
                                    <div>
                                        <p className="fw-bold mb-0">Gather students</p>
                                    </div>
                                    <small className="fw-light">
                                        Teleport all students to your location.
                                    </small>
                                </div>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <ToolbarButton
                variant="purple-dark"
                icon={<FontAwesomeIcon icon={faChalkboardUser} />}
                tipTitle={"Teacher Tools"}
                tipBody={"Tools for teachers"}
                onClick={() => setShow(!show)}
                rootClose={true}
                overlayMenu={show ? menu : null}
            />
        </>
    );
}
