import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Markdown from "react-markdown";
import { states } from "./entry/states";

import { useAuth0 } from "@auth0/auth0-react";

export default function AIHelperMenu(props) {
    if (!props.finishedViewModeOnboarding) return null;
    if (AFRAME.utils.device.isMobile()) return null;

    const [result, setResult] = useState(null);

    const [isGenerating, setIsGenerating] = useState(false);

    const [showSetupMenu, setShowSetupMenu] = useState(!window.APP.classroom.ai_subject);

    const { getAccessTokenSilently } = useAuth0();
    const [showPopup, setShowPopup] = useState(!window.APP.store.state.preferences.hideInspirationPopup);

    const [subject, setSubject] = useState("");
    const [activity, setActivity] = useState("");
    const [grade, setGrade] = useState("");
    const [state, setState] = useState("");
    const [standardsAligned, setStandardsAligned] = useState("");

    const chatRef = useRef(null);

    const handleClosingPopup = () => {
        window.APP.store.update({
            preferences: { hideInspirationPopup: true }
        });
        setShowPopup(false);
    };

    const checkRecipeGenerator = () => {
        window.APP.chatHelper.check_ai_generate();
    };

    const createRecipe = () => {
        setShowSetupMenu(false);
        setResult("");
        window.APP.classroom.ai_subject = subject;
        setIsGenerating(true);
        let payload = {
            ai_subject: subject,
            ai_activity: activity,
            ai_state: state,
            ai_grade: grade ? grade : 10
        };

        getAccessTokenSilently()
            .then(token => {
                fetch(process.env.REACT_APP_MM_API_URL + "classrooms/" + window.APP.hub.hub_id + "/", {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        window.APP.classroom = data;
                    })
                    .catch(error => {
                        toast.error("Something went wrong while saving.");
                        console.error(error);
                    });
            })
            .catch(error => {
                toast.error("Auth0 error");
                console.error(error);
            });
    };

    useEffect(() => {
        let intervalId = setInterval(() => {
            if (isGenerating) {
                // Server told us its generating, so we will continously check the status.
                checkRecipeGenerator();
            } else {
                // Server told us we are done. Stop checking.
                clearInterval(intervalId);
            }
        }, 2000);
        return () => {
            clearInterval(intervalId);
        };
    }, [isGenerating]);

    useEffect(() => {
        // 1. Check and set state
        checkRecipeGenerator();

        window.APP.scene.addEventListener("ai_message_receive", event => {
            setResult(event.detail.result);
            setIsGenerating(event.detail.is_generating);
        });
    }, []);

    return (
        <div className="position-fixed clickable ui" style={{ top: "24px", right: "24px", maxWidth: "30%" }}>
            <Accordion className="reset-style-accordion">
                {showPopup && (
                    <span
                        className="p-2 position-absolute bottom-0 start-0 badge rounded-pill bg-purple-dark"
                        style={{ zIndex: 99, transform: "translate(-95%, 30%)" }}
                    >
                        <p className="mb-0 d-flex ms-2 align-items-center">
                            Click this button for inspiration
                            <Button
                                size="sm"
                                onClick={() => handleClosingPopup()}
                                variant="link"
                                className="text-white me-2 ms-2 p-0"
                            >
                                <i className="bi bi-x"></i>
                            </Button>
                        </p>
                        <span className="visually-hidden">Click here if you need inspiration!</span>
                    </span>
                )}
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="">
                        <i className="bi bi-lightbulb-fill text-purple-dark"></i>
                    </Accordion.Header>
                    {!showSetupMenu ? (
                        <Accordion.Body className="m-0 border p-0">
                            {isGenerating ? (
                                <div
                                    className="d-flex flex-column justify-content-center align-items-center"
                                    style={{ height: "400px", overflowY: "auto" }}
                                    ref={chatRef}
                                >
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    <p style={{ whiteSpace: "break-spaces" }} className={"bg-light p-3 m-2 rounded"}>
                                        Generating instructions for {window.APP.classroom.ai_subject} ...
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <div style={{ height: "400px", overflowY: "auto", flexGrow: "1" }} ref={chatRef}>
                                        <p className={"bg-light p-3 m-2 rounded"}>
                                            <Markdown>{result}</Markdown>
                                        </p>
                                    </div>
                                    <Button
                                        variant="link"
                                        className="text-purple-dark"
                                        onClick={() => setShowSetupMenu(true)}
                                    >
                                        <small>Generate new guide</small>
                                    </Button>
                                </>
                            )}
                        </Accordion.Body>
                    ) : (
                        <Accordion.Body className="m-0 border d-flex flex-column">
                            <p>We&apos;ll create a guide for you to get started</p>
                            <p>
                                I am teaching{" "}
                                <Form.Control
                                    value={subject}
                                    onChange={e => setSubject(e.target.value)}
                                    type="text"
                                    placeholder="Pythagorean Theroem"
                                />
                            </p>
                            <p>
                                with grade{" "}
                                <Form.Control
                                    onChange={e => setGrade(e.target.value)}
                                    value={grade}
                                    type="number"
                                    placeholder="10"
                                />
                            </p>
                            <p>
                                and want to do
                                <Form.Select
                                    value={activity}
                                    onChange={e => setActivity(e.target.value)}
                                    aria-label="Activity"
                                >
                                    <option>Hook</option>
                                    <option>Check for Understanding</option>
                                    <option>Discussion</option>
                                    <option>Independent Practice</option>
                                    <option>Project-Based Learning</option>
                                    <option>Reflection</option>
                                    <option>Discovery Learning</option>
                                </Form.Select>
                            </p>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label="It is important that my Lesson is standards aligned "
                                checked={standardsAligned}
                                onChange={e => setStandardsAligned(e.target.checked)}
                            />
                            {standardsAligned && (
                                <p>
                                    My State is
                                    <Form.Select onChange={e => setState(e.target.value)} aria-label="State">
                                        {states.map(state => (
                                            <option key={state.name} value={state.name}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </p>
                            )}
                            <Button variant="success" onClick={() => createRecipe()}>
                                Create Guide
                            </Button>
                            {result && (
                                <Button
                                    size="sm"
                                    variant="link"
                                    className="mt-1 text-purple-dark"
                                    onClick={() => setShowSetupMenu(false)}
                                >
                                    Back
                                </Button>
                            )}
                        </Accordion.Body>
                    )}
                </Accordion.Item>
            </Accordion>
        </div>
    );
}
