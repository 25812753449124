import closedImageSrc from "../../assets/images/hand-open-large.png?inline";
import openImageSrc from "../../assets/images/hand-open-large.png?inline";

const openImage = new Image();
openImage.src = openImageSrc;
export const openTexture = new THREE.Texture(openImage);
openImage.onload = () => {
    openTexture.needsUpdate = true;
};

const closedImage = new Image();
closedImage.src = closedImageSrc;
export const closedTexture = new THREE.Texture(closedImage);
closedImage.onload = () => {
    closedTexture.needsUpdate = true;
};
