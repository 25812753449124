import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ReactGA from "react-ga4";

const qs = new URLSearchParams(location.search);
const hubId = document.location.pathname.substring(1).split("/")[0];

export const useRoom = (isLoading, isAuthenticated) => {
    const { getAccessTokenSilently } = useAuth0();

    const [roomLoading, setRoomLoading] = useState(true);
    const [roomError, setRoomError] = useState(null);
    const [room, setRoom] = useState(null);
    const [counter, setCounter] = useState(0);

    const roomRefresh = () => {
        setCounter(counter + 1);
    };

    const fetchAsGuest = () => {
        const response = fetch(process.env.REACT_APP_MM_API_URL + "inside/" + hubId)
            .then(resp => {
                if (!resp.ok) {
                    setRoom(null);
                    setRoomLoading(false);
                    setRoomError(resp.status);
                    return;
                }
                return resp.json();
            })
            .then(data => {
                setRoom(data);
                setRoomLoading(false);
                window.APP.classroom = data;
                ReactGA.send({ hitType: "pageview", page: hubId });
            })
            .catch(e => {
                console.error("Error", e);
                setRoom(null);
                setRoomError(e);
                setRoomLoading(false);
            });
    };
    const fetchAuthenticated = token => {
        window.APP.store.update({
            credentials: { auth_token: token }
        });
        const response = fetch(process.env.REACT_APP_MM_API_URL + "inside/" + hubId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(resp => {
                if (!resp.ok) {
                    setRoom(null);
                    setRoomLoading(false);
                    setRoomError(resp.status);
                    return;
                }
                return resp.json();
            })
            .then(data => {
                setRoom(data);
                window.APP.classroom = data;
                setRoomLoading(false);
                ReactGA.send({ hitType: "pageview", page: hubId });
            })
            .catch(e => {
                console.error("Error", e);
                setRoom(null);
                setRoomError(e);
                setRoomLoading(false);
            });
    };

    useEffect(() => {
        const fetchRoomData = async authed => {
            if (authed) {
                try {
                    const token = await getAccessTokenSilently();
                    fetchAuthenticated(token);
                } catch {
                    // Auth0 Error, should not happen
                    fetchAsGuest();
                }
            } else {
                // User might have a session we can grab silently
                try {
                    const token = await getAccessTokenSilently();
                    if (token) {
                        fetchAuthenticated(token);
                    } else {
                        fetchAsGuest();
                    }
                } catch {
                    // Anonymous session for public api
                    fetchAsGuest();
                }
            }
        };
        if (!isLoading) {
            fetchRoomData(isAuthenticated);
        }
    }, [isLoading, counter]);

    return [room, roomLoading, roomError, roomRefresh];
};
