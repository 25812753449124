import { Text } from "../bit-components";
import { defineNetworkSchema } from "./define-network-schema";
import { ArrayVec3 } from "./jsx-entity";
import { deserializerWithMigrations, Migration, NetworkSchema, read, StoredComponent, write } from "./network-schemas";
import type { EntityID } from "./networking-types";

const runtimeSerde = defineNetworkSchema(Text);

const migrations = new Map<number, Migration>();

function apply(eid: EntityID, { version, data }: StoredComponent) {
    if (version !== 1) return false;

    const {
        value,
        maxWidth,
        color,
        textAlign,
        ref
    }: { value: any; maxWidth: any; color: any; textAlign: any; ref: any } = data;

    write(Text.value, eid, value);
    write(Text.color, eid, color);
    write(Text.maxWidth, eid, maxWidth);
    write(Text.textAlign, eid, textAlign);
    write(Text.ref, eid, ref);

    return true;
}

export const NetworkedTextSchema: NetworkSchema = {
    componentName: "text",
    serialize: runtimeSerde.serialize,
    deserialize: runtimeSerde.deserialize,
    serializeForStorage: function serializeForStorage(eid: EntityID) {
        return {
            version: 1,
            data: {
                value: read(Text.value, eid),
                maxWidth: read(Text.maxWidth, eid),
                color: read(Text.color, eid),
                textAlign: read(Text.textAlign, eid),
                ref: read(Text.ref, eid)
            }
        };
    },
    deserializeFromStorage: deserializerWithMigrations(migrations, apply)
};
