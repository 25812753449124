import { addComponent } from "bitecs";
import { HubsWorld } from "../app";
import {
    CursorRaycastable,
    HandCollisionTarget,
    Holdable,
    OffersHandConstraint,
    OffersRemoteConstraint,
    RemoteHoverTarget
} from "../bit-components";

export type GrabbableParams = { cursor: boolean; hand: boolean };
const defaults: GrabbableParams = { cursor: true, hand: true };
export function inflateGrabbable(world: HubsWorld, eid: number, props: GrabbableParams) {
    props = Object.assign({}, defaults, props);
    if (props.hand) {
        addComponent(world, HandCollisionTarget, eid);
        addComponent(world, OffersHandConstraint, eid);
    }
    if (props.cursor) {
        addComponent(world, CursorRaycastable, eid);
        addComponent(world, RemoteHoverTarget, eid);
        addComponent(world, OffersRemoteConstraint, eid);
    }
    addComponent(world, Holdable, eid);
}
