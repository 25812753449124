import React, { useEffect, useState, useRef } from "react";

import { SketchPicker } from "react-color";
import { defineQuery, hasComponent, addComponent, removeComponent, removeEntity } from "bitecs";
import {
    MediaFrame,
    MediaLoader,
    MediaPDF,
    IsLocalOnly,
    MediaImage,
    Link,
    Locked,
    Billboard,
    TextUpdated,
    Text,
    Owner,
    Question,
    StudentsCanMove,
    LiveFeed,
    MediaAudio,
    Nickname,
    Equirectangular,
    PDFSettingsChanged,
    Description,
    YouTube,
    SpinningAnimation
} from "../../../../bit-components";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-hot-toast";

export default function Manipulate({ object, world, hasToggledBillboard }) {
    // We need to manually update the state of sliders, as React does not monitor the object.rotation.
    const [sliderX, setSliderX] = useState(object.rotation.x);
    const [sliderY, setSliderY] = useState(object.rotation.y);
    const [sliderZ, setSliderZ] = useState(object.rotation.z);
    const [sliderScale, setSliderScale] = useState(object.scale.x);

    const save = (link = null) => {
        if (hasComponent(world, IsLocalOnly, object.eid)) {
            console.log("Attempted to save local object");
            // Do not save local only objects such as tutorial images
        } else {
            console.log("Got save");
            window.APP.objectHelper.change(object.eid, link);
        }
    };

    const flip = (deg, axis) => {
        if (axis === "Y") {
            object.rotateY((Math.PI / 180) * deg);
            object.updateMatrix();
            setSliderY(object.rotation.y);
        }
        if (axis === "X") {
            object.rotateX((Math.PI / 180) * deg);
            object.updateMatrix();
            setSliderX(object.rotation.x);
        }
        if (axis === "Z") {
            object.rotateZ((Math.PI / 180) * deg);
            object.updateMatrix();
            setSliderZ(object.rotation.z);
        }
        save();
    };

    const rotate = (value, axis) => {
        if (axis === "X") {
            object.rotation.x = value;
            object.updateMatrix();
            setSliderX(object.rotation.x);
        }
        if (axis === "Y") {
            object.rotation.y = value;
            object.updateMatrix();
            setSliderY(object.rotation.y);
        }
        if (axis === "Z") {
            object.rotation.z = value;
            object.updateMatrix();
            setSliderZ(object.rotation.z);
        }
    };

    const setScale = scale => {
        object.scale.set(scale, scale, scale);
        object.updateMatrix();
        setSliderScale(object.scale.x);
    };

    const nudge = (amount, direction) => {
        if (direction === "up") {
            object.position.set(object.position.x, object.position.y + amount, object.position.z);
            object.updateMatrix();
        }
        if (direction === "down") {
            object.position.set(object.position.x, object.position.y - amount, object.position.z);
            object.updateMatrix();
        }
        if (direction === "forth") {
            object.position.set(object.position.x + amount, object.position.y, object.position.z);
            object.updateMatrix();
        }
        if (direction === "back") {
            object.position.set(object.position.x - amount, object.position.y, object.position.z);
            object.updateMatrix();
        }

        if (direction === "right") {
            object.position.set(object.position.x, object.position.y, object.position.z - amount);
            object.updateMatrix();
        }
        if (direction === "left") {
            object.position.set(object.position.x, object.position.y, object.position.z + amount);
            object.updateMatrix();
        }
        save();
    };

    return (
        <div>
            <div className="d-grid gap-2 d-md-block mt-3">
                <p className="fw-bold">Manipulate</p>
            </div>
            <Row className="mt-2">
                <Col xs={12}>
                    <label htmlFor="scale1" className="form-label">
                        Scale
                    </label>
                    <Row>
                        <Col xs={9}>
                            <input
                                type="range"
                                min="0.3"
                                max="15"
                                step="0.1"
                                value={object.scale.x}
                                onInput={e => setScale(parseFloat(e.target.value))}
                                onMouseUp={e => save()}
                                className="form-range"
                                id="scale1"
                            />
                        </Col>
                        <Col>
                            <div className="d-flex justify-content-center align-items-center m-0">
                                <ButtonGroup>
                                    <Button
                                        variant="purple-dark"
                                        className="btn-sm p-1 pt-0 pb-0"
                                        onClick={() => {
                                            setScale(object.scale.x - 0.4);
                                            save();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faMinus} />
                                    </Button>
                                    <Button
                                        variant="purple-dark"
                                        className="btn-sm p-1 pt-0 pb-0"
                                        onClick={() => {
                                            setScale(object.scale.x + 0.4, true);
                                            save();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Col>
                    </Row>
                    {hasToggledBillboard ? (
                        <p className="text-muted">Rotation disabled while "Always face user" is enabled.</p>
                    ) : (
                        <>
                            <label htmlFor="RotateX" className="form-label">
                                Rotate X
                            </label>
                            <Row>
                                <Col xs={9}>
                                    <input
                                        type="range"
                                        min="0"
                                        max="6.283185307179586"
                                        step="0.01"
                                        value={sliderX}
                                        onChange={e => rotate(parseFloat(e.target.value), "X")}
                                        onMouseUp={e => save()}
                                        className="form-range"
                                        id="RotateX"
                                    />
                                </Col>
                                <Col>
                                    <div className="d-flex justify-content-center align-items-center m-0">
                                        <ButtonGroup>
                                            <Button
                                                variant="purple-dark"
                                                className="btn-sm p-1 pt-0 pb-0"
                                                onClick={() => flip(-45, "X")}
                                            >
                                                <FontAwesomeIcon icon={faMinus} />
                                            </Button>
                                            <Button
                                                variant="purple-dark"
                                                className="btn-sm p-1 pt-0 pb-0"
                                                onClick={() => flip(45, "X")}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Col>
                            </Row>
                            <label htmlFor="RotateY" className="form-label">
                                Rotate Y
                            </label>
                            <Row>
                                <Col xs={9}>
                                    <input
                                        type="range"
                                        min="0"
                                        max="6.283185307179586"
                                        step="0.001"
                                        value={object.rotation.y}
                                        onChange={e => rotate(parseFloat(e.target.value), "Y")}
                                        onMouseUp={e => save()}
                                        className="form-range"
                                        id="RotateY"
                                    />
                                </Col>
                                <Col>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <ButtonGroup>
                                            <Button
                                                variant="purple-dark"
                                                className="btn-sm p-1 pt-0 pb-0"
                                                onClick={() => flip(-45, "Y")}
                                            >
                                                <FontAwesomeIcon icon={faMinus} />
                                            </Button>
                                            <Button
                                                variant="purple-dark"
                                                className="btn-sm p-1 pt-0 pb-0"
                                                onClick={() => flip(45, "Y")}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Col>
                            </Row>
                            <label htmlFor="RotateZ" className="form-label">
                                Rotate Z
                            </label>
                            <Row>
                                <Col xs={9}>
                                    <input
                                        type="range"
                                        min="0"
                                        max="6.283185307179586"
                                        step="0.001"
                                        value={object.rotation.z}
                                        onChange={e => rotate(parseFloat(e.target.value), "Z")}
                                        onMouseUp={e => save()}
                                        className="form-range"
                                        id="RotateZ"
                                    />
                                </Col>
                                <Col>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <ButtonGroup>
                                            <Button
                                                variant="purple-dark"
                                                className="btn-sm p-1 pt-0 pb-0"
                                                onClick={() => flip(-45, "Z")}
                                            >
                                                <FontAwesomeIcon icon={faMinus} />
                                            </Button>
                                            <Button
                                                variant="purple-dark"
                                                className="btn-sm p-1 pt-0 pb-0"
                                                onClick={() => flip(45, "Z")}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>
                <Col className="border-start">
                    <label htmlFor="scale1" className="form-label">
                        Nudge
                    </label>
                    <Row className="g-0">
                        <Row>
                            <Col xs={4} className="text-center">
                                <ButtonGroup>
                                    <Button disabled variant="light-outline" className="btn-sm p-1 pt-0 pb-0">
                                        Y
                                    </Button>
                                    <Button
                                        variant="purple-dark"
                                        className="btn-sm p-1 pt-0 pb-0"
                                        onClick={() => nudge(0.1, "up")}
                                    >
                                        <i className="bi bi-arrow-up-short" />
                                    </Button>
                                    <Button
                                        variant="purple-dark"
                                        className="btn-sm p-1 pt-0 pb-0"
                                        onClick={() => nudge(0.1, "down")}
                                    >
                                        <i className="bi bi-arrow-down-short" />
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col xs={4} className="text-center">
                                <ButtonGroup>
                                    <Button disabled variant="light-outline" className="btn-sm p-1 pt-0 pb-0">
                                        X
                                    </Button>
                                    <Button
                                        variant="purple-dark"
                                        className="btn-sm p-1 pt-0 pb-0"
                                        onClick={() => nudge(0.1, "left")}
                                    >
                                        <i className="bi bi-plus" />
                                    </Button>
                                    <Button
                                        variant="purple-dark"
                                        className="btn-sm p-1 pt-0 pb-0"
                                        onClick={() => nudge(0.1, "right")}
                                    >
                                        <i className="bi bi-dash" />
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col xs={4} className="text-center">
                                <ButtonGroup>
                                    <Button disabled variant="light-outline" className="btn-sm p-1 pt-0 pb-0">
                                        Z
                                    </Button>
                                    <Button
                                        variant="purple-dark"
                                        className="btn-sm p-1 pt-0 pb-0"
                                        onClick={() => nudge(0.1, "forth")}
                                    >
                                        <i className="bi bi-plus" />
                                    </Button>
                                    <Button
                                        variant="purple-dark"
                                        className="btn-sm p-1 pt-0 pb-0"
                                        onClick={() => nudge(0.1, "back")}
                                    >
                                        <i className="bi bi-dash" />
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
