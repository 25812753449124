import React from "react";
import RightPreviewMenu from "./menus/RightPreviewMenu";
import { PreviewKeyHints } from "./PreviewKeyHints";
import { PreviewMessage } from "./PreviewMessages";
import { MobilePreviewMessage } from "./PreviewMessages";

const isMobile = AFRAME.utils.device.isMobile();

export default function PreviewMenus(props) {
    return (
        <>
            {!isMobile && <PreviewKeyHints />}
            {isMobile ? <MobilePreviewMessage /> : <PreviewMessage />}
            <RightPreviewMenu />
        </>
    );
}
