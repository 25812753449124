import React, { useEffect, useState, useRef } from "react";

import { SketchPicker } from "react-color";
import { defineQuery, hasComponent, addComponent, removeComponent, removeEntity } from "bitecs";
import {
    MediaFrame,
    MediaLoader,
    IsLocalOnly,
    MediaImage,
    Link,
    Locked,
    Billboard,
    TextUpdated,
    Text,
    Owner,
    Question,
    StudentsCanMove,
    LiveFeed,
    MediaAudio,
    Nickname,
    Equirectangular,
    YouTube,
    SpinningAnimation
} from "../../../../bit-components";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";

import { toast } from "react-hot-toast";

export default function Interactivity({ object, world }) {
    const [hasToggledBillboard, setHasToggledBillboard] = useState(
        hasComponent(world, Billboard, object.eid) && Billboard.toggled[object.eid] === 1
    );

    const [hasToggledEquirectangular, setHasToggledEquirectangular] = useState(
        hasComponent(world, Equirectangular, object.eid) && Equirectangular.toggled[object.eid] === 1
    );

    const [hasToggledLock, setHasToggledLock] = useState(
        hasComponent(world, Locked, object.eid) && Locked.toggled[object.eid] === 1
    );

    const [hasToggledStudentPermission, setHasToggledStudentPermission] = useState(
        hasComponent(world, StudentsCanMove, object.eid) && StudentsCanMove.toggled[object.eid] === 1
    );

    useEffect(() => {
        setHasToggledBillboard(hasComponent(world, Billboard, object.eid) && Billboard.toggled[object.eid] === 1);
        setHasToggledEquirectangular(
            hasComponent(world, Equirectangular, object.eid) && Equirectangular.toggled[object.eid] === 1
        );
        setHasToggledLock(hasComponent(world, Locked, object.eid) && Locked.toggled[object.eid] === 1);
        setHasToggledStudentPermission(
            hasComponent(world, StudentsCanMove, object.eid) && StudentsCanMove.toggled[object.eid] === 1
        );
    }, [object]);

    const toggleLock = toggled => {
        if (toggled) {
            Locked.toggled[object.eid] = true;
            window.APP.objectHelper.change(object.eid);
            setHasToggledLock(true);

            // If you lock the object, nobody should be able to move it, including students.
            setHasToggledStudentPermission(false);
        } else {
            Locked.toggled[object.eid] = false;
            window.APP.objectHelper.change(object.eid);
            setHasToggledLock(false);
        }
    };

    const toggleBillboard = toggled => {
        if (toggled) {
            Billboard.toggled[object.eid] = true;
            window.APP.objectHelper.change(object.eid);
            setHasToggledBillboard(true);
        } else {
            Billboard.toggled[object.eid] = false;
            window.APP.objectHelper.change(object.eid);
            setHasToggledBillboard(false);
        }
    };

    const toggleStudentsCanMove = toggled => {
        if (toggled) {
            StudentsCanMove.toggled[object.eid] = true;
            window.APP.objectHelper.change(object.eid);
            setHasToggledStudentPermission(true);
        } else {
            StudentsCanMove.toggled[object.eid] = false;
            window.APP.objectHelper.change(object.eid);
            setHasToggledStudentPermission(false);
        }
    };

    const toggleEquirectangular = toggled => {
        if (toggled) {
            Equirectangular.toggled[object.eid] = true;
            window.APP.objectHelper.change(object.eid);
            setHasToggledEquirectangular(true);
        } else {
            Equirectangular.toggled[object.eid] = false;
            window.APP.objectHelper.change(object.eid);
            setHasToggledEquirectangular(false);
        }
    };

    const isImage = !!(object.children.length && object.children[0].name === "Image");

    return (
        <div>
            <div className="d-grid gap-2 d-md-block mt-3">
                <p className="fw-bold">Interactivity</p>
            </div>
            {isImage && (
                <Row className="mt-2">
                    <Col>
                        <div className="form-check">
                            <input
                                id="equirectangular-toggle"
                                className="form-check-input"
                                type="checkbox"
                                checked={hasToggledEquirectangular}
                                onChange={e => toggleEquirectangular(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="equirectangular-toggle">
                                <i className="bi bi-image-alt"></i>
                                <span className="ms-2">360 Photo</span>
                            </label>
                        </div>
                    </Col>
                </Row>
            )}
            <Row className="mt-2">
                <Col>
                    <div className="form-check">
                        <input
                            id="billboard-toggle"
                            className="form-check-input"
                            type="checkbox"
                            checked={hasToggledBillboard}
                            onChange={e => toggleBillboard(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="billboard-toggle">
                            <i className="bi bi-binoculars"></i>
                            <span className="ms-2">Always face user</span>
                        </label>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <div className="form-check">
                        <input
                            id="lock-toggle"
                            className="form-check-input"
                            type="checkbox"
                            checked={hasToggledLock}
                            onChange={e => toggleLock(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="lock-toggle">
                            <i className="bi bi-lock" />
                            <span className="ms-2">Disable Grabbing</span>
                        </label>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <div className="form-check">
                        <input
                            id="student-toggle"
                            disabled={hasToggledLock}
                            className="form-check-input"
                            type="checkbox"
                            checked={hasToggledStudentPermission}
                            onChange={e => toggleStudentsCanMove(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="student-toggle">
                            <i className="bi bi-arrows-move" />
                            <span className="ms-2">Moveable outside Edit Mode</span>
                        </label>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
