import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { useAuth0 } from "@auth0/auth0-react";
import MegamindsLogo from "../../assets/megaminds/images/megaminds-logo.png";
import "../styles/global.css";

export function HomeRoot() {
    const { user, isAuthenticated, isLoading, handleRedirectCallback, getAccessTokenSilently } = useAuth0();

    const [pin, setPin] = useState("");

    useEffect(() => {
        const getUserMetadata = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
            } catch (e) {}
        };

        getUserMetadata();
    }, [getAccessTokenSilently, user?.sub]);

    useEffect(() => {
        const qs = new URLSearchParams(location.search);

        // Support legacy sign in urls.
        if (qs.has("sign_in")) {
            const redirectUrl = new URL("/signin", window.location);
            redirectUrl.search = location.search;
            window.location = redirectUrl;
        } else if (qs.has("auth_topic")) {
            const redirectUrl = new URL("/verify", window.location);
            redirectUrl.search = location.search;
            window.location = redirectUrl;
        }
    }, []);

    const redirectToClassroom = () => {
        window.location.replace(window.location.origin + "/" + pin);
    };

    handleRedirectCallback()
        .then(data => {
            if (data.appState.target) {
                window.location.replace(data.appState.target);
            }
        })
        .catch(() => {
            console.log("No query params from auth0 found");
        });

    return (
        <Container className="d-flex justify-content-center text-center h-100">
            <div
                style={{ pointerEvents: "all", zIndex: 9999, height: "80px", maxWidth: "400px" }}
                className="h-100 d-flex flex-column justify-content-center text-center align-items-center"
            >
                <img className="img-fluid p-4 w-50" src={MegamindsLogo} alt="megaminds logo" />
                <Card>
                    <Card.Body>
                        <Form.Control
                            value={pin}
                            onChange={e => setPin(e.target.value)}
                            placeholder="Code"
                            size="lg"
                            type="text"
                        />
                        <Button
                            className="w-100 mt-3"
                            size="lg"
                            onClick={() => redirectToClassroom()}
                            variant="success"
                        >
                            Enter
                        </Button>
                    </Card.Body>
                </Card>
                <Button className="w-100 mt-3" target="_blank" href="https://gomegaminds.com" variant="link">
                    Learn more about MegaMinds
                </Button>
                <Button className="w-100 mt-3" href="https://dash.megaminds.world" variant="link">
                    Go to Dashboard
                </Button>
            </div>
        </Container>
    );
}
