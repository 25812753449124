import React from "react";
import ToolbarButton from "../../classroom/buttons/ToolbarButton";

export default function ToggleEditModeButton(props) {
    if (!props.tutorial && !window.APP.objectHelper.can("can_create")) {
        return null;
    }

    return (
        <>
            <ToolbarButton
                id="step-toolbar-edit"
                variant={props.isEditMode ? "success" : "purple-dark"}
                icon={<i className="bi bi-pencil-fill"></i>}
                tipTitle={window.APP.editMode ? "Toggle Edit Mode" : "Toggle Edit Mode"}
                tipBody={"Allows you to move and edit objects."}
                onClick={() => props.toggleEditMode()}
                rootClose={true}
            />
        </>
    );
}
