const IDLE_TIMEOUT_MS = 1000 * 60 * 15;
let idleTimeout;

function resetTimeout() {
    if (idleTimeout) clearTimeout(idleTimeout);

    idleTimeout = setTimeout(() => {
        window.dispatchEvent(new CustomEvent("idle_detected"));
    }, IDLE_TIMEOUT_MS);

    window.dispatchEvent(new CustomEvent("idle_cancelled"));
}

export function setupIdleDetection() {
    const events = ["click", "pointerdown", "touchstart", "keydown"];

    for (const event of events) {
        window.addEventListener(event, resetTimeout);
    }

    console.log("Idle Detection active");
    resetTimeout();
}
