console.clear();

console.log("%c~~~~~~~~~~~~~~~~~~~~~~~~~~", "color: #6f6ec4; font-size: 2rem; font-family: monospace");
console.log("%cMEGAMINDS", "color: #6f6ec4; font-size: 4rem; font-family: monospace");
console.log(
    "%cCurious about our technology? contact@gomegaminds.com",
    "color: #6f6ec4; font-size: 2rem; font-family: monospace"
);
console.log("%c~~~~~~~~~~~~~~~~~~~~~~~~~~", "color: #6f6ec4; font-size: 2rem; font-family: monospace");
console.log(
    "%cIf someone told you to open this menu, you are most likely the target of a scam and should close the window.",
    "color: red; font-size: 1rem; font-family: monospace"
);
console.log("%c~~~~~~~~~~~~~~~~~~~~~~~~~~", "color: #6f6ec4; font-size: 2rem; font-family: monospace");

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import Hotjar from "@hotjar/browser";
import "animate.css";

import { HomeRoot } from "./react/home/HomeRoot";
import { AssignmentRoot } from "./react/home/AssignmentRoot";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

import { Support, platformUnsupported, isInAppBrowser } from "./support";
import "bootstrap/dist/css/bootstrap.min.css";
import FirstTimeFlow from "./react/home/FirstTimeFlow";

import ReactGA from "react-ga4";

function isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

import "./react/styles/global.css";

import "aframe";
import "./utils/aframe-overrides";

import * as THREE from "three";
THREE.Cache.enabled = false;
THREE.Object3D.DefaultMatrixAutoUpdate = false;

import { patchWebGLRenderingContext } from "./utils/webgl";
patchWebGLRenderingContext();

import "networked-aframe/src/index";
import "webrtc-adapter";

import "./hub-components";

import ReactDOM from "react-dom/client";
import React from "react";
import Root from "./react/Root";
import { disableiOSZoom } from "./utils/disable-ios-zoom";

import "./systems/nav";
import "./systems/frame-scheduler";
import "./systems/personal-space-bubble";
import "./systems/userinput/userinput";
import "./systems/userinput/userinput-debug";
import "./systems/hubs-systems";
import "./systems/audio-debug-system";
import "./systems/audio-gain-system";

import "./gltf-component-mappings";

import { App } from "./app";
import { Auth0Provider } from "@auth0/auth0-react";
import { renderAsEntity } from "./utils/jsx-entity";
import { VideoMenuPrefab } from "./prefabs/video-menu";

window.APP = new App();
window.APP.spawnpoints = [];
renderAsEntity(APP.world, VideoMenuPrefab());

const store = window.APP.store;

import "./load-media-on-paste-or-drop";

const PHOENIX_RELIABLE_NAF = "phx-reliable";
NAF.options.firstSyncSource = PHOENIX_RELIABLE_NAF;
NAF.options.syncSource = PHOENIX_RELIABLE_NAF;

disableiOSZoom();

function redirectToEntryFlow() {
    document.location = `/entry?destination=${encodeURIComponent(document.location.toString())}`;
}

document.addEventListener("DOMContentLoaded", async () => {
    const root = ReactDOM.createRoot(document.getElementById("Root"));

    if (platformUnsupported() || isInAppBrowser()) {
        console.log("Unsupported platform");
        root.render(<Support />);
        return;
    }

    if (process.env.REACT_APP_MM_IS_ENV === "prod") {
        console.log("Loading integrations and logging...");
        ReactGA.initialize("G-GCVLB2BSYP");
        Sentry.init({
            dsn: "https://376450af079e417bbe24e8dfc73736c8@o4503923994656768.ingest.sentry.io/4503924045185025",
            integrations: [new BrowserTracing()],

            release: "3.0.0",
            environment: "prod",
            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 1.0
        });

        Hotjar.init(3817009, 6);
    } else if (process.env.REACT_APP_MM_IS_ENV === "dev") {
        Sentry.init({
            dsn: "https://376450af079e417bbe24e8dfc73736c8@o4503923994656768.ingest.sentry.io/4503924045185025",
            integrations: [new BrowserTracing()],

            release: "3.0.0",
            environment: "development",
            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 1.0
        });
    } else {
        console.log("Local environment found. Skipping sentry and analytics initialization");
    }

    if (!store.state.profile?.displayName || !isNumber(store.state.profile.avatarId)) {
        // Check if avatar exists

        // Initialize state
        store.update({
            profile: {
                displayName: "Name",
                avatarId: 1
            },
            preferences: {
                materialQualitySetting: "high"
            }
        });

        redirectToEntryFlow();
    }

    root.render(
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            cookieDomain={process.env.REACT_APP_AUTH0_COOKIE_DOMAIN}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: "openid profile email read:classrooms read:teacher_profile create:submission"
            }}
        >
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<HomeRoot />}></Route>
                    <Route exact path="/entry" element={<FirstTimeFlow />}></Route>
                    <Route path="/assignment/:code" element={<AssignmentRoot />}></Route>
                    <Route path="/:hubId/" element={<Root />}></Route>
                    <Route path="/:hubId/:slug" element={<Root />}></Route>
                </Routes>
            </BrowserRouter>
        </Auth0Provider>
    );
});
