import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

import { spawnFromUrl } from "../../../../load-media-on-paste-or-drop";

function ModelCard(props) {
    const getImage = () => {
        // Find the best quality image, and prevent erroring when some obscure models have less than 2 or 3 thumbnails
        const images = props.model?.thumbnails?.images;

        if (!images) return null;

        const length = images.length;
        if (length <= 2) {
            return images[length - 1].url;
        } else {
            return images[length - 2].url;
        }
    };

    return (
        <Card.Body
            className="pointer-cursor btn border-0"
            onClick={() => {
                spawnFromUrl(props.model.viewerUrl);
                props.onClose();
            }}
        >
            <img className="img-fluid rounded mb-2" src={getImage()} />
            <p className="p-1">{props.model.name}</p>
        </Card.Body>
    );
}

const sketchfabCategories = [
    "Science & Technology",
    "Places & Travel",
    "Animals & Pets",
    "Architecture",
    "Cultural Heritage & History"
];

export default function BrowseSketchfabMenu(props) {
    const [tempSearch, setTempSearch] = useState("Places & Travel");
    const [search, setSearch] = useState("Places & Travel");
    const [cursor, setCursor] = useState("0");
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isBadWord, setIsBadWord] = useState(false);

    const searchRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        fetch(process.env.REACT_APP_MM_API_URL + "sketchfab/search?count=24&cursor=" + cursor + "&q=" + search)
            .then(response => response.json())
            .then(data => {
                if (data.profanity) {
                    setIsBadWord(true);
                    setLoading(false);
                }
                else {
                    setIsBadWord(false)
                    setResults(data);
                    setLoading(false);
                }
            });
    }, [search, cursor, setCursor]);

    useEffect(() => {
        setLoading(true);
        fetch(
            process.env.REACT_APP_MM_API_URL +
                "sketchfab/search?source=sketchfab&count=24&cursor=" +
                cursor +
                "&q=" +
                search
        )
            .then(response => response.json())
            .then(data => {
                setIsBadWord(false)
                setResults(data);
                setLoading(false);
            });
    }, []);

    if (!results) {
        return <p>Could not get results from Sketchfab</p>;
    }

    const handleSearch = () => {
        setCursor(0);
        setSearch(tempSearch);
    };

    return (
        <>
            <Container className="p-3">
                <Row>
                    <Col>
                        <InputGroup>
                            <input
                                className="form-control"
                                placeholder="Search 3D Models..."
                                value={tempSearch}
                                onChange={e => setTempSearch(e.target.value)}
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                            />
                            <Button variant="secondary" onClick={() => handleSearch()}>
                                Search
                            </Button>
                        </InputGroup>
                        <span className="fw-light text-muted">Powered by Sketchfab</span>
                    </Col>
                </Row>
                <Row xs={3} className="g-2 mt-3 mb-3">
                    {sketchfabCategories.map(category => (
                        <Col key={category} className="d-flex justify-content-center">
                            <Badge
                                pill
                                bg="purple-dark"
                                className="btn p-3 pt-2 pb-2"
                                onClick={() => {
                                    setCursor("0");
                                    setSearch(category);
                                    setIsBadWord(false);
                                }}
                            >
                                {category}
                            </Badge>
                        </Col>
                    ))}
                </Row>
            </Container>
            {loading ? (
                <Container ref={searchRef} fluid className="bg-light p-5 border-top">
                    <Row className="d-flex justify-content-center align-items-center">
                        <Col>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            ) : (
                isBadWord ? (
                    <small className="d-flex justify-content-center">No results found</small>
                ) : (
                <Container ref={searchRef} fluid className="bg-light p-5 border-top">
                    <Row xs={3} className="g-4">
                        {results.results?.map(result => (
                            <Col key={result.uid}>
                                <ModelCard onClose={props.onClose} model={result} />
                            </Col>
                        ))}
                    </Row>
                    <Row className="p-3">
                        <Col className="d-flex justify-content-start">
                            {results.previous && (
                                <Button
                                    variant="purple-dark"
                                    onClick={() => {
                                        setCursor(results.cursors.previous);
                                        searchRef.current.scrollIntoView();
                                    }}
                                >
                                    Previous Page
                                </Button>
                            )}
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                            <p className="mb-0 text-muted fw-light">
                                <small>Page {cursor / 24 + 1}</small>
                            </p>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            {results.next && (
                                <Button
                                    variant="purple-dark"
                                    onClick={() => {
                                        setCursor(results.cursors.next);
                                        searchRef.current.scrollIntoView();
                                    }}
                                >
                                    Next Page
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Container>
                )
            )}
        </>
    );
}
