import { HubsWorld } from "../app";
import { ProjectionMode } from "../utils/projection-mode";
import { inflateMediaLoader } from "./media-loader";

export interface VideoLoaderParams {
    src: string;
    equi: ProjectionMode;
    autoPlay: boolean;
    controls: boolean;
    loop: boolean;
}

export function inflateVideoLoader(world: HubsWorld, eid: number, params: VideoLoaderParams) {

    console.log("videoloader params", params);

    inflateMediaLoader(world, eid, {
        src: params.src,
        recenter: false,
        resize: false,
        sketchfab_meta: "",
        equi: params.equi,
        animateLoad: false,
        isObjectMenuTarget: false
    });
}
