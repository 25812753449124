import React, { useEffect, useState, useRef } from "react";

import QuickActions from "./edit/QuickActions";
import Manipulate from "./edit/Manipulate";
import Interactivity from "./edit/Interactivity";
import Other from "./edit/Other";
import Specific from "./edit/Specific";

import { toast } from "react-hot-toast";

export default function EditObjectMenu({ object, setObject, closePopover, scene }) {
    scene.emit("tip-finished", "has-opened-object-menu");
    console.log(object);
    const world = APP.world;


    return (
        <div className="p-3 pt-2">
            <Specific world={world} object={object} />
            <QuickActions world={world} object={object} />
            <Manipulate world={world} object={object} hasToggledBillboard={false} />
            <Interactivity world={world} object={object} />
            <Other world={world} object={object} scene={scene} closePopover={closePopover} />
        </div>
    );
}
