export function createPolypadObject(link) {
    var iframe = document.createElement("iframe");

    iframe.style.border = "0px";
    iframe.style.pointerEvents = "all";
    iframe.src = link;

    console.log("Created polypad object", link);

    return iframe;
}
