import React, { useState, useRef } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { toast } from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import { spawnFromFileList } from "../../../load-media-on-paste-or-drop";
import addFiles from "../../../assets/megaminds/images/add_files.png";

function getIconByFiletype(filetype, file) {
    const extension = file.name.split(".").pop();

    if (filetype.startsWith("youtube")) {
        return <i className="bi bi-youtube"></i>;
    }

    if (filetype.startsWith("link")) {
        return <i className="bi bi-link-45deg"></i>;
    }

    if (filetype.startsWith("video") || extension === "mp4" || extension === "webm") {
        return <i className="bi bi-file-earmark-play"></i>;
    }
    if (
        filetype.startsWith("video") ||
        extension === "jpg" ||
        extension === "png" ||
        extension === "gif" ||
        extension === "svg" ||
        extension === "jpeg" ||
        extension === "bmp"
    ) {
        return <i className="bi bi-file-earmark-image"></i>;
    }
    if (
        filetype.startsWith("audio") ||
        extension === "flac" ||
        extension === "mp3" ||
        extension === "m4a" ||
        extension === "aac" ||
        extension === "aac" ||
        extension === "ogg" ||
        extension === "wav" ||
        extension === "opus"
    ) {
        return <i className="bi bi-file-earmark-music"></i>;
    }
    if (filetype.startsWith("application/pdf") || extension === "pdf") {
        return <i className="bi bi-file-earmark-pdf"></i>;
    }

    if (filetype.startsWith("model")) {
        return <i className="bi bi-box"></i>;
    }

    return <i className="bi bi-box"></i>;
}

const Files = props => {
    const [files, setFiles] = useState([]);
    const [isPosting, setIsPosting] = useState(false);

    const [showFiles, setShowFiles] = useState(false);
    const [showDragEffect, setShowDragEffect] = useState(false);
    const [editingYoutube, setEditingYoutube] = useState(false);
    const [youtubeLink, setYoutubeLink] = useState(null);
    const inputFile = useRef(null);

    const [editingLink, setEditingLink] = useState(false);
    const [link, setLink] = useState(null);

    const [counter, setCounter] = useState(0);

    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();

        console.log("Drag entered");
        setShowDragEffect(true);
    };
    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        console.log("Drag left");
        setShowDragEffect(false);
    };

    window.addEventListener(
        "dragover",
        function (e) {
            e.preventDefault();
        },
        false
    );
    window.addEventListener(
        "drop",
        function (e) {
            e.preventDefault();
        },
        false
    );

    const checkAndAddToFiles = file => {
        console.log("got file", file);
        // if they start with .
        if (file.name.startsWith(".")) {
            return;
        }

        // if files already uploaded
        const existingFiles = files.map(f => f.name);
        if (existingFiles.includes(file.name)) {
            toast("Skipped a duplicate file", {
                duration: 3000,
                position: "top-left",
                icon: "🐫"
            });
            return;
        }

        const extension = file.name.split(".").pop();
        const allowedFileTypes = [
            "glb",
            "gltf",
            "webm",
            "mp4",
            "mp3",
            "wav",
            "opus",
            "ogg",
            "webp",
            "m4a",
            "flac",
            "aac",
            "pdf",
            "bmp",
            "jpg",
            "svg",
            "gif",
            "jpeg",
            "png"
        ];
        if (file.type !== "link" && file.type !== "youtube" && !allowedFileTypes.includes(extension)) {
            toast("Skipped " + file.name + ". Unsupported filetype", {
                duration: 5000,
                position: "top-left",
                icon: "ℹ️"
            });
            return;
        }

        setFiles(prevFiles => [...prevFiles, file]);
        console.log("New file added", file);
    };

    const saveYoutube = rawlink => {
        if (!rawlink) {
            setYoutubeLink(null);
            setEditingYoutube(false);
            return;
        }
        try {
            if (!rawlink.startsWith("http://") && !rawlink.startsWith("https://")) {
                rawlink = "https://" + rawlink;
            }
            const link = new URL(rawlink);
            const file = {
                name: link.href,
                type: "youtube"
            };

            checkAndAddToFiles(file);
            setYoutubeLink(null);
            setEditingYoutube(false);
        } catch (e) {
            toast.error("Not a valid link" + e);
        }
    };

    const saveLink = rawlink => {
        if (!rawlink) {
            setLink(null);
            setEditingLink(false);
            return;
        }
        try {
            if (!rawlink.startsWith("http://") && !rawlink.startsWith("https://")) {
                rawlink = "https://" + rawlink;
            }
            const working_link = new URL(rawlink);
            const file = {
                name: working_link.href,
                type: "link"
            };

            checkAndAddToFiles(file);
            setLink(null);
            setEditingLink(false);
        } catch (e) {
            toast.error("Error" + e);
        }
    };

    const handleDrop = e => {
        e.preventDefault();
        const items = Array.from(e.dataTransfer.items);

        const traverseFileTree = (item, path) => {
            if (item.isFile) {
                item.file(file => {
                    checkAndAddToFiles(file);
                });
            } else if (item.isDirectory) {
                const directoryReader = item.createReader();
                directoryReader.readEntries(entries => {
                    for (let i = 0; i < entries.length; i++) {
                        traverseFileTree(entries[i], `${path}${item.name}/`);
                    }
                });
            }
        };

        items.forEach(item => {
            const entry = item.webkitGetAsEntry();
            console.log(entry);
            if (entry) {
                traverseFileTree(entry, "");
            } else {
                const file = item.getAsFile();
                checkAndAddToFiles(file);
            }
        });

        console.log("New files are", files);
        setShowDragEffect(false);
    };

    const getTotalFilesize = () => {
        let total = 0;

        files.forEach(file => {
            total += file.size;
        });

        return total;
    };

    const uploadAllFiles = () => {
        setIsPosting(true);
        if (files) {
            spawnFromFileList(files, false, null, window.APP.classroom.scene.sceneposition_set).then(() => {
                props.onContinue();
            });
        } else {
            props.onContinue();
        }
    };

    return (
        <div className="position-absolute top-50 start-0 translate-middle-y" style={{ width: "346px" }}>
            <Tooltip
                id="hover-tooltip"
                render={({ content, activeAnchor }) => (
                    <span>
                        {content}
                        <br />
                        {activeAnchor?.getAttribute("data-tooltip-desc") || "not set"}
                    </span>
                )}
            />
            <Container className="d-flex justify-content-center p-0 m-0">
                <Card className="ms-3 w-100" style={{ minHeight: "70vh" }}>
                    <Card.Header>Info</Card.Header>
                    <Card.Body className="clickable">
                        {showFiles ? (
                            <>
                                <Row className="m-2">
                                    <div
                                        id="dragover-area"
                                        className={
                                            showDragEffect
                                                ? "g-4 border-5 d-flex justify-content-center align-items-center border-success bg-light text-dark text-center"
                                                : "g-4 border-5 d-flex justify-content-center align-items-center text-secondary text-center"
                                        }
                                        style={{
                                            minHeight: "300px",
                                            border: "5px dotted #666",
                                            flexDirection: "column"
                                        }}
                                        onClick={() => inputFile.current.click()}
                                        onDrop={e => handleDrop(e)}
                                        onDragEnter={e => handleDragEnter(e)}
                                        onDragLeave={e => handleDragLeave(e)}
                                    >
                                        <input
                                            type="file"
                                            id="file"
                                            ref={inputFile}
                                            multiple
                                            onInput={e => {
                                                const items = Array.from(e.target.files);

                                                items.forEach(item => {
                                                    checkAndAddToFiles(item);
                                                });

                                                console.log("New files are", files);
                                                setShowDragEffect(false);
                                            }}
                                            style={{ display: "none" }}
                                        />
                                        {!showDragEffect ? (
                                            <>
                                                <p className="disable-pointer-events">
                                                    <i
                                                        style={{
                                                            fontSize: "4rem",
                                                            color: "#666"
                                                        }}
                                                        className="bi bi-box-arrow-in-down"
                                                    ></i>
                                                </p>
                                                <p className="disable-pointer-events">
                                                    Drag-and-drop folders or files here.
                                                </p>
                                                <small className="disable-pointer-events fw-light">
                                                    Supports audio, images, video, pdf, glb/gltf objects
                                                </small>
                                            </>
                                        ) : (
                                            <>
                                                <p className="disable-pointer-events">
                                                    <i
                                                        style={{
                                                            fontSize: "4rem",
                                                            color: "#666"
                                                        }}
                                                        className="bi bi-box-arrow-in-down"
                                                    ></i>
                                                </p>
                                                <small className="disable-pointer-events">Drop to upload!</small>
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        <ListGroup className="w-100 mt-4">
                                            {editingYoutube ? (
                                                <ListGroup.Item key="youtube">
                                                    <Row>
                                                        <Col xs={12}>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    placeholder="https://www.youtube.com/watch?v=atY7ymXAcRQ"
                                                                    onChange={e => setYoutubeLink(e.target.value)}
                                                                    type="text"
                                                                />
                                                                <Button
                                                                    size="sm"
                                                                    variant="outline-secondary"
                                                                    onClick={() => setEditingYoutube(false)}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    variant="outline-success"
                                                                    size="sm"
                                                                    onClick={e => saveYoutube(youtubeLink)}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            ) : (
                                                <ListGroup.Item
                                                    action
                                                    onClick={() => setEditingYoutube(true)}
                                                    key="youtube"
                                                >
                                                    <>
                                                        <i className="bi bi-plus-circle me-3"></i>
                                                        YouTube Video
                                                    </>
                                                </ListGroup.Item>
                                            )}
                                            {editingLink ? (
                                                <ListGroup.Item key="link">
                                                    <Row>
                                                        <Col xs={12}>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    placeholder="https://example.com"
                                                                    onChange={e => setLink(e.target.value)}
                                                                    type="url"
                                                                />
                                                                <Button
                                                                    variant="outline-secondary"
                                                                    size="sm"
                                                                    onClick={() => setEditingLink(false)}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    variant="outline-success"
                                                                    size="sm"
                                                                    onClick={e => saveLink(link)}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col
                                                            xs={12}
                                                            md={4}
                                                            className="d-flex justify-content-end"
                                                        ></Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            ) : (
                                                <ListGroup.Item action onClick={() => setEditingLink(true)} key="link">
                                                    <>
                                                        <i className="bi bi-plus-circle me-3"></i>
                                                        Website Link
                                                    </>
                                                </ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </div>
                                </Row>
                                <Row className="m-2 mt-3 w-100">
                                    {files.length > 0 ? (
                                        <ListGroup className="w-100">
                                            <ListGroup.Item key="first">
                                                <p className="fw-bold mb-0">Files to be uploaded</p>
                                            </ListGroup.Item>
                                            {files.map(file => (
                                                <ListGroup.Item key={file.name}>
                                                    <Row>
                                                        <Col xs={8} className="text-truncate truncate">
                                                            <p className="mb-0">{file.name}</p>
                                                        </Col>
                                                        <Col className="d-flex text-secondary justify-content-end">
                                                            <Tooltip id="tooltip" />
                                                            {(file.size / (1024 * 1024)).toFixed(2) > 10 && (
                                                                <span
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-html="This file is large and may impact loading times.</br>Try to aim for under 60mb in total."
                                                                    className="text-danger me-3"
                                                                >
                                                                    <small className="ms-3">
                                                                        {(file.size / (1024 * 1024)).toFixed(2)}
                                                                        MB
                                                                    </small>
                                                                </span>
                                                            )}
                                                            {getIconByFiletype(file.type, file)}
                                                            <i
                                                                onClick={() => {
                                                                    const index = files.indexOf(file);
                                                                    if (index > -1) {
                                                                        // only splice array when item is found
                                                                        files.splice(index, 1); // 2nd parameter means remove one item only
                                                                    }
                                                                    setCounter(counter + 1);
                                                                }}
                                                                className="bi bi-trash ms-3 trash-button"
                                                            ></i>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    ) : (
                                        <></>
                                    )}
                                </Row>
                            </>
                        ) : (
                            <div>
                                <div className="text-center mb-2">
                                    <img
                                        alt="two paper documents on top of each other with plus icon"
                                        src={addFiles}
                                        className="img-fluid"
                                        style={{ maxWidth: "200px" }}
                                    />
                                </div>
                                <h5>Add Files</h5>
                                <p>
                                    If you already have <span className="fw-bold">files</span> to upload,
                                    we can add them now or later.
                                </p>
                                <p>They will be automatically positioned around the 3D space.</p>
                                <Button variant="success" className="me-3" onClick={() => setShowFiles(true)}>
                                    Upload files
                                </Button>
                            </div>
                        )}
                        {window.APP.classroom.scene.sceneposition_set.length < files.length && (
                            <div className="alert alert-primary mt-4">
                                <p>
                                    <i className="bi bi-info-circle me-2"></i>
                                    The number of files exceed the number of available positions on the walls of this
                                    space. Some content will be placed in the middle of the space.
                                </p>
                                <p className="mb-0">You will have to position them yourself after entering the space.</p>
                            </div>
                        )}
                        {(getTotalFilesize() / (1024 * 1024)).toFixed(2) > 100 && (
                            <div className="alert alert-warning mt-4">
                                <p>
                                    <i className="bi bi-exclamation-triangle me-2"></i>
                                    Your Lesson will have a total filesize of{" "}
                                    <strong>{(getTotalFilesize() / (1024 * 1024)).toFixed(2)} MB</strong>
                                </p>
                                <p>
                                    Lessons over 100 MB may experience long load times, or difficulty connecting on older
                                    devices.
                                </p>
                                <p className="mb-0">
                                    Depending on your students devices and network connection, consider reducing the
                                    overall size of your Lesson.
                                </p>
                            </div>
                        )}
                    </Card.Body>
                    <Card.Footer className="clickable">
                        <Row>
                            <Col xs={4}>
                                <Button
                                    variant="outline-secondary"
                                    className="w-100"
                                    disabled={isPosting}
                                    onClick={() => {
                                        props.setStep("scene");
                                    }}
                                >
                                    Back
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    variant="success"
                                    className="w-100"
                                    disabled={isPosting}
                                    onClick={() => {
                                        if (files) {
                                            uploadAllFiles();
                                        } else {
                                            props.onContinue();
                                        }
                                    }}
                                >
                                    Finish and Enter Lesson
                                </Button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Container>
        </div>
    );
};

export default Files;
