import { addComponent, addEntity } from "bitecs";
import { Text as TroikaText } from "troika-three-text";
import { Text } from "../bit-components";
import { addObject3DComponent } from "../utils/jsx-entity";

const THREE_SIDES = {
    front: THREE.FrontSide,
    back: THREE.BackSide,
    double: THREE.DoubleSide
};

const DEFAULTS = {
    textAlign: "center",
    anchorX: "center",
    anchorY: "middle"
};

export function inflateText(world, eid, componentProps) {
    addComponent(world, Text, eid);

    Text.value[eid] = APP.getSid(componentProps.value);
    Text.maxWidth[eid] = APP.getSid(componentProps.maxWidth);
    Text.color[eid] = APP.getSid(componentProps.color);
    Text.textAlign[eid] = APP.getSid(componentProps.textAlign);

    const text = new TroikaText();

    addObject3DComponent(world, eid, text);

    Text.ref[eid] = text.eid;

    const obj = APP.world.eid2obj.get(Text.ref[eid]);

    obj.anchorX = "center";
    obj.anchorY = "middle";
    obj.material.side = THREE.DoubleSide;

    Object.entries(componentProps).forEach(([name, value]) => {
        switch (name) {
            case "value":
                obj.text = value;
                break;
            case "maxWidth":
                obj.maxWidth = value;
                obj.sync();
            case "side":
                break;
            case "opacity":
                break;
            case "fontUrl":
                obj.font = value;
                break;
            default:
                obj[name] = value;
        }
    });

    obj.sync();
}
