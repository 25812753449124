import React, { useState, useEffect } from "react";

import Joyride from "react-joyride";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import OnboardingSlide from "./OnboardingSlide";
import AnchorPoint from "../../menus/modals/AnchorPoint";

export default function OnboardingFlow(props) {
    // props.finishedViewModeOnboarding
    // props.onboardingStep
    // props.setOnboardingStep

    const [stepIndex, setStepIndex] = useState(0);

    const [hasMoved, setHasMoved] = useState(false);

    const [hasLooked, setHasLooked] = useState(false);

    const [showMovementHelp, setShowMovementHelp] = useState(false);

    useEffect(() => {
        props.scene.addEventListener("tip-finished", event => {
            if (event.detail === "locomotion") {
                setHasMoved(true);
            }

            if (event.detail === "look") {
                setHasLooked(true);
            }
        });
    }, []);

    useEffect(() => {
        props.setOnboardingStep(stepIndex);
    }, [stepIndex]);

    const steps = [
        {
            target: ".root",
            placement: "center",
            disableOverlayClose: true,
            skipOnboarding: () => {
                props.skippedOnboarding();
            },
            setNextStep: () => {
                window.APP.isOnboarding = true;
                setStepIndex(stepIndex + 1);
            },
            setPrevStep: () => setStepIndex(stepIndex - 1),
            title: "Welcome",
            content: (
                <div>
                    <p className="fw-bold">Basic Tutorial</p>
                    <p>Learn the basics of MegaMinds.</p>
                    <small className="text-muted">This tutorial takes approximately 2 minutes to complete.</small>
                </div>
            )
        },
        {
            target: ".anchor-top-start",
            placement: "bottom",
            isFixed: true,
            floaterProps: {
                hideArrow: true
            },
            setNextStep: () => setStepIndex(stepIndex + 1),
            setPrevStep: () => setStepIndex(stepIndex - 1),
            disableOverlay: true,
            disableOverlayClose: true,
            title: "The 3D View",
            hideNext: !hasLooked,
            content: (
                <div>
                    {showMovementHelp ? (
                        <Row className="mb-3">
                            <Col className="d-flex align-self-center">
                                <h5 className="mb-0">Looking Around</h5>
                            </Col>
                            <Col xs={4} className="d-flex justify-content-end align-content-center">
                                <Button
                                    onClick={() => setShowMovementHelp(false)}
                                    size="sm"
                                    variant="outline-secondary"
                                >
                                    <i className="bi bi-info-circle me-2"></i>
                                    Back
                                </Button>
                            </Col>
                            <Card.Body>
                                <Row>
                                    <Col xs={12}>
                                        <p className="fw-bold">Perspective</p>
                                    </Col>
                                    <Col xs={12}>
                                        <p>You are looking at this 3D space through the eyes of your avatar.</p>
                                        <p>When pressing the Arrow Keys, your avatar moves around the space.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <p className="fw-bold">Alternative Keys</p>
                                    </Col>
                                    <Col xs={12}>
                                        <p>
                                            Use W A S D keys with your left hand. Some users prefer find this more
                                            comfortable than Arrow Keys.
                                        </p>
                                        <p>You can use Q and E to rotate your view instead of using the mouse.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <p className="fw-bold">Video Guide</p>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="ratio ratio-16x9">
                                            <iframe
                                                src="https://www.youtube.com/embed/z_WaiB4TOuA"
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen
                                            ></iframe>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Row>
                    ) : (
                        <>
                            <Row className="mb-3">
                                <Col className="d-flex align-self-center">
                                    <h5 className="mb-0"> Movement & Looking </h5>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-middle align-content-center">
                                    <Button
                                        onClick={() => setShowMovementHelp(true)}
                                        size="sm"
                                        variant="outline-secondary"
                                    >
                                        <i className="bi bi-info-circle me-2"></i>
                                        Help
                                    </Button>
                                </Col>
                            </Row>
                            <hr />
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <p>
                                            <span className="fw-bold">Click-and-drag</span> the{" "}
                                            <span className="fw-bold">left mouse button</span> anywhere on the 3D area
                                            to look around.
                                        </p>
                                    </Col>
                                </Row>
                                {hasLooked && (
                                    <Alert variant="success">
                                        <p className="mb-0">🎉 Good job! You are now able to look around!</p>
                                    </Alert>
                                )}
                            </Card.Body>
                        </>
                    )}
                </div>
            )
        },
        {
            target: ".anchor-top-start",
            placement: "bottom",
            isFixed: true,
            floaterProps: {
                hideArrow: true
            },
            setNextStep: () => setStepIndex(stepIndex + 1),
            setPrevStep: () => setStepIndex(stepIndex - 1),
            disableOverlay: true,
            disableOverlayClose: true,
            title: "The 3D View",
            hideNext: !hasMoved,
            content: (
                <div>
                    {showMovementHelp ? (
                        <Row className="mb-3">
                            <Col className="d-flex align-self-center">
                                <h5 className="mb-0">Moving Around</h5>
                            </Col>
                            <Col xs={4} className="d-flex justify-content-end align-content-center">
                                <Button
                                    onClick={() => setShowMovementHelp(false)}
                                    size="sm"
                                    variant="outline-secondary"
                                >
                                    <i className="bi bi-info-circle me-2"></i>
                                    Back
                                </Button>
                            </Col>
                            <Card.Body>
                                <Row>
                                    <Col xs={12}>
                                        <p className="fw-bold">Perspective</p>
                                    </Col>
                                    <Col xs={12}>
                                        <p>You are looking at the 3D environment through the eyes of your avatar.</p>
                                        <p>When pressing the Arrow Keys, your avatar moves around the space.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <p className="fw-bold">Alternative Keys</p>
                                    </Col>
                                    <Col xs={12}>
                                        <p>
                                            Use W A S D keys with your left hand. Some users prefer find this more
                                            comfortable than Arrow Keys.
                                        </p>
                                        <p>You can use Q and E to rotate your view instead of using the mouse.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <p className="fw-bold">Video Guide</p>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="ratio ratio-16x9">
                                            <iframe
                                                src="https://www.youtube.com/embed/z_WaiB4TOuA"
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen
                                            ></iframe>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Row>
                    ) : (
                        <>
                            <Row className="mb-3">
                                <Col className="d-flex align-self-center">
                                    <h5 className="mb-0"> Movement & Looking </h5>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-middle align-content-center">
                                    <Button
                                        onClick={() => setShowMovementHelp(true)}
                                        size="sm"
                                        variant="outline-secondary"
                                    >
                                        <i className="bi bi-info-circle me-2"></i>
                                        Help
                                    </Button>
                                </Col>
                            </Row>
                            <hr />
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <p>
                                            Press the <span className="fw-bold">Arrow Keys</span> to move around.
                                        </p>
                                    </Col>
                                </Row>
                                {hasMoved && (
                                    <>
                                        <Alert variant="success">
                                            <p className="mb-0">🎉 Good job! You are able to move around!</p>
                                        </Alert>
                                        <p className="fw-light text-muted mb-0">
                                            <small>You can alternatively use the WASD keys to move.</small>
                                        </p>
                                    </>
                                )}
                            </Card.Body>
                        </>
                    )}
                </div>
            )
        },
        {
            target: "#step-toolbar",
            spotlightPadding: -3,
            disableOverlayClose: true,
            title: "The Toolbar",
            setNextStep: () => setStepIndex(stepIndex + 1),
            setPrevStep: () => setStepIndex(stepIndex - 1),
            content: (
                <div>
                    <p className="fw-bold">Toolbar</p>
                    <p>
                        This is the toolbar. The toolbar is always here and presents buttons for the functionality in
                        MegaMinds.
                    </p>
                </div>
            )
        },
        {
            target: "#step-toolbar-chat",
            disableOverlayClose: true,
            setNextStep: () => setStepIndex(stepIndex + 1),
            setPrevStep: () => setStepIndex(stepIndex - 1),
            title: "Chat",
            content: (
                <div>
                    <p className="fw-bold">Chat</p>
                    <p>Users can send messages to everyone in the space using the message field here.</p>
                    <small className="text-muted">Some spaces may have disabled the chat feature</small>
                </div>
            )
        },
        {
            target: "#step-toolbar-communicate",
            spotlightPadding: -2,
            disableOverlayClose: true,
            setNextStep: () => setStepIndex(stepIndex + 1),
            setPrevStep: () => setStepIndex(stepIndex - 1),
            title: "Help and resources",
            content: (
                <div>
                    <p className="fw-bold">Communicate</p>
                    <p>These buttons may be familiar from other video conference or chat programs.</p>
                    <p>
                        Here you can <span className="fw-bold">toggle your microphone</span>, indicate a question by{" "}
                        <span className="fw-bold">raising your hand</span>, share{" "}
                        <span className="fw-bold">webcam and screen</span>, and <span className="fw-bold">react</span>{" "}
                        by sending emojis.
                    </p>
                </div>
            )
        },
        {
            target: "#step-toolbar-add",
            spotlightPadding: -2,
            disableOverlayClose: true,
            setNextStep: () => setStepIndex(stepIndex + 1),
            setPrevStep: () => setStepIndex(stepIndex - 1),
            title: "Help and resources",
            content: (
                <div>
                    <p className="fw-bold">Add Content</p>
                    <p>
                        If you are the owner of the space, you can <span className="fw-bold">Add Content</span> to the
                        space.
                    </p>
                    <p>This can be text, images, or many of our other components such as EduTech, assessments, etc.</p>
                    <small className="text-muted">
                        The button will show up for everyone when using Student Projects or when "Allow Creation"
                        permission is granted.
                    </small>
                </div>
            )
        },
        {
            target: "#step-toolbar-edit",
            spotlightPadding: -2,
            disableOverlayClose: true,
            setNextStep: () => setStepIndex(stepIndex + 1),
            setPrevStep: () => setStepIndex(stepIndex - 1),
            title: "Help and resources",
            content: (
                <div>
                    <p className="fw-bold">Toggle Edit Mode</p>
                    <p>Use this button to toggle Edit Mode.</p>
                    <p>
                        You can <span className="fw-bold">move</span>, <span className="fw-bold">manipulate</span> and{" "}
                        <span className="fw-bold">edit existing content</span> inside your space.
                    </p>
                    <small className="text-muted">
                        The button will also show up for students when using Student Projects or when "Allow Creation"
                        permission is granted.
                    </small>
                </div>
            )
        },
        {
            target: "#step-toolbar-help",
            disableOverlayClose: true,
            setNextStep: () => {
                setStepIndex(stepIndex + 1);
            },
            setPrevStep: () => setStepIndex(stepIndex - 1),
            title: "Help and resources",
            content: (
                <div>
                    <p className="fw-bold">Help & Settings</p>
                    <p>
                        The right buttons on the toolbar allows you to open
                        <span className="fw-bold">Help & Resources</span>, set your{" "}
                        <span className="fw-bold">preferences</span>, and{" "}
                        <span className="fw-bold">change your avatar</span> and name.
                    </p>
                </div>
            )
        },
        {
            target: ".a-canvas",
            placement: "center",
            disableOverlay: true,
            skipOnboarding: () => {
                props.skippedOnboarding();
            },
            setNextStep: () => {
                props.finishedOnboarding();
            },
            setPrevStep: () => setStepIndex(stepIndex - 1),
            lastStep: true,
            title: "Conclusion",
            content: (
                <div>
                    <p className="fw-bold">This concludes the introduction tutorial.</p>
                    <p>Next steps:</p>
                    <ul>
                        <li>Get comfortable moving and looking around</li>
                        <li>Explore the toolbar buttons</li>
                    </ul>
                </div>
            )
        }
    ];

    const defaultOptions = {
        arrowColor: "#fff",
        backgroundColor: "#fff",
        beaconSize: 36,
        overlayColor: "rgba(0, 0, 0, 0.5)",
        primaryColor: "#6f6ec4",
        spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
        textColor: "#333",
        width: undefined,
        zIndex: 100
    };

    return (
        <>
            <AnchorPoint position="top-start" />
            <Joyride
                disableBeacons={true}
                disableScrolling={true}
                disableCloseOnEsc={true}
                stepIndex={stepIndex}
                tooltipComponent={OnboardingSlide}
                continuous={true}
                steps={steps}
                style={defaultOptions}
            />
        </>
    );
}
