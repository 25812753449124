import React, { useState } from "react";
import ToolbarButton from "./ToolbarButton";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Popover from "react-bootstrap/Popover";
import { spawnReaction, availableEmojis } from "../../../utils/emoji-utils.js";

export default function ReactionButton(props) {
    if (!props.tutorial && !window.APP.objectHelper.can("can_react")) {
        return null;
    }

    const [show, setShow] = useState(false);

    const menu = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Spawn Reaction</Popover.Header>
            <Popover.Body>
                <Row>
                    {availableEmojis.map(emoji => {
                        return (
                            <Col key={emoji.id}>
                                <Button
                                    variant="none"
                                    className=""
                                    onClick={() => {
                                        spawnReaction(emoji.particle);
                                        setShow(false);
                                    }}
                                >
                                    <img
                                        src={emoji.particle}
                                        alt={emoji.id}
                                        style={{
                                            width: "30px",
                                            height: "30px"
                                        }}
                                    />
                                </Button>
                            </Col>
                        );
                    })}
                </Row>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <ToolbarButton
                title="reactions"
                variant="purple-dark"
                icon={<i className="bi bi-emoji-smile-fill"></i>}
                tipTitle={"Reactions"}
                tipBody={"Click to open the Reaction menu where you can spawn emoji particles around your avatar."}
                disableTooltip={props.disableTooltip}
                onClick={() => setShow(!show)}
                rootClose={true}
                show={show}
                overlayMenu={show ? menu : null}
            />
        </>
    );
}
