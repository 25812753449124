import React, { useEffect, useState, useRef } from "react";

import { SketchPicker } from "react-color";
import { defineQuery, hasComponent, addComponent, removeComponent, removeEntity } from "bitecs";
import {
    Link,
    Text,
    Owner,
    GLTFModel,
    Question,
    LiveFeed,
    Equirectangular,
    PDFSettingsChanged,
    MediaPDF,
    Description,
    YouTube,
    SpinningAnimation
} from "../../../../bit-components";

import { spawnFromFileList } from "../../../../load-media-on-paste-or-drop";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import TextMenu from "./specific/TextMenu";
import LinkMenu from "./specific/LinkMenu";
import YouTubeMenu from "./specific/YoutubeMenu";
import PDFMenu from "./specific/PDFMenu";

import { toast } from "react-hot-toast";

export default function Specific({ object, scene }) {
    const world = APP.world;

    const [editingDescription, setEditingDescription] = useState(false);

    const [isInspecting, setIsInspecting] = useState(false);

    const inputFile = useRef(null);
    const descriptionTarget = useRef(null);

    const changeDescription = e => {
        Description.value[object.eid] = APP.getSid(e.target.value); // Store string temp as reference instead of letters in array
        window.APP.objectHelper.change(object.eid);
    };

    const replaceFile = e => {
        // Upload file and get fileasset id
        spawnFromFileList(e.target.files, false, object);
    };

    if (editingDescription) {
        return (
            <div className="p-3 pt-2">
                <p>
                    Set the description for this object. The description will be visible for all users when they click
                    the object.
                </p>
                <div className="d-grid gap-2 d-md-block mb-3">
                    <input
                        className="form-control"
                        defaultValue={APP.getString(Description.value[object.eid])}
                        onChange={e => changeDescription(e)}
                    />
                </div>
                <Button onClick={() => setEditingDescription(false)} variant="purple-dark">
                    Back
                </Button>
            </div>
        );
    }

    const desc = APP.getString(Description.value[object.eid]);


    // Interactable Media
    const isImage = object.children.length && object.children[0].name === "Image";
    const isPDF = object.children.length && object.children[0].name === "PDF";
    const isModel =
        object.name === "Interactable Media" && object.children.length && (object.children[0].name === "Scene" || object.children[0].name === "Sketchfab_Scene");
    const isAudio = object.children.length && object.children[0].name === "Audio";
    const isVideo = object.children.length && object.children[0].name === "Video";

    const isFile = isVideo || isAudio || isPDF || isModel || isImage;

    // Other components
    const isYouTube = hasComponent(APP.world, YouTube, object.eid);
    const isText = object.name === "Interactable Text";
    const isQuestion = object.name === "Question";
    const isLink = object.name === "Link";
    const isLive = hasComponent(world, LiveFeed, object.eid);

    console.log("Is Model", isModel, object.name, object);

    const shouldShowReplaceFile = isFile;
    const shouldShowDescription =
        (isImage || isModel || isAudio || isVideo || isPDF) && hasComponent(world, Description, object.eid);

    const shouldShowEditMenu = !isQuestion;

    return (
        <div>
            {shouldShowEditMenu && (
                <div className="d-grid gap-2 d-md-block">
                    <p className="fw-bold">Edit</p>
                </div>
            )}
            <Row>
                {!!shouldShowDescription && (
                    <Col>
                        <div className="d-grid gap-2">
                            <Button
                                variant="purple-dark"
                                className="text-start btn-sm"
                                onClick={() => setEditingDescription(true)}
                            >
                                <i className="bi bi-card-text"></i>
                                <span className="ms-2">Description</span>
                            </Button>
                        </div>
                    </Col>
                )}
                {!!shouldShowReplaceFile && (
                    <Col xs={6}>
                        <div className="d-grid gap-2 d-md-block mb-3">
                            <input
                                type="file"
                                ref={inputFile}
                                id="replaceFileInput"
                                onInput={e => {
                                    replaceFile(e);
                                }}
                                style={{ display: "none" }}
                            />
                            <Button
                                variant="purple-dark"
                                className="text-start btn-sm w-100"
                                onClick={() => inputFile.current.click()}
                            >
                                <i className="bi bi-file-earmark-arrow-up"></i>
                                <span className="ms-2">Replace</span>
                            </Button>
                        </div>
                    </Col>
                )}
                {/* isPDF && <PDFMenu object={object} /> */}
                {isYouTube && <YouTubeMenu object={object} />}
                {isLink && <LinkMenu object={object} />}
                {isText && <TextMenu object={object} />}
            </Row>
        </div>
    );
}
