import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export function MobilePreviewMessage() {
    return (
        <Row className="position-fixed text-center">
            <Col className="bg-white rounded">
                <p className="mb-0 fw-light text-muted p-2">
                    <small>Preview Mode has limited functionality and disabled networked features.</small>
                </p>
            </Col>
        </Row>
    );
}

export function PreviewMessage() {
    return (
        <Row className="position-absolute top-0 start-50 translate-middle-x">
            <Col className="bg-white rounded mt-2">
                <p className="mb-0 fw-light text-muted">
                    <small>Preview Mode has limited functionality and disabled networked features.</small>
                </p>
            </Col>
        </Row>
    );
}
