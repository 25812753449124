import React from "react";
import CloseButton from "react-bootstrap/CloseButton";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";

export default function ExtendedModal(props) {
    /*
     Available positions
        "top-start",
        "top-center",
        "top-end",
        "middle-start",
        "middle-center",
        "middle-end",
        "bottom-start",
        "bottom-center",
        "bottom-end",
    */

    return (
        <ToastContainer
            className={props.title == "Edit Object" ? "step-objectmenu ms-4 me-4" : "ms-4 me-4"}
            position={props.position}
        >
            <Toast
                className="bg-white"
                onClose={() => props.closePopover()}
                animation={false}
                style={{
                    maxWidth: "480px",
                    width: props.fixedWidth ? "480px" : "auto"
                }}
                show={props.show}
            >
                <div className="p-2 bg-light border-bottom rounded-top handle">
                    <CloseButton className="float-end" onClick={() => props.closePopover()} />
                    <strong>{props.title}</strong>
                </div>
                <div
                    style={{
                        maxHeight: "60vh",
                        height: props.fixedHeight ? "60vh" : "auto",
                        overflow: "auto",
                        overflowY: "scroll"
                    }}
                >
                    {props.content}
                </div>
            </Toast>
        </ToastContainer>
    );
}
