import React, { useEffect, useState, useRef } from "react";

import { defineQuery, hasComponent, addComponent, removeComponent, removeEntity } from "bitecs";
import { Link } from "../../../../../bit-components";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { toast } from "react-hot-toast";

export default function LinkMenu({ object }) {
    const world = APP.world;

    const [pendingLinkSave, setPendingLinkSave] = useState(false);
    const [changingLink, setChangingLink] = useState(false);
    const [linkValue, setLinkValue] = useState(APP.getString(Link.url[object.eid]));

    const changeLink = () => {
        // massage link
        if (linkValue.startsWith("https://") || linkValue.startsWith("http://")) {
            Link.url[object.eid] = APP.getSid(linkValue);
            window.APP.objectHelper.change(object.eid, linkValue);
            setChangingLink(false);
        } else {
            Link.url[object.eid] = APP.getSid("https://" + linkValue);
            setChangingLink(false);
            setLinkValue("https://" + linkValue);
            window.APP.objectHelper.change(object.eid, link);
        }
    };

    return (
        <Col xs={changingLink ? 12 : 6} className="mb-3">
            {!changingLink ? (
                <Button variant="purple-dark" className="text-start btn-sm w-100" onClick={() => setChangingLink(true)}>
                    <i className="bi bi-bullseye" />
                    <span className="ms-2">Change Link</span>
                </Button>
            ) : (
                <>
                    <input
                        id="troika-value-input"
                        className="form-control mb-2"
                        type="text"
                        value={linkValue}
                        onChange={e => setLinkValue(e.target.value)}
                    />
                    <Button variant="success" className="text-start btn-sm me-2" onClick={() => changeLink()}>
                        <span>Save</span>
                    </Button>
                    <Button variant="purple-dark" className="text-start btn-sm" onClick={() => setChangingLink(false)}>
                        <span>Cancel</span>
                    </Button>
                </>
            )}
        </Col>
    );
}
