import { defineQuery, hasComponent, Changed, removeComponent, enterQuery } from "bitecs";
import { Text } from "../../bit-components";

const changedTextQuery = defineQuery([Changed(Text)]);

export function textSystem(world) {
    changedTextQuery(world).forEach(function (eid) {
        const obj = APP.world.eid2obj.get(eid);

        if (obj.name == "Interactable Text") {
            const value = APP.getString(Text.value[eid]);
            const color = APP.getString(Text.color[eid]);
            const maxWidth = parseFloat(APP.getString(Text.maxWidth[eid]));
            const align = APP.getString(Text.textAlign[eid]);

            obj.text = value;
            if (color) {
                obj.color = color;
            }
            if (maxWidth) {
                obj.maxWidth = maxWidth;
            }
            obj.textAlign = align;

            obj.sync();
        }
    });
}
