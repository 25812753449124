import { defineQuery, enterQuery, Changed } from "bitecs";
import { YouTube } from "../../../bit-components";

const youtubeQuery = defineQuery([YouTube]);
const youtubeEntry = enterQuery(youtubeQuery);
const changedYoutubeQuery = defineQuery([Changed(YouTube)]);

const worldPos = new THREE.Vector3();

export function youtubeSystem(world) {
    youtubeEntry(world).forEach(function (eid) {
        // const object3d = APP.getString(YouTube.obj3d[eid]);
        // console.log("adding object3d to scene", object3d);
        // window.APP.scene.object3D.add(object3d);
    });

    changedYoutubeQuery(world).forEach(function (eid) {
        /*
        const value = APP.getString(YouTube.src[eid]);
        const object3d = APP.getString(YouTube.obj3d[eid]);
        object.element.src = value;

        console.log("Got change src on Youtbue", object3d);
        */
    });

    youtubeQuery(world).forEach(function (eid) {
        const parent = world.eid2obj.get(eid);
        const youtube = APP.getString(YouTube.obj3d[eid]);
        const placeholder = APP.getString(YouTube.placeholder[eid]);

        const avatarPovObject = document.getElementById("avatar-pov-node").object3D;
        avatarPovObject.getWorldPosition(worldPos);

        /*
        youtube.position.set(parent.position.x, parent.position.y, parent.position.z);
        youtube.rotation.set(parent.rotation.x, parent.rotation.y, parent.rotation.z);
        youtube.scale.set(parent.scale.x / 1000, parent.scale.y / 1000, parent.scale.z / 1000);
        youtube.updateMatrix();
        */

        youtube.element.style.width = (parent.scale.x * 16 * 28).toString() + "px";
        youtube.element.style.height = (parent.scale.y * 10 * 28).toString() + "px";

        const isNearby = worldPos.distanceTo(parent.position) < 0.8 + (parent.scale.x > 1 ? parent.scale.x * 0.4 : 1);

        if (!window.APP.editMode) {
            if (isNearby) {
                placeholder.visible = false;
                youtube.element.style.pointerEvents = "auto";
                youtube.element.style.opacity = "1";
            } else {
                placeholder.visible = true;
                youtube.element.style.pointerEvents = "none";
                youtube.element.style.opacity = "0";
            }
        }
        if (window.APP.editMode) {
            if (isNearby) {
                placeholder.visible = true;
                youtube.element.style.pointerEvents = "none";
                youtube.element.style.opacity = "0.2";
            } else {
                placeholder.visible = true;
                youtube.element.style.pointerEvents = "none";
                youtube.element.style.opacity = "0";
            }
        }
    });
}

/*
 * o
        if (window.APP.editMode) {
            placeholder.visible = true;

            if (isNearby) {
                youtube.visible = true;
                youtube.element.style.pointerEvents = "none";
                youtube.element.style.opacity = "0.3";
            } else {
                youtube.visible = false;
                youtube.element.style.pointerEvents = "none";
                youtube.element.style.opacity = "0.3";
            }
        } else {

*/
