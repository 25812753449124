import React, { useState, useEffect } from "react";
import { RootContainer } from "../misc/RoomLayout";
import { KeyHints } from "./KeyHints";
import { IdleDetected } from "./IdleDetected";

import LeftToolbar from "./toolbars/LeftToolbar";
import CenterToolbar from "./toolbars/CenterToolbar";
import RightToolbar from "./toolbars/RightToolbar";
import MobileToolbar from "./toolbars/MobileToolbar";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const isMobile = AFRAME.utils.device.isMobile();

export default function ClassroomInterface(props) {
    const [showKeyHints, setShowKeyHints] = useState(!window.APP.store.state.preferences.hideHints);
    const [showIdleDetected, setShowIdleDetected] = useState(false);

    useEffect(() => {
        console.log("Adding event listener to the Classroom Interface");
        props.scene.addEventListener("keyhints_updated", checked => {
            setShowKeyHints(!checked.detail);
        });
        window.addEventListener("idle_detected", () => {
            setShowIdleDetected(true);
        });
        window.addEventListener("idle_cancelled", () => {
            setShowIdleDetected(false);
        });
    }, []);

    return (
        <RootContainer>
            {showIdleDetected && <IdleDetected />}
            {!isMobile && showKeyHints && <KeyHints />}
            {isMobile ? (
                <div
                    className="toolbar-mobile d-flex justify-content-middle w-fit-content clickable"
                    style={{
                        overflowX: "scroll",
                        alignSelf: "flex-end",
                        justifySelf: "center",
                        borderRadius: "4px 4px 0px 0px"
                    }}
                >
                    <MobileToolbar
                        audioStatus={props.audioStatus}
                        scene={props.scene}
                        sessionId={props.sessionId}
                        presences={props.presences}
                    />
                </div>
            ) : (
                <Row
                    id="step-toolbar"
                    className={
                        !props.finishedViewModeOnboarding && props.onboardingStep && props.onboardingStep < 3
                            ? "opacity-0 fixed-bottom toolbar"
                            : "opacity-100 fixed-bottom toolbar"
                    }
                >
                    <Col xs={1} className="col d-flex justify-content-start">
                        <LeftToolbar />
                    </Col>
                    <Col xs={10} className="col d-flex justify-content-center">
                        <CenterToolbar
                            tutorial={
                                !props.finishedViewModeOnboarding && props.onboardingStep && props.onboardingStep > 2
                            }
                            isEditMode={props.isEditMode}
                            subscription={props.room.subscription}
                            presences={props.presences}
                            scene={props.scene}
                            sessionId={props.sessionId}
                            toggleEditMode={props.toggleEditMode}
                            audioStatus={props.audioStatus}
                        />
                    </Col>
                    <Col xs={1} className="d-flex justify-content-end step-toolbar-help">
                        <RightToolbar resetOnboarding={() => props.resetTutorials()} />
                    </Col>
                </Row>
            )}
        </RootContainer>
    );
}
