import React, { useEffect, useState, useRef } from "react";

import { defineQuery, hasComponent, addComponent, removeComponent, removeEntity } from "bitecs";
import { YouTube } from "../../../../../bit-components";
import { toast } from "react-hot-toast";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function youtube_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    if (!match) return "error";

    return match && match[7].length == 11 ? match[7] : false;
}

export default function YouTubeMenu({ object }) {
    const world = APP.world;

    const [changingYoutube, setChangingYoutube] = useState(false);
    const [youtubeValue, setYoutubeValue] = useState(APP.getString(YouTube.src[object.eid]));

    console.log(youtubeValue);

    const changeYoutubeLink = () => {
        console.log("Saving youtube link to ", youtubeValue);

        var id = youtube_parser(youtubeValue);

        if (id === "error") {
            toast.error("That is not a valid YouTube link");
            return;
        }

        var link = "https://www.youtube.com/embed/" + id + "?rel=0";

        YouTube.src[object.eid] = APP.getSid(link);

        window.APP.objectHelper.change(object.eid, link);
        setChangingYoutube(false);
    };

    return (
        <Col xs={changingYoutube ? 12 : 6} className="mb-3">
            {!changingYoutube ? (
                <Button
                    variant="purple-dark"
                    className="text-start btn-sm w-100"
                    onClick={() => setChangingYoutube(true)}
                >
                    <i className="bi bi-bullseye" />
                    <span className="ms-2">Change Video</span>
                </Button>
            ) : (
                <>
                    <input
                        className="form-control mb-2"
                        value={youtubeValue}
                        type="text"
                        onChange={e => setYoutubeValue(e.target.value)}
                    />
                    <Button variant="success" className="text-start btn-sm me-2" onClick={() => changeYoutubeLink()}>
                        <span>Save</span>
                    </Button>
                    <Button
                        variant="purple-dark"
                        className="text-start btn-sm"
                        onClick={() => setChangingYoutube(false)}
                    >
                        <span>Cancel</span>
                    </Button>
                </>
            )}
        </Col>
    );
}
