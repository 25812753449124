import { toast } from "react-hot-toast";

function youtube_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    if (!match) return "error";

    return match && match[7].length == 11 ? match[7] : false;
}

let validLink;
export function createYoutubeObject(link) {
    console.log("LINK IS", link);
    if (link.startsWith("https://www.youtube.com/embed")) {
        console.log("GOT EMBED LINK", link);
        validLink = link;
    } else {
        validLink = youtube_parser(link);
        if (validLink === "error") {
            console.error("Invalid youtube link");
            validLink = "https://www.youtube.com/embed/69FeEVXex10";
        } else {
            validLink = "https://youtube.com/embed/" + validLink;
        }
    }

    var iframe = document.createElement("iframe");

    iframe.style.border = "0px";
    iframe.style.pointerEvents = "auto";

    console.log("Final source is", validLink);
    iframe.src = validLink;

    return iframe;
}
