import React, { useState } from "react";

import { MobileChatSystem } from "../ChatSystemMobile";
import { MobileChatLog } from "../ChatSystemMobile";
import AudioButton from "../buttons/AudioButton";
import RaiseHandButton from "../buttons/RaiseHandButton";
import ReactionButton from "../buttons/ReactionButton";
import FullScreenButton from "../buttons/FullScreenButton";

import ButtonGroup from "react-bootstrap/ButtonGroup";

export default function MobileToolbar(props) {
    const [hideEverythingButChat, setHideEverythingButChat] = useState(false);

    return (
        <ButtonGroup className="">
            {!hideEverythingButChat && (
                <>
                    <AudioButton audioStatus={props.audioStatus} disableTooltip scene={props.scene} />
                    <ReactionButton disableTooltip scene={props.scene} />
                    <RaiseHandButton disableTooltip scene={props.scene} presences={props.presences} sessionid={props.sessionId} />
                </>
            )}
            <MobileChatLog />
            <MobileChatSystem
                isMobile={true}
                hideEverythingElse={() => setHideEverythingButChat(true)}
                showEverythingElse={() => setHideEverythingButChat(false)}
                canChat={window.APP.objectHelper.can("can_chat")}
            />
            {!hideEverythingButChat && <FullScreenButton disableTooltip />}
        </ButtonGroup>
    );
}
