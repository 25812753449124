import { addComponent, hasComponent, getEntityComponents } from "bitecs";
import { HubsWorld } from "../app";
import { ProjectionMode } from "../utils/projection-mode";
import { inflateMediaLoader } from "./media-loader";
import { Equirectangular } from "../bit-components";

export interface ImageLoaderParams {
    src: string;
    recenter: boolean;
    resize: boolean;
    animateLoad: boolean;
    isObjectMenuTarget: boolean;
    equi: ProjectionMode;
}

export function inflateImageLoader(world: HubsWorld, eid: number, params: ImageLoaderParams) {
    console.log("Image Loader Params", params);

    inflateMediaLoader(world, eid, {
        src: params.src,
        recenter: true,
        resize: true,
        sketchfab_meta: "",
        equi: params.equi,
        animateLoad: false,
        isObjectMenuTarget: false
    });
}
