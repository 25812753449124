import React from "react";
import screenfull from "screenfull";
import ToolbarButton from "./ToolbarButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";

export default function FullScreenButton(props) {
    const toggleFullscreen = () => {
        if (screenfull.isEnabled) {
            screenfull.request();
        } else {
            screenfull.exit();
        }
    };

    return (
        <ToolbarButton
            variant="purple-dark"
            icon={<FontAwesomeIcon icon={faExpand} color="#fff" />}
            tipTitle={"Fullscreen"}
            disableTooltip={props.disableTooltip}
            tipBody={"Open MegaMinds in fullscreen"}
            onClick={() => toggleFullscreen()}
        />
    );
}
