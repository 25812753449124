import { defineQuery, enterQuery, Changed } from "bitecs";
import { Peardeck } from "../../../bit-components";

const peardeckQuery = defineQuery([Peardeck]);
const peardeckEntry = enterQuery(peardeckQuery);
const changedPeardeckQuery = defineQuery([Changed(Peardeck)]);

const worldPos = new THREE.Vector3();

export function peardeckSystem(world) {
    peardeckEntry(world).forEach(function (eid) {
        const object3d = APP.getString(Peardeck.obj3d[eid]);
        console.log("ADDED PEARDECK", object3d);
    });

    changedPeardeckQuery(world).forEach(function (eid) {
        console.log("Got change src on Peardeck");
        const object3d = APP.getString(Peardeck.obj3d[eid]);

        object3d.element.src = APP.getString(Peardeck.src[eid]);
    });

    peardeckQuery(world).forEach(function (eid) {
        const parent = world.eid2obj.get(eid);
        const peardeck = APP.getString(Peardeck.obj3d[eid]);
        const placeholder = APP.getString(Peardeck.placeholder[eid]);

        const avatarPovObject = document.getElementById("avatar-pov-node").object3D;
        avatarPovObject.getWorldPosition(worldPos);

        /*
        peardeck.position.set(parent.position.x * 1000, parent.position.y * 1000, parent.position.z * 1000);
        peardeck.rotation.set(parent.rotation.x, parent.rotation.y, parent.rotation.z);
        peardeck.scale.set(parent.scale.x / 1000, parent.scale.y / 1000, parent.scale.z / 1000);
        peardeck.updateMatrix();
        */

        peardeck.element.style.width = (parent.scale.x * 16 * 28).toString() + "px";
        peardeck.element.style.height = (parent.scale.y * 14 * 28).toString() + "px";

        const isNearby = worldPos.distanceTo(parent.position) < 0.8 + (parent.scale.x > 1 ? parent.scale.x * 0.4 : 1);

        if (!window.APP.editMode) {
            if (isNearby) {
                placeholder.visible = false;
                peardeck.element.style.pointerEvents = "all";
                peardeck.element.style.opacity = "1";
            } else {
                placeholder.visible = true;
                peardeck.element.style.pointerEvents = "none";
                peardeck.element.style.opacity = "0";
            }
        }
        if (window.APP.editMode) {
            if (isNearby) {
                placeholder.visible = true;
                peardeck.element.style.pointerEvents = "none";
                peardeck.element.style.opacity = "0.2";
            } else {
                placeholder.visible = true;
                peardeck.element.style.pointerEvents = "none";
                peardeck.element.style.opacity = "0";
            }
        }
    });
}

/*
 * o
        if (window.APP.editMode) {
            placeholder.visible = true;

            if (isNearby) {
                youtube.visible = true;
                youtube.element.style.pointerEvents = "none";
                youtube.element.style.opacity = "0.3";
            } else {
                youtube.visible = false;
                youtube.element.style.pointerEvents = "none";
                youtube.element.style.opacity = "0.3";
            }
        } else {

*/
