import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Filter } from "bad-words";
import { InputGroup } from "react-bootstrap";

function ModelCard(props) {
    return (
        <div
            className="d-flex justify-content-center align-items-center h-100"
            onClick={() => {
                props.onClose();
                window.APP.scene.emit("add_media", props.model.media[0].tinygif.url);
            }}
        >
            <img className="img-fluid rounded pointer-cursor" src={props.model.media[0].nanogif.url} />
            <p>{props.model.name}</p>
        </div>
    );
}

export default function BrowseGifMenu(props) {
    const [search, setSearch] = useState("Science");
    const [tempSearch, setTempSearch] = useState("Science");
    const [cursor, setCursor] = useState("0");
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [containProfanity, setContainProfanity] = useState(false);
    const filter = new Filter();
    const whiteList = ["god"];
    filter.removeWords(...whiteList);

    useEffect(() => {
        if(filter.isProfane(search)){
            setContainProfanity(true);
            setLoading(false);
        } else {
            fetch("https://g.tenor.com/v1/search?q=" + search + "&key=A9TWEI5QZNBZ&limit=20&contentfilter=high")
            .then(response => response.json())
            .then(data => {
                setContainProfanity(false);
                setResults(data);
                setLoading(false);
            });
        }
    }, [search, cursor]);

    useEffect(() => {     
        fetch("https://g.tenor.com/v1/search?q=" + search + "&key=A9TWEI5QZNBZ&limit=20&contentfilter=high")
            .then(response => response.json())
            .then(data => {
                setContainProfanity(false);
                setResults(data);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!results) {
        return <p>Could not get results from Tenor</p>;
    }

    const handleSearch = () => {
        setCursor(0);
        setSearch(tempSearch);
    };

    return (
        <>
            <Container className="p-3">
                <Row>
                    <Col>
                        <InputGroup>
                            <input
                                className="form-control"
                                placeholder="Search GIFs..."
                                value={tempSearch}
                                onChange={e => setTempSearch(e.target.value)}   
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                            />
                            <Button variant="secondary" onClick={() => handleSearch()}>
                                Search
                            </Button>
                        </InputGroup>
                        <span className="fw-light text-muted">Powered by Tenor</span>
                    </Col>
                </Row>
            </Container>
            {containProfanity ? (
                <small className="d-flex justify-content-center">No results found</small>
            ) : (
                <Container fluid className="bg-light p-3 border-top">
                <Row xs={3} className="g-4">
                    {results.results.map(result => (
                        <Col key={result.id}>
                            <ModelCard onClose={props.onClose} model={result} />
                        </Col>
                    ))}
                </Row>
                <Row className="p-3">
                    <Col className="d-flex justify-content-start">
                        {(results.cursors && results.cursors.previous) ||
                            (results.previous && (
                                <Button
                                    variant="purple-dark"
                                    onClick={() => setCursor(results.cursors.previous ? results.cursors.previous : "0")}
                                >
                                    Previous Page
                                </Button>
                            ))}
                    </Col>
                    <Col className="d-flex justify-content-end">
                        {results.cursors && results.cursors.next && (
                            <Button variant="purple-dark" onClick={() => setCursor(results.cursors.next)}>
                                Next Page
                            </Button>
                        )}
                    </Col>
                </Row>
            </Container>
            )}
            
        </>
    );
}
