import React, { useCallback } from "react";
import ToolbarButton from "./ToolbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHand } from "@fortawesome/free-solid-svg-icons";
import usePresence from "../../hooks/usePresence";
import { getPresenceProfileForSession } from "../../../utils/phoenix-utils";

export default function RaiseHandButton(props) {
    if (!props.tutorial && !window.APP.objectHelper.can("can_raise_hand")) {
        return null;
    }
    const initialPresence = getPresenceProfileForSession(props.presences, props.sessionId);

    const presence = usePresence(props.scene, initialPresence);

    const onToggleHandRaised = useCallback(() => {
        if (presence.hand_raised) {
            window.APP.hubChannel.lowerHand();
        } else {
            window.APP.hubChannel.raiseHand();
            window.APP.userHelper.register_event("com_raised_hand");
        }
    }, [presence]);

    return (
        <ToolbarButton
            title="raise-hand"
            variant={presence.hand_raised ? "success" : "purple-dark"}
            icon={<FontAwesomeIcon icon={faHand} />}
            onClick={onToggleHandRaised}
            disableTooltip={props.disableTooltip}
            tipTitle={"Toggle Hand Raise"}
            tipBody={"Click to raise and lower your hand."}
        />
    );
}
