import { addComponent } from "bitecs";
import { Quizizz } from "../bit-components";
import { addObject3DComponent } from "../utils/jsx-entity";
import { createQuizizzObject } from "../utils/css2d/create-quizizz-object";
import { CSS3DObject } from "three/examples/jsm/renderers/CSS3DRenderer";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { quizizzTexture } from "../utils/textures/quizizz-texture";

export function inflateQuizizz(world, eid, params) {
    const questionContainer = createQuizizzObject(params.link);

    const geometry = new THREE.BoxGeometry(0.398 * (16 / 9), 0.47, 0.02);
    const material = new THREE.MeshBasicMaterial({ map: quizizzTexture, color: 0xffffff });

    // This is a helper cube when css3dobject is out of range, or to move it around in Edit Mode.
    console.log("Quizizz inflating");
    const cube = new THREE.Mesh(geometry, material);

    cube.visible = false;

    const parentObj = new THREE.Object3D();

    parentObj.add(cube);
    parentObj.position.setZ(-0.02);

    const obj3d = new CSS2DObject(questionContainer);
    parentObj.add(obj3d);

//    window.APP.scene.object3D.add(obj3d);
    addComponent(world, Quizizz, eid);

    Quizizz.src[eid] = APP.getSid(obj3d.element.src);
    Quizizz.obj3d[eid] = APP.getSid(obj3d);
    Quizizz.placeholder[eid] = APP.getSid(cube);

    addObject3DComponent(world, eid, parentObj);

    console.log("Quizizz done inflating");
    return eid;
}
