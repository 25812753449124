import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Popover from "react-bootstrap/Popover";
import { Tooltip } from "react-tooltip";

import { createNetworkedEntity } from "../../../../utils/create-networked-entity";

export default function CreatePeardeckMenu(props) {
    const [link, setLink] = useState("");

    const handleSubmit = () => {
        const eid = createNetworkedEntity(APP.world, "peardeck", {
            recenter: true,
            resize: true,
            link: link
        });

        const avatarPov = document.querySelector("#avatar-pov-node").object3D;
        const obj = APP.world.eid2obj.get(eid);
        obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
        obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

        setTimeout(() => {
            window.APP.objectHelper.save(eid, null, link, link, "peardeck");
        }, 1000);

        props.onFinish();
    };

    return (
        <div className="p-3">
            <Tooltip id="addMediaTooltip" />
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Back to list"
                variant="light"
                onClick={() => props.onBack()}
            >
                <i className="bi bi-arrow-left"></i>
            </Button>
            <Button
                href="https://megaminds.tawk.help/article/can-i-add-pear-deck-to-my-lesson"
                target="_blank"
                variant="light"
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="How to add Pear Deck to your Lesson"
            >
                <i className="bi bi-question-circle-fill"></i>
            </Button>
            <hr />
            <Col>
                <p>Use a Peardeck link to add a Peardeck Session / Slides to your Lesson.</p>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Control
                        type="text"
                        name="url"
                        placeholder="https://app.peardeck.com/student/tlxgbeekb"
                        onChange={e => setLink(e.target.value)}
                    />
                </Form.Group>
                <Button onClick={() => handleSubmit()} variant="success">
                    Add
                </Button>
            </Col>
        </div>
    );
}
