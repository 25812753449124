/** @jsx createElementEntity */
import { createElementEntity, EntityDef } from "../utils/jsx-entity";
import { COLLISION_LAYERS } from "../utils/constants";
import { FLOATY_OBJECT_FLAGS } from "../systems/floaty-object-system";
import { MediaLoaderParams } from "../inflators/media-loader";
import question_model from "../assets/models/question.glb";

export function QuestionPrefab(params) {
    const object_id = params.object_id;
    const variant = params.variant;
    const bundle = params.bundle;

    return (
        <entity
            name="Question"
            networked
            question={{ object_id: object_id, variant: variant, bundle: bundle }}
            networkedTransform
            billboard
            locked
            nickname
            description
            studentsCanMove
            mediaLoader={{ src: question_model, recenter: true, resize: true }}
            deletable
            grabbable={{ cursor: true, hand: true }}
            destroyAtExtremeDistance
            floatyObject={{
                flags: FLOATY_OBJECT_FLAGS.UNTHROWABLE,
                releaseGravity: 0
            }}
            rigidbody={{ collisionGroup: COLLISION_LAYERS.INTERACTABLES, collisionMask: COLLISION_LAYERS.HANDS }}
            scale={[1, 1, 1]}
        />
    );
}
