import React, { useCallback, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSound } from "../../hooks/useSound";
import { SOUND_SPEAKER_TONE } from "../../../systems/sound-effects-system";
import { useSpeakers } from "../../hooks/useSpeakers";
import { useVolumeMeter } from "../../hooks/useVolumeMeter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export function VolumeLevelBar() {
    const [isTalking, setIsTalking] = useState(false);

    const update = useCallback(level => {
        const pct = level * 100;
        if (pct > 20) {
            setIsTalking(true);
        } else {
            setIsTalking(false);
        }
    }, []);

    useVolumeMeter({
        analyser: window.APP.scene.systems["hubs-systems"].audioSystem.outboundAnalyser,
        update
    });

    if (isTalking)
        return (
            <span className="float-end fw-light">
                Activity Detected<i className="ms-2 bi bi-check-circle-fill text-success"></i>
            </span>
        );

    return null;
}

export function MicSetupBox({ scene, onEnterRoom, audioStatus }) {
    const { playSound } = useSound({
        scene,
        sound: SOUND_SPEAKER_TONE
    });

    const [isMicMutedOnEntry, setIsMicMutedOnEntry] = useState(APP.store.state.preferences["muteMicOnEntry"]);

    const onChangeMicrophoneMuted = useCallback(muted => {
        setIsMicMutedOnEntry(muted);
        APP.store.update({
            preferences: { muteMicOnEntry: muted }
        });
    }, []);

    return (
        <Row className="">
            <Col xs={12}>
                {audioStatus.permissionStatus === "prompt" && <div>Loading...</div>}
                <>
                    <FontAwesomeIcon icon={faMicrophone} />
                    <span className="ms-2">Select Microphone</span>
                    <VolumeLevelBar />
                </>
                {audioStatus.permissionStatus === "allowed" && (
                    <select
                        value={audioStatus.selectedMicId}
                        onChange={e => audioStatus.changeInputDevice(e.target.value)}
                        className="form-select mt-3"
                        aria-label="Select a microphone"
                    >
                        {audioStatus.input.availableMicDevices.map(mic => {
                            return (
                                <option key={mic.value} value={mic.value}>
                                    {mic.label}
                                </option>
                            );
                        })}
                    </select>
                )}
                <div className="form-check mt-2">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="micCheck"
                        checked={isMicMutedOnEntry}
                        onChange={e => onChangeMicrophoneMuted(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="micCheck">
                        Mute microphone on entry
                    </label>
                </div>
            </Col>
            {audioStatus.permissionStatus === "allowed" && (
                <Col xs={12} className="mt-3">
                    <FontAwesomeIcon icon={faVolumeUp} />
                    <span className="ms-2">Select Speaker</span>
                    <div className="d-grid">
                        <div>
                            <select
                                className="form-select mt-3"
                                onChange={e => audioStatus.changeOutputDevice(e.target.value)}
                                aria-label="Select audio output device"
                            >
                                {audioStatus.output.availableOutputDevices.map(speaker => {
                                    return (
                                        <option key={speaker.value} value={speaker.value}>
                                            {speaker.label}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <Button variant="purple-light" className="mt-3" onClick={playSound}>
                            <span className="ms-2">Play Test Sound</span>
                        </Button>
                    </div>
                </Col>
            )}
            <Col xs={12} className="d-grid mt-3">
                <Button variant="purple-dark" onClick={onEnterRoom}>
                    <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                    <span>Back</span>
                </Button>
            </Col>
        </Row>
    );
}

MicSetupBox.propTypes = {
    scene: PropTypes.object.isRequired
};
