import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";

const hubId = document.location.pathname.substring(1).split("/")[0];

const useAvatars = hubsNetworkingFinished => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [avatars, setAvatars] = useState(null);
    const [counter, setCounter] = useState(0);

    const refresh = () => {
        setCounter(counter + 1);
    };

    useEffect(() => {
        if (hubsNetworkingFinished) {
            if(NAF.clientId) {
            const payload = {
                hub_id: window.APP.classroom.hub_id,
                clientId: window.APP.clientId
            };
            console.log("Posting payload", payload, window.APP.clientId, NAF.clientId);
            fetch(process.env.REACT_APP_MM_API_URL + "livekit/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            })
                .then(resp => {
                    if (!resp.ok) {
                        setAvatars(null);
                        setLoading(false);
                        setError(resp.status);
                        return;
                    }
                    return resp.json();
                })
                .then(data => {
                    console.log("RESP OK");
                    setAvatars(data);
                    setLoading(false);
                    ReactGA.send({ hitType: "pageview", page: hubId });
                })
                .catch(e => {
                    console.error("Error", e);
                    setAvatars(null);
                    setError(e);
                    setLoading(false);
                });
            }
        }
    }, [hubsNetworkingFinished, NAF.clientId]);

    return [avatars, loading, error, refresh];
};

export default useAvatars;
