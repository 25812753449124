import { addComponent } from "bitecs";
import { Question, Networked } from "../bit-components";
import { addObject3DComponent } from "../utils/jsx-entity";
import { createQuestionObject } from "../utils/css2d/create-question-object";
import { createMCQObject } from "../utils/css2d/create-mcq-object";
import { createLegacyQuestionObject } from "../utils/css2d/create-legacy-question-object";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";

export function inflateQuestion(world, eid, params) {
    console.log(params);

    if (params.variant === "multiplechoice") {
        console.log("Multiple Choice Question");
        addComponent(world, Question, eid);
        const questionContainer = createMCQObject(params.bundle, params.object_id);
        const obj2d = new CSS2DObject(questionContainer);

        Question.obj2d[eid] = APP.getSid(obj2d);
        addObject3DComponent(world, eid, obj2d);

        return eid;
    } else if (params.variant === "freetext") {
        console.log("Normal Question", params);
        const questionContainer = createQuestionObject(params.bundle, params.object_id);
        const obj2d = new CSS2DObject(questionContainer);

        addComponent(world, Question, eid);

        Question.obj2d[eid] = APP.getSid(obj2d);
        addObject3DComponent(world, eid, obj2d);

        return eid;
    } else {
        console.log("Got no return on legacy question");
        const questionContainer = createLegacyQuestionObject();
        const obj2d = new CSS2DObject(questionContainer);

        addComponent(world, Question, eid);

        Question.obj2d[eid] = APP.getSid(obj2d);
        addObject3DComponent(world, eid, obj2d);
    }
}
