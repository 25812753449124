import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

import { MicSetupBox } from "./MicSetupBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export function AudioStep(props) {
    return (
        <div className="position-absolute top-50 start-50 translate-middle ui">
            <Container className="d-flex justify-content-center p-0">
                <Card style={{ maxWidth: "26rem", minWidth: "16rem" }} className="clickable">
                    <Card.Body>
                        <Card.Title className="mb-2">Audio Setup</Card.Title>
                        <Card.Text>
                            <span className="fw-light">Check your microphone and audio before entering.</span>
                        </Card.Text>
                    </Card.Body>
                    <Card.Body>
                        <Row>
                            <Col xs={12}>
                                {props.audioStatus.permissionStatus === "error" ? (
                                    <>
                                        <div className="alert alert-warning" role="alert">
                                            <span className="fw-bold ms-2">Could not get microphone permissions.</span>
                                            <Button
                                                href="https://megaminds.tawk.help/article/microphone-permissions"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                variant="link"
                                            >
                                                Learn more
                                            </Button>
                                        </div>
                                        <Button variant="purple-dark" onClick={() => props.setStep("profile")}>
                                            <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                                            <span>Back</span>
                                        </Button>
                                    </>
                                ) : (
                                    <MicSetupBox
                                        scene={props.scene}
                                        onEnterRoom={() => props.setStep("profile")}
                                        audioStatus={props.audioStatus}
                                        setError={props.setError}
                                        onBack={() => props.setStep("profile")}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}
