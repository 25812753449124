import React from "react";
import ToolbarButton from "./ToolbarButton";

const getVariant = (muted, enabled) => {
    if (enabled) {
        if (muted) {
            return "danger";
        } else {
            return "success";
        }
    } else {
        return "warning";
    }
};

export default function AudioButton(props) {
    if (!props.tutorial && !window.APP.objectHelper.can("can_share_microphone")) {
        return null;
    }
    const toggleMute = () => {
        props.audioStatus.toggleMic();
    };

    return (
        <ToolbarButton
            variant={getVariant(props.audioStatus.micMuted, props.audioStatus.permissionStatus !== "error")}
            title="audio"
            tipTitle={"Toggle Microphone"}
            icon={props.audioStatus.micMuted ? <i className="bi bi-mic-mute-fill" /> : <i className="bi bi-mic-fill" />}
            onClick={() => toggleMute()}
            tipBody={
                props.audioStatus.permissionStatus !== "error"
                    ? "Click to mute and unmute your microphone"
                    : "MegaMinds was denied access to your microphone in the browser settings."
            }
            disableTooltip={props.disableTooltip}
        />
    );
}
