import { addComponent, hasComponent } from "bitecs";
import { HubsWorld } from "../app";
import { MediaLoader, FileReplaced, Equirectangular } from "../bit-components";
import { MEDIA_LOADER_FLAGS } from "../bit/systems/media-loading";

export type MediaLoaderParams = {
    src: string;
    fileAsset?: any;
    equi?: any;
    resize: boolean;
    recenter: boolean;
    sketchfab_meta: string;
    animateLoad: boolean;
    isObjectMenuTarget: boolean;
};

export function inflateMediaLoader(
    world: HubsWorld,
    eid: number,
    { src, equi, fileAsset, recenter, resize, animateLoad, isObjectMenuTarget }: MediaLoaderParams
) {
    addComponent(world, MediaLoader, eid);
    let flags = 0;
    if (recenter) flags |= MEDIA_LOADER_FLAGS.RECENTER;
    if (resize) flags |= MEDIA_LOADER_FLAGS.RESIZE;
    if (animateLoad) flags |= MEDIA_LOADER_FLAGS.ANIMATE_LOAD;
    if (isObjectMenuTarget) flags |= MEDIA_LOADER_FLAGS.IS_OBJECT_MENU_TARGET;
    if (equi) flags |= MEDIA_LOADER_FLAGS.IS_EQUI;
    MediaLoader.flags[eid] = flags;
    MediaLoader.src[eid] = APP.getSid(src)!;
}
