import { addComponent, removeComponent } from "bitecs";
import { createNetworkedEntity } from "./utils/create-networked-entity";
import youtube_model from "./assets/models/youtube.glb";
import { FileReplaced, Nickname, StickyNote } from "./bit-components";
import { upload, parseURL } from "./utils/media-utils";
import { guessContentType } from "./utils/media-url-utils";
import { Vector3 } from "three";
import { toast } from "react-hot-toast";

export async function spawnFromUrl(text) {
    if (!text) {
        return;
    }
    if (!parseURL(text)) {
        console.warn(`Could not parse URL. Ignoring pasted text:\n${text}`);
        return;
    }
    const eid = createNetworkedEntity(APP.world, "media", {
        src: text,
        recenter: true,
        resize: true
    });

    const avatarPov = document.querySelector("#avatar-pov-node").object3D;
    const obj = APP.world.eid2obj.get(eid);
    obj.position.copy(avatarPov.localToWorld(new Vector3(0, 0, -1.7)));
    obj.scale.set(1.7, 1.7, 1.7);
    obj.lookAt(avatarPov.getWorldPosition(new Vector3()));

    setTimeout(() => {
        window.APP.objectHelper.save(eid, null, text, text);
    }, 1000);
    return obj;
}

export const getPosition = (i, scene_positions) => {
    if (i >= scene_positions.length) {
        return {
            position: [
                Math.random() * (2 - 1 + 1) + 1,
                Math.random() * (2 - 1 + 1) + 1,
                Math.random() * (2 - 1 + 1) + 1
            ],
            rotation: [0, 0, 0, 1],
            scale: [2, 2, 2]
        };
    } else {
        // Still available scenepositions left
        return scene_positions[i];
    }
};

export async function spawnFromFileList(files, isStickyNote = false, replace = null, position_set) {
    console.log(position_set);

    let index = 0;

    for (const file of files) {
        if (file.type === "youtube") {
            // Make a youtube entity and save
            console.log("Should make youtube entity");
            const eid = createNetworkedEntity(APP.world, "youtube", {
                src: youtube_model,
                recenter: true,
                resize: true,
                link: file.name
            });

            const obj = APP.world.eid2obj.get(eid);
            const position = getPosition(index, position_set);
            index = index + 1;

            obj.scale.set(1.0, 1.0, 1.0);
            obj.position.set(position.position[0], position.position[1], position.position[2]);
            obj.quaternion.set(position.rotation[0], position.rotation[1], position.rotation[2], position.rotation[3]);
            obj.updateMatrix();
            obj.matrixNeedsUpdate = true;

            setTimeout(() => {
                window.APP.objectHelper.save(eid, null, "YouTube Video", file.name, "youtube");
            }, 1000);
        } else if (file.type === "link") {
            console.log("Should make link entity");
            const eid = createNetworkedEntity(APP.world, "link", { url: file.name });
            const obj = APP.world.eid2obj.get(eid);
            const position = getPosition(index, position_set);
            index = index + 1;
            console.log("Got position and index", position, index);
            obj.scale.set(position.scale[0], position.scale[1], position.scale[2]);
            obj.position.set(position.position[0], position.position[1], position.position[2]);
            obj.quaternion.set(position.rotation[0], position.rotation[1], position.rotation[2], position.rotation[3]);
            obj.updateMatrix();
            obj.matrixNeedsUpdate = true;

            addComponent(APP.world, Nickname, eid);
            Nickname.value[eid] = APP.getSid(file.name);

            setTimeout(() => {
                window.APP.objectHelper.save(eid, null, file.name, file.name, "link");
            }, 1000);
        } else {
            const desiredContentType = file.type || guessContentType(file.name);
            const unsupportedFiles = [
                "undefined",
                "video/x-ms-wmv",
                "video/x-msvideo",
                "video/3gpp",
                "",
                null,
                undefined,
                "audio/ac3",
                "audio/x-realaudio",
                "audio/x-ms-wma",
                "image/tiff"
            ];
            if (unsupportedFiles.includes(desiredContentType)) {
                toast.error("Unsupported filetype");
                throw "Unsupported filetype";
            } else {
                const { src, id } = await upload(file, desiredContentType)
                    .then(function (response) {
                        if (response.file.startsWith("/")) {
                            return { src: "http://localhost:8000" + response.file, id: response.id };
                        } else {
                            return { src: response.file, id: response.id };
                        }
                    })
                    .catch(e => {
                        console.error("Media upload failed", e);
                        return {
                            src: "error",
                            id: null,
                            recenter: true,
                            resize: true,
                            animateLoad: true,
                            isObjectMenuTarget: true
                        };
                    });

                if (replace) {
                    addComponent(APP.world, FileReplaced, replace.eid);
                    FileReplaced.newSrc[replace.eid] = APP.getSid(src);

                    setTimeout(() => {
                        removeComponent(APP.world, FileReplaced, replace.eid);
                    }, 1000);

                    setTimeout(() => {
                        window.APP.objectHelper.replace(replace.eid, id, file.name);
                        window.APP.scene.emit("new_asset");
                    }, 1000);
                } else {
                    const eid = createNetworkedEntity(APP.world, "media", {
                        src: src,
                        fileAsset: id,
                        recenter: true,
                        resize: true,
                        filename: file.name
                    });
                    addComponent(APP.world, Nickname, eid);
                    if (isStickyNote) {
                        addComponent(APP.world, StickyNote, eid);
                        StickyNote.toggled[eid] = true;
                    }
                    addComponent(APP.world, Nickname, eid);
                    const avatarPov = document.querySelector("#avatar-pov-node").object3D;
                    const obj = APP.world.eid2obj.get(eid);
                    if (position_set) {
                        const position = getPosition(index, position_set);
                        index = index + 1;
                        console.log("Got position and index", position, index);
                        obj.scale.set(position.scale[0], position.scale[1], position.scale[2]);
                        obj.position.set(position.position[0], position.position[1], position.position[2]);
                        obj.quaternion.set(
                            position.rotation[0],
                            position.rotation[1],
                            position.rotation[2],
                            position.rotation[3]
                        );
                        obj.updateMatrix();
                        obj.matrixNeedsUpdate = true;
                    } else {
                        obj.scale.set(1.7, 1.7, 1.7);
                        obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.7)));
                        obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));
                        obj.updateMatrix();
                        obj.matrixNeedsUpdate = true;
                    }

                    Nickname.value[eid] = APP.getSid(file.name);
                    setTimeout(() => {
                        window.APP.objectHelper.save(
                            eid,
                            id,
                            file.name,
                            null,
                            isStickyNote ? "stickynote" : desiredContentType
                        );
                        window.APP.scene.emit("new_asset");
                    }, 1000);
                }
            }
        }
    }
}

async function onPaste(e) {
    if (!AFRAME.scenes[0].is("entered")) {
        return;
    }

    if (!window.APP.objectHelper) {
        return;
    }

    if (!window.APP.objectHelper.can("can_create")) {
        return;
    }

    const isPastedInChat =
        (e.target.matches("input, textarea") || e.target.contentEditable === "true") &&
        document.activeElement === e.target;
    if (isPastedInChat) {
        return;
    }
    if (!e.clipboardData) {
        return;
    }
    e.preventDefault();

    if (e.clipboardData && e.clipboardData.files && e.clipboardData.files.length) {
        return spawnFromFileList(e.clipboardData.files);
    }

    const text = e.clipboardData.getData("text");

    const isYoutube = text.includes("youtube.com");

    /*
    if (!isYoutube) {
        try {
            const isLink = new URL(text);
            // Its a link
            const eid = createNetworkedEntity(APP.world, "link", {
                url: isLink.text,
                recenter: true,
                resize: true,
                link: text
            });

            const avatarPov = document.querySelector("#avatar-pov-node").object3D;
            const obj = APP.world.eid2obj.get(eid);
            obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
            obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

            setTimeout(() => {
                window.APP.objectHelper.save(eid);
            }, 1000);
        } catch {}
    }

    if (isYoutube) {
        const eid = createNetworkedEntity(APP.world, "youtube", {
            src: youtube_model,
            recenter: true,
            resize: true,
            link: text
        });

        const avatarPov = document.querySelector("#avatar-pov-node").object3D;
        const obj = APP.world.eid2obj.get(eid);
        obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
        obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

        setTimeout(() => {
            window.APP.objectHelper.save(eid);
        }, 1000);
    } else {
        try {
            url = new URL(string);
            spawnFromUrl(url);
        } catch {
    */

    const textPayload = {
        value: text,
        fontSize: 0.2,
        maxWidth: 3,
        textAlign: "left",
        color: "#ffffff"
    };

    const eid = createNetworkedEntity(APP.world, "text", textPayload);

    const avatarPov = document.querySelector("#avatar-pov-node").object3D;
    const obj = APP.world.eid2obj.get(eid);
    obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
    obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

    setTimeout(() => {
        window.APP.objectHelper.save(obj.eid, null, "text", textPayload, "text");
    }, 1000);
}

function onDrop(e) {
    if (!AFRAME.scenes[0].is("entered")) {
        return;
    }

    if (!window.APP.objectHelper) {
        e.preventDefault();
        toast.error("We could not load the permissions for this room yet.");
        return;
    }

    if (!window.APP.objectHelper.can("can_create")) {
        e.preventDefault();
        toast.error("You do not have permission to upload content to this room.");
        return;
    }

    const files = e.dataTransfer?.files;
    if (files && files.length) {
        e.preventDefault();
        return toast.promise(spawnFromFileList(files), {
            loading: "Uploading...",
            success: "Uploaded",
            error: err => err.toString()
        });
    }
    const url = e.dataTransfer?.getData("url") || e.dataTransfer?.getData("text");
    if (url) {
        e.preventDefault();
        return toast.promise(spawnFromUrl(url), {
            loading: "Uploading...",
            success: "Uploaded",
            error: "An error occurred while uploading"
        });
    }
}

document.addEventListener("paste", onPaste);
document.addEventListener("drop", onDrop);
