import React, { useRef, useState, useEffect } from "react";

export function RootContainer(props) {
    const shouldShowDenied = window.APP.objectHelper?.can("can_create");

    return (
        <div className="roomlayout">
            <div id="dragoverContainer" className="dragover-area">
                {shouldShowDenied ? (
                    <>
                        <div className="row">
                            <i className="bi bi-cloud-upload-fill"></i>
                        </div>
                        <div className="row">
                            <p>Drop file to upload</p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="row">
                            <i className="bi bi-cloud-upload-fill"></i>
                        </div>
                        <div className="row">
                            <p>You do not have permission to upload content in this space.</p>
                        </div>
                    </>
                )}
            </div>

            {props.children}
        </div>
    );
}
