import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Spinner from "../assets/megaminds/images/Spinner.svg";
import MegamindsLogo from "../assets/megaminds/images/megaminds-logo.png";

export default function LoadingScreen(props) {
    return (
        <div className="position-absolute top-50 start-50 translate-middle">
            <Col
                className="d-flex flex-column justify-content-center text-center bg-white rounded"
                style={{ maxWidth: "150px" }}
            >
                <div className="p-2">
                    <img className="img-fluid" style={{ width: "150px" }} src={MegamindsLogo} alt="megaminds logo" />
                    <img className="text-center img-fluid" src={Spinner} alt="Spinner" />
                    {props.message}
                </div>
            </Col>
        </div>
    );
}
