import { Socket } from "phoenix";

export function getReticulumFetchUrl(path) {
    return process.env.REACT_APP_HUBS_ENDPOINT + "/" + path;
}

export async function connectToReticulum(debug = false, params = null, socketClass = Socket) {
    const socketSettings = {};

    if (debug) {
        socketSettings.logger = (kind, msg, data) => {
            console.log(`${kind}: ${msg}`, data);
        };
    }

    if (params) {
        socketSettings.params = params;
    }

    const socket = new socketClass(process.env.REACT_APP_PHX_SOCKET_ENDPOINT, socketSettings);

    socket.connect();
    socket.onError(async () => {
        // On error, underlying reticulum node may have died, so rebalance by
        // fetching a new healthy node to connect to.
    });

    return socket;
}

export function getPresenceEntryForSession(presences, sessionId) {
    const entry = Object.entries(presences || {}).find(([k]) => k === sessionId) || [];
    const presence = entry[1];
    return (presence && presence.metas && presence.metas[0]) || {};
}

export function getPresenceProfileForSession(presences, sessionId) {
    return (getPresenceEntryForSession(presences, sessionId) || {}).profile || {};
}

export function denoisePresence({ onJoin, onLeave, onChange }) {
    return {
        rawOnJoin: (key, beforeJoin, afterJoin) => {
            if (beforeJoin === undefined) {
                onJoin(key, afterJoin.metas[0]);
            }
        },
        rawOnLeave: (key, remaining, removed) => {
            if (remaining.metas.length === 0) {
                onLeave(key, removed.metas[0]);
            } else {
                onChange(key, removed.metas[removed.metas.length - 1], remaining.metas[remaining.metas.length - 1]);
            }
        }
    };
}

export function presenceEventsForHub(events) {
    const onJoin = (key, meta) => {
        events.trigger(`hub:join`, { key, meta });
    };
    const onLeave = (key, meta) => {
        events.trigger(`hub:leave`, { key, meta });
    };
    const onChange = (key, previous, current) => {
        events.trigger(`hub:change`, { key, previous, current });
    };
    return {
        onJoin,
        onLeave,
        onChange
    };
}
