import { addComponent, addEntity, defineQuery, enterQuery, exitQuery, hasComponent, removeComponent } from "bitecs";
import { HubsWorld } from "../../app";
import { Billboard } from "../../bit-components";

const billboardQuery = defineQuery([Billboard]);
const billboardEnterQuery = enterQuery(billboardQuery);
const worldPos = new THREE.Vector3();
export function billboardSystem(world: HubsWorld, scene: any) {
    billboardQuery(world).forEach(function (eid) {
        if (Billboard.toggled[eid] === 1) {
            const obj = world.eid2obj.get(eid);
            window.APP.scene?.camera.getWorldPosition(worldPos);
            if (obj) {
                obj.lookAt(worldPos);
            }
        }
    });
}
