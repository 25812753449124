import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import { defineQuery, removeEntity } from "bitecs";
import Button from "react-bootstrap/Button";
import { Tooltip } from "react-tooltip";
import { toast } from "react-hot-toast";
import useDashApi from "../../hooks/useDashApi";
import useTemplateApi from "../../hooks/useTemplateApi";

import { updateEnvironmentForHub } from "../../setupHubsNetworking";
import Files from "./Files";

import { loadStoredRoomData } from "../../../utils/load-room-objects";

import { FloatyObject } from "../../../bit-components";

function setupLobbyCamera() {
    const camera = document.getElementById("scene-preview-node");
    const previewCamera = document.getElementById("environment-scene").object3D.getObjectByName("scene-preview-camera");

    if (previewCamera) {
        camera.object3D.position.copy(previewCamera.position);
        camera.object3D.rotation.copy(previewCamera.rotation);
        camera.object3D.rotation.reorder("YXZ");
    } else {
        const cameraPos = camera.object3D.position;
        camera.object3D.position.set(cameraPos.x, 2.5, cameraPos.z);
    }

    camera.object3D.matrixNeedsUpdate = true;

    camera.removeAttribute("scene-preview-camera");
    camera.setAttribute("scene-preview-camera", "positionOnly: true; duration: 60");
}

const objectQuery = defineQuery([FloatyObject]);

export function InitialClassroomSetupFlow(props) {
    const [step, setStep] = useState("scene");
    const [isPosting, setIsPosting] = useState(false);

    const [selectedTemplate, setSelectedTemplate] = useState(undefined);

    const [tempText, setTempText] = useState("");
    const [searchText, setSearchText] = useState("");
    const [changingEnvironment, setChangingEnvironment] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const [scenes, scenesLoading, sceneError, sceneRefresh] = useDashApi("scenes");
    const [templates, templatesLoading, setTemplates, templatesRefresh] = useTemplateApi(
        searchText,
        searchText ? "" : "Create Flow"
    );

    const [selectedScene, setSelectedScene] = useState(scenes && scenes[0]);

    const resetObjects = () => {
        const objs = objectQuery(APP.world);
        objs.forEach(eid => {
            removeEntity(APP.world, eid);
        });
    };

    const swapScene = newScene => {
        setChangingEnvironment(true);
        const fader = document.getElementById("viewing-camera").components["fader"];
        fader.fadeOut().then(() => {
            document.querySelector(".a-canvas").classList.add("a-hidden");
            resetObjects();
            updateEnvironmentForHub(window.APP.hub, newScene.scene_glb);
            setSelectedScene(newScene);
            setSelectedTemplate(undefined);
            setTimeout(() => {
                fader.fadeIn().then(() => {
                    setChangingEnvironment(false);
                });
            }, 200);
        });
    };

    useEffect(() => {
        const environmentScene = document.querySelector("#environment-scene");
        const fader = document.getElementById("viewing-camera").components["fader"];

        environmentScene.addEventListener("environment-loaded", ({ detail: { model } }) => {
            setTimeout(() => {
                console.log("Done loading! Should fade back in");
                // fader.fadeIn();
                setChangingEnvironment(false);
            }, 400);
        });
    }, []);

    const changeTemplate = template => {
        setChangingEnvironment(true);
        console.log("Changing template...", template);
        const fader = document.getElementById("viewing-camera").components["fader"];
        fader.fadeOut().then(() => {
            resetObjects();
            updateEnvironmentForHub(window.APP.hub, template.source_classroom_clone.scene.scene_glb);
            setSelectedScene(null);
            setSelectedTemplate(template);
            APP.world.deletedNids.clear();
            APP.world.ignoredNids.clear();

            loadStoredRoomData(template.source_classroom_clone.hub_id).then(() => {
                setTimeout(() => {
                    console.log("Done loading! Should fade back in");
                    fader.fadeIn().then(() => {
                        setChangingEnvironment(false);
                    });
                }, 400);
            });
        });
    };

    useEffect(() => {
        if (!scenesLoading) {
            const officeWorld = scenes.find(scene => {
                return scene.title === "Office Hours";
            });

            console.log(officeWorld, scenes);
            setSelectedScene(officeWorld);
        }
    }, [scenesLoading, scenes]);

    const saveTemplateContent = () => {
        // Create new ids and room objects for each item in the template, cloneroom logic?
        // Trigger some kind of backend to save the objects, that way they can be edited

        const payload = {
            template_id: selectedTemplate.source_classroom_clone.id,
            new_id: window.APP.classroom.id
        };

        getAccessTokenSilently().then(token => {
            fetch(process.env.REACT_APP_MM_API_URL + "clone-objects", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            })
                .then(res => res.json())
                .then(res => {
                    console.log("Saved cloned objects..");
                    handleSubmit();
                })
                .catch(e => {
                    console.error(e);
                });
        });
    };

    const handleSubmit = () => {
        setIsPosting(true);
        console.log("Scene info", selectedTemplate, selectedScene);
        let payload = {
            title: window.APP.classroom.title,
            scene: selectedTemplate ? selectedTemplate.source_classroom_clone.scene.id : selectedScene.id,
            is_fresh: false
        };

        getAccessTokenSilently()
            .then(token => {
                fetch(process.env.REACT_APP_MM_API_URL + "classrooms/" + window.APP.classroom.hub_id + "/", {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        setIsPosting(false);
                        props.onEnterRoom();
                    })
                    .catch(error => {
                        toast.error("Something went wrong while saving.");
                        console.error(error);
                        setIsPosting(false);
                    });
            })
            .catch(error => {
                toast.error("Auth0 error");
                console.error(error);
                setIsPosting(false);
            });
    };

    if (step === "scene") {
        return (
            <>
                {changingEnvironment && (
                    <div className="position-absolute top-50 start-50 translate-middle-x text-white">
                        <div className="text-center d-flex justify-content-center align-items-center flex-column">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            <p className="mt-2">Loading Design...</p>
                        </div>
                    </div>
                )}
                <div className="position-absolute top-50 start-0 translate-middle-y" style={{ width: "346px" }}>
                    <Tooltip
                        id="hover-tooltip"
                        render={({ content, activeAnchor }) => (
                            <span>
                                {content}
                                <br />
                                {activeAnchor?.getAttribute("data-tooltip-desc") || "not set"}
                            </span>
                        )}
                    />
                    <Container className="d-flex justify-content-center p-0 m-0">
                        <Card className="ms-3">
                            <Card.Header>Select a Design</Card.Header>
                            <Card.Body className="clickable overflow-auto pb-0">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={tempText}
                                        onChange={e => setTempText(e.target.value)}
                                        placeholder="Search for Templates"
                                        onKeyDown={event => {
                                            if (event.key === "Enter") {
                                                setSearchText(tempText);
                                            }
                                        }}
                                    />
                                    <Button variant="secondary" onClick={() => setSearchText(tempText)}>
                                        <i className="bi bi-search"></i>
                                    </Button>
                                </InputGroup>
                            </Card.Body>
                            <Card.Body className="clickable overflow-auto pt-0 mb-0 pb-0" style={{ height: "70vh" }}>
                                {!searchText && (
                                    <>
                                        <p className="mb-0">Empty Designs</p>
                                        {!scenesLoading && scenes.length && !templatesLoading && (
                                            <Row className="">
                                                {scenes.map((scene, index) => (
                                                    <Col xs={6} className="mt-3" key={index}>
                                                        <Button
                                                            variant="dark"
                                                            disabled={changingEnvironment || selectedScene === scene}
                                                            className={
                                                                selectedScene === scene
                                                                    ? "d-flex justify-content-center align-items-center selector-card w-100 border border-3 border-success"
                                                                    : "d-flex justify-content-center align-items-center selector-card w-100 border border-3 border-secondary"
                                                            }
                                                            style={{
                                                                height: "80px",
                                                                background: scene.photo.startsWith("/")
                                                                    ? "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(http://localhost:8000" +
                                                                      scene.photo +
                                                                      ")"
                                                                    : "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(" +
                                                                      scene.photo +
                                                                      ")",
                                                                backgroundPosition: "center",
                                                                backgroundSize: "cover"
                                                            }}
                                                            onClick={() => {
                                                                if (selectedScene === scene) {
                                                                    // Pass on repeat click
                                                                } else {
                                                                    swapScene(scene);
                                                                }
                                                            }}
                                                        >
                                                            <p
                                                                className="mb-0 text-white text-center"
                                                                style={{
                                                                    textShadow: "#000 0.5px 0 8px"
                                                                }}
                                                            >
                                                                {scene.title}
                                                            </p>
                                                        </Button>
                                                    </Col>
                                                ))}
                                            </Row>
                                        )}
                                    </>
                                )}
                                <p className="mb-0 mt-3">Templates</p>
                                {!templatesLoading && (
                                    <Row className="">
                                        {templates.results.length === 0 && (
                                            <p className="fw-light">
                                                <small>No results</small>
                                            </p>
                                        )}
                                        {templates.results.length !== 0 &&
                                            templates.results.map((template, index) => (
                                                <Col xs={12} className="mt-3" key={index}>
                                                    <Button
                                                        disabled={changingEnvironment || selectedTemplate === template}
                                                        className={
                                                            selectedTemplate === template
                                                                ? "d-flex justify-content-center align-items-center w-100 border border-3 border-success selected-dim"
                                                                : "d-flex justify-content-center align-items-center w-100 border border-3 border-secondary"
                                                        }
                                                        style={{
                                                            height: "80px",
                                                            background: template.image.startsWith("/")
                                                                ? "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(http://localhost:8000" +
                                                                  template.image +
                                                                  ")"
                                                                : "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(" +
                                                                  template.image +
                                                                  ")",
                                                            backgroundPosition: "center",
                                                            backgroundSize: "cover"
                                                        }}
                                                        onClick={() => {
                                                            if (template === selectedTemplate) {
                                                                // Pass
                                                            } else {
                                                                changeTemplate(template);
                                                            }
                                                        }}
                                                    >
                                                        <p
                                                            className="mb-0 text-white"
                                                            style={{
                                                                textShadow: "#000 0.5px 0 8px"
                                                            }}
                                                        >
                                                            {template.title}
                                                        </p>
                                                    </Button>
                                                </Col>
                                            ))}
                                    </Row>
                                )}
                            </Card.Body>
                            <Card.Footer className="clickable">
                                <Row>
                                    <Col>
                                        {selectedScene && (
                                            <Button
                                                variant="success"
                                                className="w-100"
                                                disabled={isPosting}
                                                onClick={() => {
                                                    setStep("content");
                                                }}
                                            >
                                                Continue
                                            </Button>
                                        )}
                                        {selectedTemplate && (
                                            <Button
                                                variant="success"
                                                className="w-100"
                                                disabled={isPosting}
                                                onClick={() => {
                                                    saveTemplateContent();
                                                }}
                                            >
                                                Save & Enter
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </Container>
                </div>
            </>
        );
    }

    if (step === "content") {
        return <Files setStep={setStep} onContinue={handleSubmit} />;
    }

    return (
        <div className="position-absolute top-50 start-50 translate-middle ui">
            <Container className="d-flex justify-content-center p-0">
                <Card
                    style={{ maxWidth: "26rem", minWidth: "16rem", maxHeight: "70vh", overflow: "auto" }}
                    className="clickable"
                >
                    <Card.Body>
                        <Row>
                            <Col>Something went wrong</Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}
