import { toast } from "react-hot-toast";

export default class CommandHelper {
    constructor() {
        this.stack = [];
        this.redo = [];

        // console.log("Initialized commandHelper");
    }

    add(command) {
        this.redo.length = 0;
        this.stack.push(command);
    }

    undo() {
        // Get last item of array
        const item = this.stack.slice(-1)[0];
        console.log(this.stack, this.redo);

        if (!item) {
            toast.error("Can not go further back in Undo history.");
            return;
        }

        console.log("Undoing item", item);
        item.undo();

        this.stack.pop();
        this.redo.push(item);
    }

    redoCommand() {
        // Get last item of array
        const item = this.redo.slice(-1)[0];
        console.log(this.stack, this.redo);

        if (!item) {
            toast.error("Can not go further forward in redo history.");
            return;
        }

        console.log("Redoing item", item);
        item.redo();

        this.redo.pop();
        this.stack.push(item);
    }
}
