import React from "react";
import CloseButton from "react-bootstrap/CloseButton";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";

export default function AnchorPoint(props) {
    /*
     Available positions
        "top-start",
        "top-center",
        "top-end",
        "middle-start",
        "middle-center",
        "middle-end",
        "bottom-start",
        "bottom-center",
        "bottom-end",
    */

    return (
        <ToastContainer className={"anchor-" + props.position + " ms-4 me-4"} position={props.position}>
            <div
                style={{
                    maxHeight: "50vh",
                    overflow: "auto"
                }}
            >
                {props.content}
            </div>
        </ToastContainer>
    );
}
