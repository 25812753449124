import { defineQuery, enterQuery, Changed } from "bitecs";
import { Quizizz } from "../../../bit-components";

const quizizzQuery = defineQuery([Quizizz]);
const quizizzEntry = enterQuery(quizizzQuery);
const changedQuizizzQuery = defineQuery([Changed(Quizizz)]);

const worldPos = new THREE.Vector3();

export function quizizzSystem(world) {
    quizizzEntry(world).forEach(function (eid) {
        const object3d = APP.getString(Quizizz.obj3d[eid]);
        console.log("ADDED quizizz", object3d);
    });

    changedQuizizzQuery(world).forEach(function (eid) {
        console.log("Got change src on Quizizz");
        const object3d = APP.getString(Quizizz.obj3d[eid]);

        object3d.element.src = APP.getString(Quizizz.src[eid]);
    });

    quizizzQuery(world).forEach(function (eid) {
        const parent = world.eid2obj.get(eid);
        const quizizz = APP.getString(Quizizz.obj3d[eid]);
        const placeholder = APP.getString(Quizizz.placeholder[eid]);

        const avatarPovObject = document.getElementById("avatar-pov-node").object3D;
        avatarPovObject.getWorldPosition(worldPos);

        quizizz.element.style.width = (parent.scale.x * 16 * 28).toString() + "px";
        quizizz.element.style.height = (parent.scale.y * 14 * 28).toString() + "px";

        /*
        quizizz.position.set(parent.position.x * 1000, parent.position.y * 1000, parent.position.z * 1000);
        quizizz.rotation.set(parent.rotation.x, parent.rotation.y, parent.rotation.z);
        quizizz.scale.set(parent.scale.x / 1000, parent.scale.y / 1000, parent.scale.z / 1000);
        quizizz.updateMatrix();
        */


        const isNearby = worldPos.distanceTo(parent.position) < 0.7 + (parent.scale.x > 1 ? parent.scale.x * 0.4 : 1);

        if (!window.APP.editMode) {
            if (isNearby) {
                placeholder.visible = false;
                quizizz.element.style.pointerEvents = "all";
                quizizz.element.style.opacity = "1";
                quizizz.element.style.display = "block";
            } else {
                placeholder.visible = true;
                quizizz.element.style.pointerEvents = "none";
                quizizz.element.style.opacity = "0";
                quizizz.element.style.display = "none";
            }
        }
        if (window.APP.editMode) {
            if (isNearby) {
                placeholder.visible = true;
                quizizz.element.style.pointerEvents = "none";
                quizizz.element.style.opacity = "0.2";
            } else {
                placeholder.visible = true;
                quizizz.element.style.pointerEvents = "none";
                quizizz.element.style.opacity = "0";
            }
        }
    });
}

/*
 * o
        if (window.APP.editMode) {
            placeholder.visible = true;

            if (isNearby) {
                youtube.visible = true;
                youtube.element.style.pointerEvents = "none";
                youtube.element.style.opacity = "0.3";
            } else {
                youtube.visible = false;
                youtube.element.style.pointerEvents = "none";
                youtube.element.style.opacity = "0.3";
            }
        } else {

*/
