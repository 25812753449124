const commonKnownContentTypes = {
    gltf: "model/gltf",
    glb: "model/gltf-binary",
    png: "image/png",
    jpg: "image/jpeg",
    bmp: "image/bmp",
    svg: "image/svg+xml",
    zip: "model/gltf+zip",
    jpeg: "image/jpeg",
    gif: "image/gif",
    aac: "audio/aac",
    ogg: "audio/ogg",
    m4a: "audio/mp4",
    opus: "audio/opus",
    flac: "audio/flac",
    webm: "video/webm",
    wav: "audio/wav",
    pdf: "application/pdf",
    mp4: "video/mp4",
    mp3: "audio/mpeg",
    basis: "image/basis",
    ktx2: "image/ktx2",
    m3u8: "application/vnd.apple.mpegurl",
    mpd: "application/dash+xml"
};

export function getAbsoluteUrl(baseUrl, relativeUrl) {
    return new URL(relativeUrl, baseUrl);
}

export function getAbsoluteHref(baseUrl, relativeUrl) {
    return getAbsoluteUrl(baseUrl, relativeUrl).href;
}

// Note these files are configured in webpack.config.js to be handled with file-loader, so this will be a string containing the file paths
/*
import basisJsUrl from "three/examples/js/libs/basis/basis_transcoder.js";
import dracoWrapperJsUrl from "three/examples/js/libs/draco/gltf/draco_wasm_wrapper.js";
import basisWasmUrl from "three/examples/js/libs/basis/basis_transcoder.wasm";
import dracoWasmUrl from "three/examples/js/libs/draco/gltf/draco_decoder.wasm";
*/

export const rewriteBasisTranscoderUrls = function (url) {
    if (url === "basis_transcoder.js") {
        return basisJsUrl;
    } else if (url === "basis_transcoder.wasm") {
        return basisWasmUrl;
    }
    return url;
};

export const getCustomGLTFParserURLResolver = gltfUrl => url => {
    // Intercept loading of basis transcoder with content hashed urls
    /*
    if (url === "basis_transcoder.js") {
        return basisJsUrl;
    } else if (url === "basis_transcoder.wasm") {
        return basisWasmUrl;
    } else if (url === "draco_wasm_wrapper.js") {
        return dracoWrapperJsUrl;
    } else if (url === "draco_decoder.wasm") {
        return dracoWasmUrl;
    }
    */

    if (typeof url !== "string" || url === "") return "";
    if (/^(https?:)?\/\//i.test(url)) return url;
    if (/^data:.*,.*$/i.test(url)) return url;
    if (/^blob:.*$/i.test(url)) return url;

    return url;
};

const dataUrlRegex = /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/;

export const guessContentType = url => {
    if (!url) return;
    if (url.startsWith("hubs://") && url.endsWith("/camera")) return "video/vnd.hubs-webrtc";
    if (url.startsWith("hubs://") && url.endsWith("/screen")) return "video/vnd.hubs-webrtc";
    if (url.startsWith("data:")) {
        const matches = dataUrlRegex.exec(url);
        if (matches.length > 0) {
            matches[1];
        }
    }

    if (url.endsWith(".svg")) {
        return "image/svg+xml";
    }
    const extension = new URL(url, window.location).pathname.split(".").pop();
    return commonKnownContentTypes[extension];
};

const originIsHubsServer = new Map();
async function isHubsServer(url) {
    if (!url) return false;
    if (!url.startsWith("http")) {
        url = "https://" + url;
    }
    const { origin } = new URL(url);

    if (originIsHubsServer.has(origin)) {
        return originIsHubsServer.get(origin);
    }

    let isHubsServer;
    try {
        isHubsServer = (await fetch(origin, { method: "HEAD" })).headers.has("hub-name");
    } catch (e) {
        isHubsServer = false;
    }
    originIsHubsServer.set(origin, isHubsServer);
    return isHubsServer;
}

const hubsSceneRegex = /https?:\/\/[^/]+\/scenes\/[a-zA-Z0-9]{7}(?:\/|$)/;
const hubsAvatarRegex = /https?:\/\/[^/]+\/avatars\/(?<id>[a-zA-Z0-9]{7})(?:\/|$)/;
const hubsRoomRegex = /(https?:\/\/)?[^/]+\/(?<id>[a-zA-Z0-9]{7})(?:\/|$)/;

export const isLocalHubsUrl = async url =>
    (await isHubsServer(url)) && new URL(url).origin === document.location.origin;

export const isHubsSceneUrl = async url => (await isHubsServer(url)) && hubsSceneRegex.test(url);
export const isLocalHubsSceneUrl = async url => (await isHubsSceneUrl(url)) && (await isLocalHubsUrl(url));

export const isHubsAvatarUrl = async url => (await isHubsServer(url)) && hubsAvatarRegex.test(url);
export const isLocalHubsAvatarUrl = async url => (await isHubsAvatarUrl(url)) && (await isLocalHubsUrl(url));

export const isHubsRoomUrl = async url =>
    (await isHubsServer(url)) &&
    !(await isHubsAvatarUrl(url)) &&
    !(await isHubsSceneUrl(url)) &&
    url.match(hubsRoomRegex)?.groups.id;

export const isHubsDestinationUrl = async url =>
    (await isHubsServer(url)) && ((await isHubsSceneUrl(url)) || (await isHubsRoomUrl(url)));

export const idForAvatarUrl = url => {
    const match = url.match(hubsAvatarRegex);
    if (match) {
        return match.groups.id;
    }
    return null;
};
