import { CursorTargettingSystem } from "./cursor-targetting-system";
import { BoneVisibilitySystem } from "../components/bone-visibility";
import { AnimationMixerSystem } from "../components/animation-mixer";
import { UVScrollSystem } from "../components/uv-scroll";
import { PhysicsSystem } from "./physics-system";
import { SoundEffectsSystem } from "./sound-effects-system";
import { ScenePreviewCameraSystem } from "./scene-preview-camera-system";
import { CameraSystem } from "./camera-system";
import { CharacterControllerSystem } from "./character-controller-system";
import { waitForDOMContentLoaded } from "../utils/async-utils";
import { AudioSettingsSystem } from "./audio-settings-system";
import { AudioSystem } from "./audio-system";
import { AudioZonesSystem } from "./audio-zones-system";
import { GainSystem } from "./audio-gain-system";
import { EnvironmentSystem } from "./environment-system";
import { NameTagVisibilitySystem } from "./name-tag-visibility-system";

// new world
import { networkReceiveSystem } from "../bit/systems/network-receive-system";
import { networkSendSystem } from "../bit/systems/network-send-system";
import { onOwnershipLost } from "./on-ownership-lost";
import { interactionSystem } from "./bit-interaction-system";
import { floatyObjectSystem } from "./floaty-object-system";
import { removeNetworkedObjectButtonSystem } from "./remove-networked-object-button-system";
import { removeObject3DSystem } from "./remove-object3D-system";
import { networkedTransformSystem } from "./networked-transform";
import { buttonSystems } from "./single-action-button-system";
import { constraintsSystem } from "./bit-constraints-system";
import { mediaFramesSystem } from "./bit-media-frames";
import { videoSystem } from "../bit/systems/video-system";
import { audioSystem } from "../bit/systems/audio-system";
import { pdfSystem } from "../bit/systems/pdf-system";
import { questionSystem } from "../bit/systems/question-system";
import { youtubeSystem } from "../bit/systems/integrations/youtube-system";
import { peardeckSystem } from "../bit/systems/integrations/peardeck-system";
import { kahootSystem } from "../bit/systems/integrations/kahoot-system";
import { geogebraSystem } from "../bit/systems/integrations/geogebra-system";
import { quizizzSystem } from "../bit/systems/integrations/quizizz-system";
import { nearpodSystem } from "../bit/systems/integrations/nearpod-system";
import { gimkitSystem } from "../bit/systems/integrations/gimkit-system";
import { polypadSystem } from "../bit/systems/integrations/polypad-system";
import { spinningAnimationSystem } from "../bit/systems/spinning-animation-system";
import { billboardSystem } from "../bit/systems/billboard-system";
import { textSystem } from "../bit/systems/text-system";
import { fileReplacedSystem } from "../bit/systems/replace-file-system";
import { mediaLoadingSystem } from "../bit/systems/media-loading";
import { physicsCompatSystem } from "./bit-physics";
import { destroyAtExtremeDistanceSystem } from "./bit-destroy-at-extreme-distances";
import { videoMenuSystem } from "../bit/systems/video-menu-system";
import { deleteEntitySystem } from "../bit/systems/delete-entity-system";
import type { HubsSystems } from "aframe";
import { Camera, Scene, WebGLRenderer } from "three";
import { HubsWorld } from "../app";
import { sceneLoadingSystem } from "../bit/systems/scene-loading";

import { particleEmitterSystem } from "../bit/systems/particle-emitter-system";

declare global {
    interface Window {
        $S: HubsSystems;
    }
}

const timeSystem = (world: HubsWorld) => {
    const { time } = world;
    const now = performance.now();
    time.delta = now - time.elapsed;
    time.elapsed = now;
    time.tick++;
};

// NOTE keeping this around since many things index into it to get a reference to a system. This will
// naturally burn down as we migrate things, so it is not worth going through and changing all of them.
AFRAME.registerSystem("hubs-systems", {
    init() {
        waitForDOMContentLoaded().then(() => {
            this.DOMContentDidLoad = true;
        });
        this.cursorTargettingSystem = new CursorTargettingSystem();
        this.physicsSystem = new PhysicsSystem(this.el.object3D);
        this.audioSystem = new AudioSystem(this.el);
        this.soundEffectsSystem = new SoundEffectsSystem(this.el);
        this.scenePreviewCameraSystem = new ScenePreviewCameraSystem();
        this.cameraSystem = new CameraSystem(this.el.camera, this.el.renderer);
        this.characterController = new CharacterControllerSystem(this.el);
        this.audioSettingsSystem = new AudioSettingsSystem(this.el);
        this.animationMixerSystem = new AnimationMixerSystem();
        this.boneVisibilitySystem = new BoneVisibilitySystem();
        this.uvScrollSystem = new UVScrollSystem();
        this.audioZonesSystem = new AudioZonesSystem();
        this.gainSystem = new GainSystem();
        this.environmentSystem = new EnvironmentSystem(this.el);
        this.nameTagSystem = new NameTagVisibilitySystem(this.el);

        window.$S = this;
    },

    remove() {
        this.cursorTargettingSystem.remove();
    }
});

export function mainTick(xrFrame: XRFrame, renderer: WebGLRenderer, scene: Scene, camera: Camera) {
    const world = APP.world;
    const sceneEl = AFRAME.scenes[0];
    const aframeSystems = sceneEl.systems;
    const hubsSystems = aframeSystems["hubs-systems"];

    // TODO does anything actually ever pause the scene?
    if (!sceneEl.isPlaying && !sceneEl.hasLoaded && !hubsSystems.DOMContentDidLoad) return;

    timeSystem(world);
    const t = world.time.elapsed;
    const dt = world.time.delta;

    // Tick AFrame components
    const tickComponents = sceneEl.behaviors.tick;
    for (let i = 0; i < tickComponents.length; i++) {
        if (!tickComponents[i].el.isPlaying) continue;
        tickComponents[i].tick(t, dt);
    }

    // Run order of this loop, based on module load order
    // NOTE these could be inlined instead of looping but that would be a breakings change
    // to third part devs. This will also just naturally burndown as we migrate.
    // aframeSystems["networked"].tick(t, dt);
    // aframeSystems["local-audio-analyser"].tick(t, dt);
    // aframeSystems["frame-scheduler"].tick(t, dt);
    // aframeSystems["personal-space-bubble"].tick(t, dt);
    // aframeSystems["userinput-debug"].tick(t, dt);
    

    const systemNames = sceneEl.systemNames;
    for (let i = 0; i < systemNames.length; i++) {
        if (!aframeSystems[systemNames[i]].tick) continue;
        aframeSystems[systemNames[i]].tick(t, dt);
    }

    networkReceiveSystem(world);
    onOwnershipLost(world);
    sceneLoadingSystem(world, hubsSystems.environmentSystem, hubsSystems.characterController);
    mediaLoadingSystem(world);

    physicsCompatSystem(world);

    networkedTransformSystem(world);

    interactionSystem(world, hubsSystems.cursorTargettingSystem, t, aframeSystems);

    buttonSystems(world);
    constraintsSystem(world, hubsSystems.physicsSystem);

    // We run this earlier in the frame so things have a chance to override properties run by animations
    hubsSystems.animationMixerSystem.tick(dt);

    hubsSystems.characterController.tick(t, dt);

    floatyObjectSystem(world);

    hubsSystems.soundEffectsSystem.tick();
    hubsSystems.scenePreviewCameraSystem.tick();
    hubsSystems.physicsSystem.tick(dt);
    hubsSystems.cameraSystem.tick(hubsSystems.el, dt);
    hubsSystems.uvScrollSystem.tick(dt);
    videoMenuSystem(world, aframeSystems.userinput);
    videoSystem(world, hubsSystems.audioSystem);
    audioSystem(world, hubsSystems.audioSystem);
    pdfSystem(world);
    questionSystem(world);
    peardeckSystem(world);
    nearpodSystem(world);
    // gimkitSystem(world);
    polypadSystem(world);
    quizizzSystem(world);
    billboardSystem(world, APP.scene);
    textSystem(world);
    fileReplacedSystem(world);
    particleEmitterSystem(world, dt);
    spinningAnimationSystem(world);
    mediaFramesSystem(world);
    hubsSystems.audioZonesSystem.tick(hubsSystems.el);
    hubsSystems.gainSystem.tick();
    hubsSystems.nameTagSystem.tick();

    deleteEntitySystem(world, aframeSystems.userinput);
    // destroyAtExtremeDistanceSystem(world);
    removeNetworkedObjectButtonSystem(world);
    removeObject3DSystem(world);

    // We run this late in the frame so that its the last thing to have an opinion about the scale of an object
    hubsSystems.boneVisibilitySystem.tick();

    networkSendSystem(world);

    scene.updateMatrixWorld();

    renderer.info.reset();

    renderer.render(scene, camera);

    kahootSystem(world);
    geogebraSystem(world);
    youtubeSystem(world);

    aframeSystems.userinput.tick2(xrFrame);

    APP.cssRenderer.render(scene, camera);

    // tock()s on components and system will fire here. (As well as any other time render() is called without unbinding onAfterRender)
    // TODO inline invoking tocks instead of using onAfterRender registered in a-scene
}
