import { addComponent } from "bitecs";
import { Polypad } from "../bit-components";
import { addObject3DComponent } from "../utils/jsx-entity";
import { createPolypadObject } from "../utils/css2d/create-polypad-object";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { polypadTexture } from "../utils/textures/polypad-texture";

export function inflatePolypad(world, eid, params) {
    const questionContainer = createPolypadObject(params.link);

    const geometry = new THREE.BoxGeometry(0.398 * (16 / 9), 0.47, 0.02);
    const material = new THREE.MeshBasicMaterial({ map: polypadTexture, color: 0xffffff });

    // This is a helper cube when css3dobject is out of range, or to move it around in Edit Mode.
    console.log("Polypad inflating");
    const cube = new THREE.Mesh(geometry, material);

    cube.visible = false;

    const parentObj = new THREE.Object3D();

    parentObj.add(cube);
    parentObj.position.setZ(-0.02);

    const obj3d = new CSS2DObject(questionContainer);
    parentObj.add(obj3d);

    addComponent(world, Polypad, eid);

    Polypad.src[eid] = APP.getSid(obj3d.element.src);
    Polypad.obj3d[eid] = APP.getSid(obj3d);
    Polypad.placeholder[eid] = APP.getSid(cube);

    addObject3DComponent(world, eid, parentObj);

    console.log("Polypad done inflating");
    return eid;
}
