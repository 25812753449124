import { addComponent } from "bitecs";
import { YouTube } from "../bit-components";
import { addObject3DComponent } from "../utils/jsx-entity";
import { createYoutubeObject } from "../utils/css2d/create-youtube-object";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { CSS3DObject } from "three/examples/jsm/renderers/CSS3DRenderer";
import { youtubeTexture } from "../utils/textures/youtube-texture";

export function inflateYouTube(world, eid, params) {
    console.log("Inflating YouTube - ", params.link);
    addComponent(world, YouTube, eid);

    const youtubeIframe = createYoutubeObject(params.link);

    const geometry = new THREE.BoxGeometry(0.398 * (16 / 9), 0.47, 0.02);
    const material = new THREE.MeshBasicMaterial({ map: youtubeTexture, color: 0xffffff });

    const cube = new THREE.Mesh(geometry, material);
    cube.visible = false;

    const parentObj = new THREE.Object3D();
    parentObj.add(cube);

    const obj3d = new CSS2DObject(youtubeIframe);
    parentObj.add(obj3d);

    YouTube.src[eid] = APP.getSid(params.link);
    YouTube.obj3d[eid] = APP.getSid(obj3d);
    YouTube.placeholder[eid] = APP.getSid(cube);

    addObject3DComponent(world, eid, parentObj);

    return eid;
}
