export function createGimkitObject(link) {
    var iframe = document.createElement("iframe");

    iframe.style.width = "720px";
    iframe.style.height = "480px";

    iframe.style.border = "0px";
    iframe.style.scale = "75%";
    iframe.style.pointerEvents = "all";
    iframe.src = link;

    return iframe;
}
