import React from "react";
import Accordion from "react-bootstrap/Accordion";

export default function InstructionsMenu() {
    if (!window.APP.classroom.assignment_parent?.instructions) {
        return null;
    }

    return (
        <div className="position-fixed clickable ui" style={{ top: "24px", right: "24px", maxWidth: "25%" }}>
            <Accordion defaultActiveKey="0" className="reset-style-accordion">
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="">
                        <i className="bi bi-lightbulb-fill text-purple-dark me-2"></i>Teacher's Instructions
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <p className="mb-0" style={{ whiteSpace: "break-spaces" }}>
                                {window.APP.classroom.assignment_parent?.instructions}
                            </p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}
