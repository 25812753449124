import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function RoomSettingsMenu(props) {
    const defaultPermissions = window.APP.objectHelper.permissions;

    const [create, setCreate] = useState(defaultPermissions.can_create);
    const [draw, setDraw] = useState(defaultPermissions.can_draw);
    const [change, setChange] = useState(defaultPermissions.can_change);
    const [voice, setVoice] = useState(defaultPermissions.can_share_microphone);
    const [react, setReact] = useState(defaultPermissions.can_share_microphone);
    const [chat, setChat] = useState(defaultPermissions.can_chat);
    const [hand, setHand] = useState(defaultPermissions.can_raise_hand);
    const [stickynotes, setStickynotes] = useState(defaultPermissions.can_spawn_stickynotes);

    const [studentMenu, setStudentmenu] = useState(defaultPermissions.can_see_student_menu);

    const [video, setVideo] = useState(defaultPermissions.can_share_video);

    const applyChanges = () => {
        let payload = {
            can_change: change,
            can_create: create,
            can_chat: chat,
            can_react: react,
            can_raise_hand: hand,
            can_draw: draw,
            can_share_microphone: voice,
            can_see_student_menu: studentMenu,
            can_share_video: video,
            can_spawn_stickynotes: stickynotes
        };

        if (payload.can_share_microphone) {
            window.APP.hubChannel.sendUnMuteRequest();
        } else {
            window.APP.hubChannel.sendMuteRequest();
        }

        window.APP.objectHelper.update_permissions(payload);
    };

    return (
        <div className="p-3">
            <p className="fw-light mt-2 mb-2">Communication</p>
            <Form>
                <Form.Check
                    type="switch"
                    id="chat"
                    label="Enable Chat"
                    onChange={() => setChat(!chat)}
                    checked={chat}
                />
                <Form.Check
                    type="switch"
                    id="reactions"
                    label="Enable Reactions"
                    onChange={() => setReact(!react)}
                    checked={react}
                />
                <Form.Check
                    type="switch"
                    id="hand"
                    label="Enable Hand Raising"
                    onChange={() => setHand(!hand)}
                    checked={hand}
                />
                <Form.Check
                    type="switch"
                    id="voice"
                    label="Enable Microphone"
                    checked={voice}
                    onChange={() => setVoice(!voice)}
                />
                <Form.Check
                    type="switch"
                    id="share"
                    label="Enable Webcam and Screensharing"
                    checked={video}
                    onChange={() => setVideo(!video)}
                />
            </Form>
            <p className="fw-light mt-3 mb-3">Editing</p>
            <Form>
                <Form.Check
                    type="switch"
                    id="create"
                    label="Visitors can Add Content and use Edit Mode"
                    checked={create}
                    onChange={() => setCreate(!create)}
                />
                <span className="text-muted mt-2">
                    This will allow all visitors to upload and move objects
                </span>
                {/*
                <Form.Check
                    type="switch"
                    id="draw"
                    label="Students can draw with the pen tool"
                    checked={draw}
                    onChange={() => setDraw(!draw)}
                />
                */}
            </Form>
            <Button onClick={() => applyChanges()} variant="success">
                Save Changes
            </Button>
        </div>
    );
}
