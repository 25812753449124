import React, { useEffect, useState } from "react";
import ExtendedModal from "../../menus/modals/ExtendedModal";

import ObjectDetailMenu from "../../menus/right/ObjectDetailMenu";

export default function RightPreviewMenu() {
    const scene = window.APP.scene;
    const [object, setObject] = useState(null);

    useEffect(() => {
        scene.addEventListener("right_menu_changed", el => {
            if (el.detail === null) {
                setObject(null);
                if (window.APP.scene.systems["hubs-systems"]["cameraSystem"].helper) {
                    window.APP.scene.systems["hubs-systems"]["cameraSystem"].helper.visible = false;
                }
            } else {
                if (el.detail === object) {
                    setObject(null);
                } else {
                    setObject(el.detail);
                }
            }
        });
    }, []);

    if (!object) {
        return null;
    }

    let obj = window.APP.world.eid2obj.get(object);

    if (obj.parent?.name === "Interactable Media") {
        obj = obj.parent;
    } else {
        obj = obj;
    }

    return (
        <ExtendedModal
            position="middle-end"
            title={"Object Details"}
            closePopover={() => {
                window.APP.scene.systems["hubs-systems"].cameraSystem.uninspect();
                window.APP.scene.systems["hubs-systems"].cameraSystem.isInsideMenu = null;
                if (window.APP.scene.systems["hubs-systems"]["cameraSystem"].helper) {
                    window.APP.scene.systems["hubs-systems"]["cameraSystem"].helper.visible = false;
                }
                window.APP.scene.emit("right_menu_changed", null);
            }}
            content={
                <ObjectDetailMenu
                    isPreview={true}
                    closePopover={() => {
                        window.APP.scene.systems["hubs-systems"].cameraSystem.uninspect();
                        window.APP.scene.systems["hubs-systems"].cameraSystem.isInsideMenu = null;
                        if (window.APP.scene.systems["hubs-systems"]["cameraSystem"].helper) {
                            window.APP.scene.systems["hubs-systems"]["cameraSystem"].helper.visible = false;
                        }
                        window.APP.scene.emit("right_menu_changed", null);
                    }}
                    object={obj}
                    setObject={setObject}
                    eid={object}
                />
            }
        />
    );
}
