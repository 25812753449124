import React, { useState, useEffect } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Toaster } from "react-hot-toast";

import { MobileWarningModal } from "./classroom/entry/MobileWarningModal";
import PreviewMenus from "./preview/PreviewMenus";

import { RootContainer } from "./misc/RoomLayout";
import { Tooltip } from "react-tooltip";

import ObjectHelper from "../utils/ObjectHelper";

function PreviewInterface(props) {
    const [showMobileWarning, setShowMobileWarning] = useState(AFRAME.utils.device.isMobile());

    const enterPreview = async () => {
        // console.log("Should enter scene!");
        await props.entryManager.enterScene(true);
        setShowMobileWarning(false);
    };

    useEffect(() => {
        if (!showMobileWarning) {
            enterPreview();
            setShowMobileWarning(false);
        }
    }, []);

    if (!showMobileWarning) return <PreviewMenus />;

    return (
        <RootContainer scene={props.scene} store={APP.store}>
            <Toaster />
            <Tooltip id="tooltip-container" />
            <MobileWarningModal onFinished={enterPreview} />
        </RootContainer>
    );
}

export default PreviewInterface;
