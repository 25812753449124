import * as bitecs from "bitecs";
import { CSS3DRenderer, CSS3DObject } from "three/examples/jsm/renderers/CSS3DRenderer";
import { CSS2DRenderer, CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { addEntity, createWorld, IWorld } from "bitecs";
import "./utils/aframe-to-bit-components";
import { AEntity, Networked, Object3DTag, Owned } from "./bit-components";
import Store from "./storage/store";

import type { AElement, AScene } from "aframe";
import HubChannel from "./utils/hub-channel";

import {
    Audio,
    AudioListener,
    Object3D,
    PerspectiveCamera,
    PositionalAudio,
    Scene,
    Material,
    sRGBEncoding,
    WebGLRenderer
} from "three";

import { AudioSettings, SourceType } from "./components/audio-params";
import { DialogAdapter } from "./livekit-adapter";
import { mainTick } from "./systems/hubs-systems";
import { waitForPreloads } from "./utils/preload";
import SceneEntryManager from "./scene-entry-manager";
import { store } from "./utils/store-instance";

declare global {
    interface Window {
        $O: (eid: number) => Object3D | undefined;
        APP: App;
    }
    const APP: App;
}

export interface HubsWorld extends IWorld {
    scene: Scene;
    nameToComponent: {
        object3d: typeof Object3DTag;
        networked: typeof Networked;
        owned: typeof Owned;
        AEntity: typeof AEntity;
    };
    ignoredNids: Set<number>;
    deletedNids: Set<number>;
    nid2eid: Map<number, number>;
    eid2obj: Map<number, Object3D>;
    eid2mat: Map<number, Material>;
    time: { delta: number; elapsed: number; tick: number };
}

let resolvePromiseToScene: (value: Scene) => void;
const promiseToScene: Promise<Scene> = new Promise(resolve => {
    resolvePromiseToScene = resolve;
});
export function getScene() {
    return promiseToScene;
}

interface HubDescription {
    hub_id: string;
}

export class App {
    scene?: AScene;
    hubChannel?: HubChannel;
    hub?: HubDescription;
    endpoint?: string;
    spawnpoints?: any;
    entryManager?: SceneEntryManager;
    messageDispatch?: any;
    store: Store;
    renderer: any;
    cssRenderer: any;
    raycaster: any;
    camera: any;

    audios = new Map<AElement | number, PositionalAudio | Audio>();
    sourceType = new Map<AElement | number, SourceType>();
    audioOverrides = new Map<AElement | number, AudioSettings>();
    zoneOverrides = new Map<AElement | number, AudioSettings>();
    gainMultipliers = new Map<AElement | number, number>();
    supplementaryAttenuation = new Map<AElement | number, number>();
    clippingState = new Set<AElement | number>();
    mutedState = new Set<AElement | number>();
    isAudioPaused = new Set<AElement | number>();
    audioDebugPanelOverrides = new Map<SourceType, AudioSettings>();
    sceneAudioDefaults = new Map<SourceType, AudioSettings>();
    moderatorAudioSource = new Set<AElement | number>();

    world: HubsWorld = createWorld();

    str2sid: Map<string | null, number>;
    sid2str: Map<number, string | null>;
    nextSid = 1;

    audioListener: AudioListener;

    dialog = new DialogAdapter();

    RENDER_ORDER = {
        HUD_BACKGROUND: 1,
        HUD_ICONS: 2,
        CURSOR: 3
    };

    constructor() {
        this.store = store;
        // TODO: Create accessor / update methods for these maps / set
        this.world.eid2obj = new Map();
        this.world.eid2mat = new Map();

        this.world.nid2eid = new Map();
        this.world.deletedNids = new Set();
        this.world.ignoredNids = new Set();

        // used in aframe and networked aframe to avoid imports
        this.world.nameToComponent = {
            object3d: Object3DTag,
            networked: Networked,
            owned: Owned,
            AEntity
        };

        // reserve entity 0 to avoid needing to check for undefined everywhere eid is checked for existance
        addEntity(this.world);

        this.str2sid = new Map([[null, 0]]);
        this.sid2str = new Map([[0, null]]);

        window.$O = eid => this.world.eid2obj.get(eid);
    }

    // TODO nothing ever cleans these up
    getSid(str: string) {
        if (!this.str2sid.has(str)) {
            const sid = this.nextSid;
            this.nextSid = this.nextSid + 1;
            this.str2sid.set(str, sid);
            this.sid2str.set(sid, str);
            return sid;
        }
        return this.str2sid.get(str)!;
    }

    getString(sid: number) {
        return this.sid2str.get(sid);
    }

    // This gets called by a-scene to setup the renderer, camera, and audio listener
    // TODO ideally the contorl flow here would be inverted, and we would setup this stuff,
    // initialize aframe, and then run our own RAF loop
    setupRenderer(sceneEl: AScene) {
        const canvas = document.createElement("canvas");
        canvas.classList.add("a-canvas");
        canvas.dataset.aframeCanvas = "true";
        canvas.style.width = window.innerWidth + "px";
        canvas.style.height = window.innerHeight + "px";
        sceneEl.style.width = window.innerWidth + "px";
        sceneEl.style.height = window.innerHeight + "px";

        const cssRenderer = new CSS2DRenderer();
        cssRenderer.setSize(window.innerWidth, window.innerHeight);

        cssRenderer.domElement.style.position = "absolute";
        cssRenderer.domElement.style.pointerEvents = "none";
        cssRenderer.domElement.style.top = "0";
        cssRenderer.domElement.style.zIndex = "3";

        // document.getElementById("CSS3DRenderer")?.appendChild(this.cssRenderer.domElement);

        this.raycaster = new THREE.Raycaster();

        const renderer = new WebGLRenderer({
            antialias: false,
            depth: true,
            stencil: true,
            canvas
        });

        // We manually handle resetting this in mainTick so that stats are correctly reported with post effects enabled
        renderer.info.autoReset = false;

        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setClearColor(0x000000, 0);
        renderer.setSize(window.innerWidth, window.innerHeight);

        // renderer.debug.checkShaderErrors = qsTruthy("checkShaderErrors");

        // These get overridden by environment-system but setting to the highly expected defaults to avoid any extra work

        renderer.physicallyCorrectLights = false;
        renderer.outputEncoding = sRGBEncoding;

        this.entryManager = new SceneEntryManager();

        this.renderer = renderer;
        this.cssRenderer = cssRenderer;

        sceneEl.appendChild(renderer.domElement);
        sceneEl.appendChild(cssRenderer.domElement);

        const camera = new PerspectiveCamera(80, window.innerWidth / window.innerHeight, 0.05, 10000);
        camera.aspect = canvas.width / canvas.height;
        camera.updateProjectionMatrix();

        this.camera = camera;

        const setScreenResolution = () => {
            canvas.style.width = window.innerWidth + "px";
            canvas.style.height = window.innerHeight + "px";

            sceneEl.style.width = window.innerWidth + "px";
            sceneEl.style.height = window.innerHeight + "px";

            cssRenderer.setSize(window.innerWidth, window.innerHeight);
            renderer.setSize(window.innerWidth, window.innerHeight);

            sceneEl.camera.aspect = canvas.width / canvas.height;
            sceneEl.camera.updateProjectionMatrix();
        };

        window.addEventListener("resize", event => {
            setScreenResolution();
        });

        const audioListener = new AudioListener();
        this.audioListener = audioListener;
        camera.add(audioListener);

        this.world.time = {
            delta: 0,
            elapsed: 0,
            tick: 0
        };

        this.world.scene = sceneEl.object3D;
        const scene = sceneEl.object3D;
        resolvePromiseToScene(scene);

        // We manually call scene.updateMatrixWolrd in mainTick
        scene.autoUpdate = false;

        // This gets called after all system and component init functions
        sceneEl.addEventListener("loaded", () => {
            waitForPreloads().then(() => {
                this.world.time.elapsed = performance.now();
                renderer.setAnimationLoop(function (_rafTime, xrFrame) {
                    mainTick(xrFrame, renderer, scene, camera);
                    // cssRenderer.render(scene, camera);
                });
                sceneEl.renderStarted = true;
            });
        });

        return {
            renderer,
            camera,
            audioListener
        };
    }
}
