import React, { useState, useEffect } from "react";

import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { AvatarPicker } from "./AvatarPicker";

import useDashApi from "../hooks/useDashApi";

export function ProfileStep(props) {
    const [displayName, setDisplayName] = useState("");

    const [results, avatarsLoading, error, refresh] = useDashApi("avatars");

    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        if (results) {
            setAvatar(results[0]);
        }
    }, [results]);

    const handleSaveAndContinue = () => {
        if (props.fromSettingsMenu || props.fromUserList) {
            props.handleSave(avatar, displayName);
        } else {
            window.APP.store.update({
                profile: { avatarId: avatar.id, displayName: displayName }
            });
            window.location = props.destination;
        }
    };

    if (error) {
        console.log(error);
        return <p>Error in the avatar selector: {error.message}</p>;
    }

    if (!results || avatarsLoading || error || !avatar) {
        return <p>Loading avatar selector</p>;
    }

    return (
        <div className={!props.fromSettingsMenu || !props.fromUserList ? "purple-double-gradient h-100" : ""}>
            <div>
                <Row>
                    <Col xs={12}>
                        <Modal show scrollable fullscreen="md-down" backdrop={false} centered>
                            {!props.fromUserList && !props.fromSettingsMenu && (
                                <Modal.Header>
                                    <Row>
                                        <Col xs={12} className="">
                                            <p className="mb-0 fw-bold">Avatar Selection</p>
                                            <small className="mb-0 fw-light">
                                                The avatar will represent you inside a 3D space. You can change your avatar
                                                and name later.
                                            </small>
                                        </Col>
                                    </Row>
                                </Modal.Header>
                            )}
                            <Modal.Body className="h-100 p-3">
                                <Row className="">
                                    <Col xs={12} className="">
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="formDisplayName">
                                                    <Form.Label>Display Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={displayName}
                                                        maxLength={60}
                                                        minLength={3}
                                                        required
                                                        onChange={e => setDisplayName(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <p>Avatar Selection</p>
                                                <AvatarPicker results={results} avatar={avatar} setAvatar={setAvatar} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Row className="">
                                    <Col xs={12}>
                                        {props.fromUserList && props.handleBack && (
                                            <Button
                                                variant="secondary"
                                                className="me-2"
                                                onClick={() => props.handleBack()}
                                            >
                                                Back
                                            </Button>
                                        )}
                                        {props.fromSettingsMenu && props.handleClose && (
                                            <Button
                                                variant="secondary"
                                                className="me-2"
                                                onClick={() => props.handleClose()}
                                            >
                                                Back
                                            </Button>
                                        )}
                                        {displayName.length >= 3 ? (
                                            <Button variant="success" onClick={() => handleSaveAndContinue()}>
                                                {props.fromUserList || props.fromSettings
                                                    ? "Save"
                                                    : "Save and Continue"}
                                            </Button>
                                        ) : (
                                            <Button variant="success" disabled>
                                                Please enter a name
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
