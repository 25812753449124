import { addComponent } from "bitecs";
import { Kahoot } from "../bit-components";
import { addObject3DComponent } from "../utils/jsx-entity";
import { createKahootObject } from "../utils/css2d/create-kahoot-object";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { kahootTexture } from "../utils/textures/kahoot-texture";

export function inflateKahoot(world, eid, params) {
    const questionContainer = createKahootObject(params.link);

    const geometry = new THREE.BoxGeometry(0.398 * (16 / 9), 0.47, 0.02);
    const material = new THREE.MeshBasicMaterial({ map: kahootTexture, color: 0xffffff });

    console.log("Kahoot inflating");

    const cube = new THREE.Mesh(geometry, material);
    cube.visible = false;

    const parentObj = new THREE.Object3D();
    parentObj.add(cube);

    const obj3d = new CSS2DObject(questionContainer);
    parentObj.add(obj3d);
    // window.APP.scene.object3D.add(obj3d);

    addComponent(world, Kahoot, eid);

    Kahoot.src[eid] = APP.getSid(obj3d.element.src);
    Kahoot.obj3d[eid] = APP.getSid(obj3d);
    Kahoot.placeholder[eid] = APP.getSid(cube);

    addObject3DComponent(world, eid, parentObj);

    console.log("Kahoot done inflating");
    return eid;
}
