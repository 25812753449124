import { toast } from "react-hot-toast";

export const setupWebsocketListeners = (socket, token, disconnect) => {
    socket.addEventListener("close", e => {
        console.log(e.code);
        if (e.code === 1000) {
            console.log("You got kicked");
        } else if (e.code === 1006) {
            // Most likely locked.
            console.error("Tried to connect to a locked room!");
            // toast.error("Lost connection to servers. Reconnecting in 5 seconds...");
            disconnect();
        } else {
            console.log("WebSocket:Close", e);
            // toast.error("Lost connection to servers. Reconnecting in 5 seconds...");
            disconnect();
        }
    });

    socket.addEventListener("error", err => {
        console.log("WebSocket:Error", err);
        console.error("Socket encountered error: ", err.message, "Closing socket");
        socket.close();
    });

    socket.addEventListener("message", event => {
        const json_data = JSON.parse(event.data);

        if (json_data.message == "permissions_updated") {
            // An admin updated the permissions of the room, fetch new ones as if the user was entering the room.
            toast.success("Room permissions updated.");
            window.APP.objectHelper.get_permissions();
        }
        if (json_data.message == "permissions_requested") {
            // Server sends permission payload back to hubs
            window.APP.objectHelper.set_permissions(json_data.permissions);
        }

        if (json_data.type == "kick_order") {
            // Server sends permission payload back to hubs
            console.log("Got a kick order for session", json_data);
            if (json_data.session_id.user === NAF.clientId) {
                window.APP.userHelper.handleKicked();
            }
        }

        if (json_data.type == "system" && json_data.error && json_data.message) {
            toast.error(json_data.message);
        }

        if (json_data.type == "openai") {
            window.APP.scene.emit("ai_message_receive", json_data);
        }

        if (json_data.type == "chat_message") {
            window.APP.chatHelper.receive_message(json_data.sender, json_data.message, json_data.authenticated);
        }
    });
};
