export function createKahootObject(link) {
    var iframe = document.createElement("iframe");

    iframe.style.border = "0px";
    iframe.style.pointerEvents = "auto";

    iframe.src = "https://kahoot.it?pin=" + link;

    console.log("Created kahoot object", link);

    return iframe;
}
