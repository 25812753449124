import React, { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Linkify from "linkify-react";

export function ChatBar(props) {
    const [text, setText] = useState("");
    const [showTooltip, setShowTooltip] = useState(text);

    const handleSubmit = event => {
        if (event.key === "Enter") {
            window.APP.chatHelper.send_message(text);
            setText("");
        }
    };

    useEffect(() => {
        if (text !== "") {
            setShowTooltip(true);
        } else {
            setShowTooltip(false);
        }
    }, [text]);

    return (
        <>
            <span
                data-tooltip-id="tooltip-container"
                data-tooltip-content="Click to show full chat history"
                onClick={e => props.setShowFullChat(!props.showFullChat)}
                className="text-muted d-flex"
            >
                {props.showFullChat ? (
                    <i className="bi bi-caret-down-fill d-flex align-items-center" />
                ) : (
                    <i className="bi bi-caret-up-fill d-flex align-items-center" />
                )}
            </span>
            <div className="input-group ms-2" style={{ width: "215px" }}>
                <OverlayTrigger
                    show={!!text}
                    placement={"top"}
                    overlay={<Tooltip>Press the Enter Key to send</Tooltip>}
                >
                    <input
                        type="text"
                        className="form-control bg-transparent chat-box border-0"
                        id="floatingInputGroup1"
                        maxLength="300"
                        placeholder="Message"
                        value={text}
                        onChange={e => setText(e.target.value)}
                        onKeyDown={e => handleSubmit(e)}
                    />
                </OverlayTrigger>
            </div>
        </>
    );
}

export function ChatSystem(props) {
    if (!window.APP.objectHelper.can("can_chat")) {
        return null;
    }
    const [messages, setMessages] = useState([]);
    const [showFullChat, setShowFullChat] = useState(false);
    const [showExpandButton, setShowExpandButton] = useState(false);

    useEffect(() => {
        window.APP.chatHelper.addEventListener("receive_message", event => {
            setMessages(oldMessages => [...oldMessages, event.detail]);
        });
    }, []);

    return (
        <>
            <ChatBar showFullChat={showFullChat} setShowFullChat={setShowFullChat} />
            {showFullChat ? (
                <Row
                    className="position-fixed ms-2 hide-scrollbar bg-semitransparent rounded"
                    style={{
                        left: "4px",
                        bottom: "80px"
                    }}
                >
                    <Col className="m-0 p-0">
                        <table
                            style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                                alignItems: "end",
                                height: "300px",
                                maxHeight: "300px",
                                width: "fit-content",
                                overflowY: "auto"
                            }}
                            className="scroll-target custom-scrollbar scrollbar-left"
                        >
                            <tbody
                                style={{
                                    width: "30vw",
                                    maxWidth: "40vw"
                                }}
                                className="text-rtl-reset ms-2"
                            >
                                {messages.map(message => (
                                    <tr key={message.key} className="d-flex justify-content-middle">
                                        <td className="d-flex text-start ignore-cursor">
                                            <span
                                                style={{ maxWidth: "130px" }}
                                                className="badge bg-semitransparent text-truncate d-flex align-items-center"
                                            >
                                                {message.sender}
                                            </span>
                                        </td>
                                        <td className="text-white ignore-cursor">
                                            <p className="fw-bold mb-0">
                                                <Linkify
                                                    options={{
                                                        target: "_blank",
                                                        rel: "noopener referrer",
                                                        className: "clickable text-purple-dark"
                                                    }}
                                                >
                                                    {message.message}
                                                </Linkify>
                                            </p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            ) : (
                <Row
                    onMouseEnter={() => setShowExpandButton(true)}
                    onMouseLeave={() => setShowExpandButton(false)}
                    className="position-fixed ms-2 bg-transparent rounded ignore-cursor"
                    style={{
                        left: 0,
                        bottom: "92px"
                    }}
                >
                    <Col className="m-0 p-0">
                        {showExpandButton && (
                            <OverlayTrigger placement={"top"} overlay={<Tooltip>Click to expand the chat log</Tooltip>}>
                                <span
                                    onClick={e => props.setShowFullChat(!props.showFullChat)}
                                    className="text bg-transparent text-dark p-2"
                                >
                                    {props.showFullChat ? (
                                        <i className="bi bi-caret-down-fill ignore-cursor" />
                                    ) : (
                                        <i className="bi bi-caret-up-fill ignore-cursor" />
                                    )}
                                </span>
                            </OverlayTrigger>
                        )}
                        <table
                            style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                                alignItems: "start",
                                minHeight: "200px",
                                maxHeight: "200px",
                                overflowY: "auto",
                                width: "fit-content"
                            }}
                            className="hide-scrollbar"
                        >
                            <tbody
                                style={{
                                    width: "20vw",
                                    maxWidth: "25vw"
                                }}
                                className="text-rtl-reset"
                            >
                                {messages.slice(-5).map(message => (
                                    <tr key={message.key} className="d-flex justify-content-middle">
                                        <td className="d-flex text-start pe-2 ignore-cursor">
                                            <span
                                                style={{ maxWidth: "130px" }}
                                                className="badge bg-semitransparent text-truncate d-flex align-items-center"
                                            >
                                                {message.sender}
                                            </span>
                                        </td>
                                        <td className="text-white ignore-cursor">
                                            <p className="fw-bold mb-0">
                                                <Linkify
                                                    options={{
                                                        target: "_blank",
                                                        rel: "noopener referrer",
                                                        className: "clickable text-purple-dark"
                                                    }}
                                                >
                                                    {message.message}
                                                </Linkify>
                                            </p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            )}
        </>
    );
}
