import { addComponent } from "bitecs";
import { GeoGebra } from "../bit-components";
import { addObject3DComponent } from "../utils/jsx-entity";
import { createGeoGebraObject } from "../utils/css2d/create-geogebra-object";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { geogebraTexture } from "../utils/textures/geogebra-texture";

export function inflateGeoGebra(world, eid, params) {
    const questionContainer = createGeoGebraObject(params.link);

    const geometry = new THREE.BoxGeometry(0.398 * (16 / 9), 0.47, 0.02);
    const material = new THREE.MeshBasicMaterial({ map: geogebraTexture, color: 0xffffff });

    console.log("GeoGebra inflating");

    const cube = new THREE.Mesh(geometry, material);
    cube.visible = false;

    const parentObj = new THREE.Object3D();
    parentObj.add(cube);

    const obj3d = new CSS2DObject(questionContainer);
    parentObj.add(obj3d);
    // window.APP.scene.object3D.add(obj3d);

    addComponent(world, GeoGebra, eid);

    GeoGebra.src[eid] = APP.getSid(obj3d.element.src);
    GeoGebra.obj3d[eid] = APP.getSid(obj3d);
    GeoGebra.placeholder[eid] = APP.getSid(cube);

    addObject3DComponent(world, eid, parentObj);

    console.log("GeoGebra done inflating");
    return eid;
}
