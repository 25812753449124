import { takeOwnership } from "./take-ownership";
import {
    Networked,
    LiveFeed,
    Equirectangular,
    Text,
    IsLocalOnly,
    YouTube,
    Description,
    Billboard,
    StudentsCanMove,
    Link,
    Locked
} from "../bit-components";
import { hasComponent } from "bitecs";

export default class UserHelper {
    constructor(websocket) {
        this.socket = websocket;
    }

    can(permission) {
        return window.APP.objectHelper.can(permission);
    }

    kick(user) {
        console.log("Attempting to kick", user);
        this.socket.send(JSON.stringify({ message: "kick_order", user: user }));
    }

    register_event(event) {
        this.socket.send(JSON.stringify({ message: "register_event", event: event }));
    }

    handleKicked() {
        console.log("HandleKicked");
        // Disconnect from Daphne
        window.APP.scene.emit("kicked");
        window.APP.hubChannel.disconnect();
        this.socket.close();
        // Disconnect from NAF
        NAF.connection.disconnect();
        // Disconnect from Dialog
        // APP.dialog.disconnect();
        // Clean up avatar
    }
}
