export function createGeoGebraObject(link) {
    var iframe = document.createElement("iframe");

    iframe.style.border = "0px";
    iframe.style.pointerEvents = "auto";

    iframe.src = link;

    console.log("Created geogebra object", link, iframe);

    return iframe;
}
