import { defineQuery, entityExists, exitQuery, hasComponent } from "bitecs";
import { Matrix4, Mesh, MeshStandardMaterial, Object3D } from "three";
import { HubsWorld } from "../../app";
import {
    HoveredRemoteLeft,
    HoveredRemoteRight,
    Interacted,
    Networked,
    NetworkedWaypoint,
    Owned,
    SceneRoot,
    Waypoint
} from "../../bit-components";
import { CharacterControllerSystem } from "../../systems/character-controller-system";
import { sleep } from "../../utils/async-utils";
import { anyEntityWith, findAncestorWithComponent } from "../../utils/bit-utils";
import { coroutine } from "../../utils/coroutine";
import { EntityID } from "../../utils/networking-types";
import { takeOwnership } from "../../utils/take-ownership";
import { takeOwnershipWithTime } from "../../utils/take-ownership-with-time";
import { setMatrixWorld } from "../../utils/three-utils";

const waypointQuery = defineQuery([Waypoint]);

export function moveToSpawnPoint(world: HubsWorld, characterController: CharacterControllerSystem) {
    const spawnPoints = window.APP.spawnpoints; // waypointQuery(world);
    console.log("Got spawnpoints, ", spawnPoints);

    if (spawnPoints.length) {
        const waypoint = spawnPoints[Math.floor(Math.random() * spawnPoints.length)];
        console.log("Getting the position of said waypoint and teleporting...", waypoint);
        characterController.teleportTo(waypoint);
    }
}

function moveToWaypoint(
    world: HubsWorld,
    eid: number,
    characterController: CharacterControllerSystem,
    instant: boolean
) {
    const newPosition = APP.getString(Waypoint.position[eid]);

    characterController.teleportTo(newPosition);
}
