import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

export default function HelpPopover() {
    return (
        <Dropdown.Item target="_blank" rel="noopener noreferrer" href="https://megaminds.tawk.help">
            <i className="bi bi-question-circle-fill text-purple-dark"></i>
            <span className="ms-2 mb-0">Help & Resources</span>
        </Dropdown.Item>
    );
}
