import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { toast } from "react-hot-toast";

import Row from "react-bootstrap/Row";

export default function SubmitPictureMenu({ closePopover, ...rest }) {
    const [tempImage, setTempImage] = useState(null);
    const [tempBlob, setTempBlob] = useState(null);
    const [text, setText] = useState("");

    const takeScreenshot = () => {
        let canvas = document
            .querySelector("a-scene")
            .components["mega-screenshot"].getCanvas("perspective");
        setTempImage(canvas.toDataURL("image/png"));
        canvas.toBlob(function(blob) {
            setTempBlob(blob);
        });
    };

    const uploadScreenshot = () => {
        let form_data = new FormData();

        if (text !== "") {
            form_data.append("title", text);
        } else {
            form_data.append("title", "No title");
        }
        form_data.append(
            "student_name",
            window.APP.store.state.profile.displayName
        );
        form_data.append("current_classroom", window.APP.hub.hub_id);
        form_data.append("photo", tempImage);

        fetch(process.env.REACT_APP_MM_API_URL + "submissions/", {
            method: "post",
            body: form_data,
        });

        toast.success("Submission submitted!");
        closePopover();
    };

    return (
        <Row className="p-2">
            {tempImage && (
                <>
                    <img className="img-fluid" src={tempImage} />
                    <p>Describe your submission</p>
                    <input
                        className="form-control"
                        required
                        maxLength="255"
                        onChange={(e) => setText(e.target.value)}
                    />
                    <Button
                        variant="success"
                        onClick={() => uploadScreenshot()}
                    >
                        Submit this photo
                    </Button>
                    <Button
                        variant="purple-dark"
                        onClick={() => {
                            setTempImage(null);
                            setTempBlob(null);
                        }}
                    >
                        Start over
                    </Button>
                </>
            )}
            {!tempImage && (
                <Button
                    variant="purple-dark"
                    className="align-middle"
                    onClick={() => takeScreenshot()}
                >
                    Take a screenshot
                </Button>
            )}
        </Row>
    );
}

SubmitPictureMenu.propTypes = {
    className: PropTypes.string,
    onBack: PropTypes.func,
};
