import { StudentsCanMove } from "../../bit-components";
import { defineNetworkSchema } from "../../utils/define-network-schema";
import {
    deserializerWithMigrations,
    Migration,
    NetworkSchema,
    read,
    StoredComponent,
    write,
} from "../../utils/network-schemas";
import type { EntityID } from "../../utils/networking-types";

const runtimeSerde = defineNetworkSchema(StudentsCanMove);

const migrations = new Map<number, Migration>();

function apply(eid: EntityID, { version, data }: StoredComponent) {
    if (version !== 1) return false;
    const { toggled }: { toggled: any } = data;
    write(StudentsCanMove.toggled, eid, toggled);
    return true;
}

export const StudentsCanMoveSchema: NetworkSchema = {
    componentName: "students-can-move",
    serialize: runtimeSerde.serialize,
    deserialize: runtimeSerde.deserialize,
    serializeForStorage: function serializeForStorage(eid: EntityID) {
        return {
            version: 1,
            data: {
                toggled: read(StudentsCanMove.toggled, eid)
            },
        };
    },
    deserializeFromStorage: deserializerWithMigrations(migrations, apply),
}
