import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";

export default function ToolbarButton(props) {
    const target = useRef(null);

    return (
        <>
            <Button
                disabled={props.disabled}
                ref={target}
                data-tooltip-id={props.tipKey ? props.tipKey : "tooltip-container"}
                data-tooltip-content={props.disableTooltip ? null : props.tipBody}
                id={props.id}
                className={props.className}
                style={
                    !!AFRAME.utils.device.isMobile()
                        ? {
                              maxWidth: "50px"
                          }
                        : null
                }
                variant={props.variant}
                onClick={() => props.onClick()}
            >
                {props.icon}
                {props.text && <span className="ms-2">{props.text}</span>}
            </Button>
            {props.overlayMenu && (
                <Overlay
                    target={target.current}
                    show={true}
                    placement="top"
                    rootClose={true}
                    onHide={() => props.onClick()}
                >
                    {props.overlayMenu}
                </Overlay>
            )}
        </>
    );
}
