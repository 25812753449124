import { Locked } from "../../bit-components";
import { defineNetworkSchema } from "../../utils/define-network-schema";
import {
    deserializerWithMigrations,
    Migration,
    NetworkSchema,
    read,
    StoredComponent,
    write,
} from "../../utils/network-schemas";
import type { EntityID } from "../../utils/networking-types";

const runtimeSerde = defineNetworkSchema(Locked);

const migrations = new Map<number, Migration>();

function apply(eid: EntityID, { version, data }: StoredComponent) {
    if (version !== 1) return false;

    const { toggled }: { toggled: any } = data;
    write(Locked.toggled, eid, toggled);
    return true;
}

export const LockedSchema: NetworkSchema = {
    componentName: "locked",
    serialize: runtimeSerde.serialize,
    deserialize: runtimeSerde.deserialize,
    serializeForStorage: function serializeForStorage(eid: EntityID) {
        return {
            version: 1,
            data: {
                toggled: read(Locked.toggled, eid)
            },
        };
    },
    deserializeFromStorage: deserializerWithMigrations(migrations, apply),
};
