import { defineQuery, hasComponent } from "bitecs";
import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import {
    FloatyObject,
    Networked,
    GLTFModel,
    Nickname,
    Question,
    YouTube,
    Link,
    MediaAudio,
    MediaPDF,
    MediaVideo,
    MediaImage
} from "../../bit-components";

const objectQuery = defineQuery([FloatyObject]);

const getString = obj => {
    const world = APP.world;

    if (obj.name === "Youtube") {
        return <i className="bi bi-youtube"></i>;
    }

    if (obj.name === "Question") {
        return <i className="bi bi-question-square"></i>;
    }

    if (obj.name === "Interactable Text") {
        return <i className="bi bi-card-text"></i>;
    }

    if (obj.name === "Link") {
        return <i className="bi bi-link"></i>;
    }

    if (obj.name === "Interactable Media" && obj.children.length) {
        const eid = obj.children[0].eid;

        if (hasComponent(world, MediaImage, eid)) {
            return <i className="bi bi-file-earmark-image"></i>;
        }

        if (hasComponent(world, MediaVideo, eid)) {
            return <i className="bi bi-file-earmark-play"></i>;
        }

        if (hasComponent(world, MediaPDF, eid)) {
            return <i className="bi bi-file-earmark-pdf"></i>;
        }

        if (hasComponent(world, MediaAudio, eid)) {
            return <i className="bi bi-file-earmark-music"></i>;
        }

        if (hasComponent(world, GLTFModel, eid)) {
            return <i className="bi bi-box"></i>;
        }
    }

    return <i className="bi bi-bug"></i>;
};

export default function ObjectList() {
    let [objects, setObjects] = useState([]);

    useEffect(() => {
        function getObjects() {
            const objs = objectQuery(APP.world);
            const arr = [];
            objs.forEach(eid => {
                arr.push(APP.world.eid2obj.get(eid));
            });
            setObjects(arr);
        }
        getObjects();
        const interval = setInterval(() => getObjects(), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="position-fixed clickable ui" style={{ top: "24px", left: "24px" }}>
            <Accordion className="reset-style-accordion">
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="">
                        <span className="me-2">
                            <i className="bi bi-boxes me-2"></i>
                            Objects
                        </span>
                    </Accordion.Header>
                    <Accordion.Body className="p-0 max-height-60">
                        <Table hover className="mb-0">
                            <tbody>
                                {objects.map(obj => {
                                    let nid = "Unnamed";
                                    try {
                                        // Get set Name
                                        nid = APP.getString(Nickname.value[obj.eid]);
                                    } catch {
                                        try {
                                            nid = APP.getString(Networked.id[obj.eid]);
                                        } catch {
                                            nid = "Unnamed";
                                        }
                                    }
                                    return (
                                        <tr key={obj.id}>
                                            <td
                                                style={{
                                                    width: "230px",
                                                    maxWidth: "230px"
                                                }}
                                                className="text-truncate"
                                                title={"object"}
                                                onClick={() => {
                                                    APP.scene.systems["hubs-systems"].cameraSystem.inspect(obj, 2);
                                                    APP.scene.emit("right_menu_changed", obj.eid);
                                                }}
                                            >
                                                {nid}
                                            </td>
                                            <td className="align-middle"></td>
                                            <td className="align-middle">{getString(obj)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}
