import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { PermissionStatus } from "../../../utils/media-devices-utils";
import { useAuth0 } from "@auth0/auth0-react";

export default function PreferencesMenu(props) {
    const { user, isLoading, isAuthenticated, loginWithPopup } = useAuth0();

    const [showKeyHints, setShowKeyHints] = useState(!!window.APP.store.state.preferences.hideHints);

    const updateVoiceVolume = e => {
        window.APP.store.update({
            preferences: { globalVoiceVolume: e.target.value }
        });
    };

    const updateMediaVolume = e => {
        window.APP.store.update({
            preferences: { globalMediaVolume: e.target.value }
        });
    };

    const updateSfxVolume = e => {
        window.APP.store.update({
            preferences: { globalSFXVolume: e.target.value }
        });
    };

    const updatePanningDisabled = e => {
        window.APP.store.update({
            preferences: { disableLeftRightPanning: e.target.checked }
        });
    };

    const updateShowFPS = e => {
        window.APP.scene.components["stats-plus"].toggleVisibility(e.target.checked);
    };

    const updateHideHints = e => {
        setShowKeyHints(e.target.checked);
        window.APP.store.update({
            preferences: { hideHints: e.target.checked }
        });
        window.APP.scene.emit("keyhints_updated", e.target.checked);
    };

    const updateCursorSize = e => {
        window.APP.store.update({
            preferences: { cursorSize: e.target.value }
        });
    };
    const setPreferredMic = value => {
        window.APP.store.update({
            preferences: { preferredMic: value }
        });
    };

    return (
        <div className="p-2">
            <p className="fw-bold">Audio</p>
            <Card.Body className="border rounded p-4 m-2 mb-3">
                <Form.Label>Preferred Microphone</Form.Label>
                <select
                    value={props.audioStatus.input.selectedMicDevice}
                    onChange={e => {
                        setPreferredMic(e.target.value);
                        props.audioStatus.changeInputDevice(e.target.value);
                    }}
                    className="form-select mb-3"
                    aria-label="Select a microphone"
                >
                    {props.audioStatus.input.availableMicDevices.map(mic => {
                        return (
                            <option key={mic.value} value={mic.value}>
                                {mic.label}
                            </option>
                        );
                    })}
                </select>
                <Form.Label>Global Voice Volume</Form.Label>
                <Form.Range
                    onChange={e => updateVoiceVolume(e)}
                    defaultValue={window.APP.store.state.preferences.globalVoiceVolume}
                    min={0}
                    max={200}
                />
                <Form.Label>Global Media Volume</Form.Label>
                <Form.Range
                    onChange={e => updateMediaVolume(e)}
                    defaultValue={window.APP.store.state.preferences.globalMediaVolume}
                    min={0}
                    max={200}
                />
                <Form.Label>Global Sound Effects Volume</Form.Label>
                <Form.Range
                    onChange={e => updateSfxVolume(e)}
                    defaultValue={window.APP.store.state.preferences.globalSFXVolume}
                    min={0}
                    max={200}
                />
                <Form>
                    <Form.Check
                        type="switch"
                        onChange={e => updatePanningDisabled(e)}
                        defaultValue={window.APP.store.state.preferences.disableLeftRightPanning}
                        id="panningSwitch"
                        label="Disable Left/Right Audio Panning"
                    />
                </Form>
            </Card.Body>
            <p className="fw-bold">Misc</p>
            <Card.Body className="border rounded p-4 m-2 mb-3">
                <Form>
                    <Form.Check
                        type="switch"
                        onChange={e => updateHideHints(e)}
                        checked={showKeyHints}
                        id="hideHints"
                        label="Hide key hints in the bottom right"
                    />
                </Form>
                <Form>
                    <Form.Check
                        type="switch"
                        onChange={e => updateShowFPS(e)}
                        id="showFPS"
                        label="Show Performance Metrics"
                    />
                </Form>
                <Form>
                    <Form.Label>Cursor Size</Form.Label>
                    <Form.Range
                        onChange={e => updateCursorSize(e)}
                        defaultValue={window.APP.store.state.preferences.cursorSize}
                        min="0.4"
                        max="3"
                        step="0.2"
                    />
                </Form>
                {process.env.REACT_APP_MM_IS_ENV !== "prod" && (
                    <>
                        <button
                            onClick={() => {
                                loginWithPopup().then(() => {
                                    location.reload();
                                });
                            }}
                        >
                            Teacher Login
                        </button>
                    </>
                )}
            </Card.Body>
        </div>
    );
}
