import { addComponent } from "bitecs";
import { MediaPDF } from "../bit-components";
import { addObject3DComponent } from "../utils/jsx-entity";
import { createImageMesh } from "../utils/create-image-mesh";

export function inflatePDF(world, eid, { texture, ratio, index, page }) {
    const mesh = createImageMesh(texture, ratio, "blend");
    addObject3DComponent(world, eid, mesh);
    addComponent(world, MediaPDF, eid);
    
    MediaPDF.index[eid] = index;
    MediaPDF.pageRef[eid] = APP.getSid(page);
    MediaPDF.pageCount[eid] = page.pageCount;

    return eid;
}
