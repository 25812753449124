import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { ProfileStep } from "./ProfileStep";
import { useLocation } from "react-router-dom";

export default function FirstTimeFlow() {
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);

    const destination = searchParams.get("destination");

    return <ProfileStep destination={destination} />;
}
