import { defineQuery, enterQuery } from "bitecs";
import { ParticleEmitter } from "../../bit-components";
import { ParticleEmitter as PE } from "../../components/lib-hubs/three-particle-emitter";
import { textureLoader } from "../../utils/media-utils";

const emitterQuery = defineQuery([ParticleEmitter]);
const emitterEnterQuery = enterQuery(emitterQuery);
const particleEmitter = new PE(null);

export function particleEmitterSystem(world, dt) {
    emitterEnterQuery(world).forEach(function (eid) {
        const obj = APP.world.eid2obj.get(eid);
        particleEmitter.visible = false;

        // Grab initial config, maybe redundant as this is used only for emojis right now.
        particleEmitter.startOpacity = 1;
        particleEmitter.middleOpacity = 1;
        particleEmitter.endOpacity = 0;
        particleEmitter.startSize = 0.1;
        particleEmitter.endSize = 0.2;
        particleEmitter.sizeRandomness = 0.05;
        particleEmitter.ageRandomness = 1;
        particleEmitter.lifetime = 1;
        particleEmitter.lifetimeRandomness = 0.2;
        particleEmitter.particleCount = 20;
        particleEmitter.startVelocity.copy(new THREE.Vector3(0, 0.25, 0));
        particleEmitter.endVelocity.copy(new THREE.Vector3(0, 0.25, 0));

        textureLoader.load(APP.getString(ParticleEmitter.src[eid]), texture => {
            particleEmitter.material.uniforms.map.value = texture;
            particleEmitter.material.side = THREE.DoubleSide;
            particleEmitter.visible = true;

            obj.add(particleEmitter);
        });
    });

    emitterQuery(world).forEach(function (eid) {
        if (particleEmitter.visible) {
            particleEmitter.update(dt / 1000);
        }
    });
}
