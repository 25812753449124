import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

export function MobileWarningModal(props) {
    return (
        <div className="position-absolute top-50 start-50 translate-middle ui">
            <Container className="d-flex justify-content-center p-0">
                <Card
                    style={{ maxWidth: "26rem", minWidth: "16rem", maxHeight: "70vh", overflow: "auto" }}
                    className="clickable"
                >
                    <Card.Body>
                        <Row>
                            <Col>
                                <p className="fw-bold">Mobile Devices</p>
                            </Col>
                        </Row>
                        <Col>
                            <p className="fw-light text-muted">
                                Mobile devices have limited functionality. To get the most out of MegaMinds, use a
                                computer.
                            </p>
                        </Col>
                        <Button variant="success" className="w-100 clickable" onClick={() => props.onFinished()}>
                            Got it
                        </Button>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}
