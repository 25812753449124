export default class ChatHelper extends EventTarget {
    constructor(websocket) {
        super();
        this.socket = websocket;
    }

    get_last_messages() {
        try {
            this.channel.send(JSON.stringify({ message: "get_permissions" }));
        } catch (e) {
            console.warn("ObjectHelper: Getting permissions failed", e);
        }
    }

    send_message(message) {
        this.socket.send(
            JSON.stringify({
                sender: window.APP.store.state.profile.displayName,
                message: "send_chat_message",
                body: message
            })
        );
    }

    start_ai_generate() {
        console.log("Starting AI Generation");
        this.socket.send(
            JSON.stringify({
                sender: window.APP.store.state.profile.displayName,
                message: "start_ai_generate"
            })
        );
    }

    check_ai_generate(message) {
        this.socket.send(
            JSON.stringify({
                sender: window.APP.store.state.profile.displayName,
                message: "check_ai_generate"
            })
        );
    }

    send_ai_message(message) {
        this.socket.send(
            JSON.stringify({
                sender: window.APP.store.state.profile.displayName,
                message: "send_to_ai",
                body: message
            })
        );
    }

    receive_message(sender, message, authenticated) {
        this.dispatchEvent(
            new CustomEvent("receive_message", {
                detail: { sender, message, authenticated }
            })
        );
    }
}
