import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import MegamindsLogo from "../../../assets/megaminds/images/megaminds-logo.png";

export function LandingModal(props) {
    const isMobileVR = AFRAME.utils.device.isMobileVR();
    const isTeacher = window.APP.objectHelper.can("anything");
    const isAudioPermitted = window.APP.objectHelper.can("can_share_microphone");

    const enterInVR = () => {
        props.entryManager.enterScene();
        props.scene.enterVR();
    };

    if (isMobileVR) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle ui">
                <Container className="d-flex justify-content-center p-0">
                    <Card
                        style={{ maxWidth: "26rem", minWidth: "16rem", maxHeight: "70vh", overflow: "auto" }}
                        className="clickable"
                    >
                        <Card.Body>
                            <Card.Title className="mb-0 text-center">{props.room.title}</Card.Title>
                        </Card.Body>
                        <Card.Body>
                            <Row>
                                <Col xs={12} className="d-grid">
                                    <Button variant="purple-dark" className="mb-2" onClick={() => enterInVR()}>
                                        <span>Enter in VR</span>
                                    </Button>
                                </Col>
                                <Col xs={12} className="d-grid">
                                    {props.error && props.error}
                                    <Button
                                        variant="purple-dark"
                                        className="mb-2"
                                        onClick={() => props.setStep("audio")}
                                    >
                                        <span>Audio Settings</span>
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        );
    }

    return (
        <div className="position-absolute top-50 start-50 translate-middle ui">
            <Container className="d-flex justify-content-center p-0">
                <Card
                    style={{ maxWidth: "26rem", minWidth: "16rem", maxHeight: "70vh", overflow: "auto" }}
                    className="clickable"
                >
                    <div className="d-flex p-5 justify-content-center">
                        <img style={{ maxWidth: "126px" }} src={MegamindsLogo} />
                    </div>
                    <Card.Body>
                        <Card.Title className="mb-0 text-center">{props.room.title}</Card.Title>
                        {props.room.locked && (
                            <Card.Text className="mb-0 mt-2 text-center text-muted">
                                This Lesson is currently locked and only available to you.
                            </Card.Text>
                        )}
                    </Card.Body>
                    <Card.Body>
                        <Row>
                            {isTeacher && (
                                <Col xs={12} className="d-grid">
                                    {props.error && props.error}
                                    <Button
                                        variant="purple-dark"
                                        className="mb-2"
                                        onClick={() => props.setStep("invite")}
                                    >
                                        <span>Invite Others</span>
                                    </Button>
                                </Col>
                            )}
                            {isAudioPermitted && (
                                <Col xs={12} className="d-grid">
                                    <Button
                                        variant="purple-dark"
                                        className="mb-2"
                                        onClick={() => props.setStep("audio")}
                                    >
                                        <span>
                                            Audio Settings{" "}
                                            {props.permissionStatus === "denied" && (
                                                <i className="bi bi-exclamation-circle"></i>
                                            )}
                                        </span>
                                    </Button>
                                </Col>
                            )}
                            <Col xs={12} className="d-grid">
                                <Button variant="success" className="mb-2" onClick={() => props.onEnterRoom()}>
                                    <span>Enter</span>
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}
