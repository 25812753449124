import React from "react";
import ToolbarButton from "./ToolbarButton";

export default function ChatButton(props) {
    return (
        <ToolbarButton
            variant="purple-dark"
            onClick={props.showFullChat}
            icon={<i className="bi bi-chat-fill"></i>}
        />
    );
}
