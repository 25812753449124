import {
    defineQuery,
    enterQuery,
    exitQuery,
    hasComponent,
    removeComponent,
} from "bitecs";
import {
    SpinningAnimation,
    SpinningAnimationUpdated,
} from "../../bit-components";
import anime from "animejs";

const spinningAnimationQuery = defineQuery([SpinningAnimation]);
const spinningEnterQuery = enterQuery(spinningAnimationQuery);
const spinningExitQuery = exitQuery(spinningAnimationQuery);

let activeAnimation = null;

export function spinningAnimationSystem(world) {
    spinningEnterQuery(world).forEach(function (eid) {
        console.log("Should start spinning animation here", eid);
        const obj = world.eid2obj.get(window.APP.arrowIndicator);

        const animation =
            activeAnimation ||
            anime({
                targets: [obj.position],
                y: obj.position.y + 0.06,
                easing: "easeInOutSine",
                direction: "alternate",
                loop: true,
                duration: 1500,
                update: function () {
                    obj.updateMatrix();
                },
                autoplay: false,
            });

        animation.play();
        activeAnimation = animation;
    });

    spinningExitQuery(world).forEach(function (eid) {
        console.log("Exited Animation");
        const obj = world.eid2obj.get(window.APP.arrowIndicator);
        obj.position.set(0, 0.4, 0);
        obj.updateMatrix();
        console.log("Position is now", obj.position);
        activeAnimation.pause();
    });
}

