import { defineQuery } from "bitecs";
import { Question } from "../../bit-components";

const questionQuery = defineQuery([Question]);

const worldPos = new THREE.Vector3();

export function questionSystem(world) {
    questionQuery(world).forEach(function (eid) {
        // Initialize all the questions here
        const question = world.eid2obj.get(eid);

        const avatarPovObject = document.getElementById("avatar-pov-node").object3D;
        avatarPovObject.getWorldPosition(worldPos);

        if (worldPos.distanceTo(question.position) < 2) {
            question.element.style.pointerEvents = "all";
            question.element.style.opacity = 1;
        } else if (worldPos.distanceTo(question.position) < 3) {
            question.element.style.opacity = 0.5;
            question.element.style.pointerEvents = "none";
        } else {
            question.element.style.opacity = 0;
            question.element.style.pointerEvents = "none";
        }

        const isNearby = worldPos.distanceTo(question.position) < 0.8 + (question.scale.x > 1 ? question.scale.x * 0.4 : 1);

        if (!window.APP.editMode) {
            if (isNearby) {
                question.element.style.pointerEvents = "auto";
                question.element.style.opacity = "1";
            } else {
                question.element.style.pointerEvents = "none";
                question.element.style.opacity = "0";
            }
        }
        if (window.APP.editMode) {
            if (isNearby) {
                question.element.style.pointerEvents = "none";
                question.element.style.opacity = "0.2";
            } else {
                question.element.style.pointerEvents = "none";
                question.element.style.opacity = "0";
            }
        }
    });
}
