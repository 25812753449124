import React, { useState } from "react";
import "react-tooltip/dist/react-tooltip.css";

import { Toaster } from "react-hot-toast";

import RoomEntryModal from "./classroom/entry/RoomEntryModal";
import RoomInterface from "./classroom/RoomInterface";

// Legacy Hubs stuff
import { RootContainer } from "./misc/RoomLayout";

function Interface(props) {
    const [entered, setEntered] = useState(false);

    // Show landing modal first
    if (!entered) {
        return (
            <RootContainer scene={props.scene} store={props.store}>
                <Toaster />
                <RoomEntryModal
                    room={props.room}
                    scene={props.scene}
                    audioStatus={props.audioStatus}
                    entryManager={props.entryManager}
                    setEntered={setEntered}
                />
            </RootContainer>
        );
    }

    // Load the rest of the Room interface
    return (
        <RoomInterface
            room={props.room}
            scene={props.scene}
            store={props.store}
            messageDispatch={props.messageDispatch}
            sessionId={props.sessionId}
            audioStatus={props.audioStatus}
            presences={props.presences}
        />
    );
}

export default Interface;
