import React from "react";
import { LinkItUrl } from "react-linkify-it";
import { toArray as toEmojis } from "react-emoji-render";
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
import { spawnFromFileList } from "../../load-media-on-paste-or-drop";

const emojiRegex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/;

const CHAT_MESSAGE_TEXTURE_SIZE = 1024;
export const MAX_MESSAGE_LENGTH = 750;

export function formatMessageBody(body) {
    // Support wrapping text in ` to get monospace, and multiline.
    const bodyLength =
        body.length >= MAX_MESSAGE_LENGTH ? MAX_MESSAGE_LENGTH : body.length;
    const monospace = body.startsWith("`") && body.endsWith("`");
    const cleanedBody = (
        monospace
            ? body.substring(1, bodyLength - 1)
            : body.substring(0, bodyLength)
    ).trim();

    const messages = cleanedBody.split("\n").map((message, i) => (
        <p key={i}>
            <LinkItUrl
                properties={{ target: "_blank", rel: "noopener referrer" }}
            >
                {toEmojis(message)}
            </LinkItUrl>
        </p>
    ));

    const multiline = messages.length > 1;

    let emoji = false;

    if (messages.length === 1) {
        const emojiComponents = toEmojis(cleanedBody);

        emoji =
            emojiComponents.length === 1 &&
            emojiComponents[0].props &&
            emojiComponents[0].props.children.match &&
            emojiComponents[0].props.children.match(emojiRegex);
    }

    return {
        formattedBody: <>{messages}</>,
        multiline,
        monospace,
        emoji,
    };
}

export function renderStickyNote(body) {
    return new Promise((resolve, error) => {
        const el = document.createElement("div");
        el.classList.add("html2canvas-card");
        const text = document.createElement("p");
        text.innerText = body;
        text.classList.add("mb-0");

        el.appendChild(text);
        document.body.appendChild(el);

        const width = el.offsetWidth;
        const height = el.offsetHeight;
        const ratio = height / width;
        const scale =
            (CHAT_MESSAGE_TEXTURE_SIZE * Math.min(1.0, 1.0 / ratio)) /
            el.offsetWidth;

        html2canvas(el, { scale: scale, backgroundColor: null }).then(
            (canvas) => {
                canvas.toBlob((blob) => {
                    var file = new File([blob], "image.png", {
                        type: "image/png",
                    });

                    spawnFromFileList([file], true);
                    el.remove();
                    resolve();
                }, "image/jpeg");
            }
        );
    });
}
