import { SpinningAnimation } from "../../bit-components";
import { defineNetworkSchema } from "../../utils/define-network-schema";
import {
    deserializerWithMigrations,
    Migration,
    NetworkSchema,
    read,
    StoredComponent,
    write,
} from "../../utils/network-schemas";
import type { EntityID } from "../../utils/networking-types";

const runtimeSerde = defineNetworkSchema(SpinningAnimation);

const migrations = new Map<number, Migration>();

function apply(eid: EntityID, { version, data }: StoredComponent) {
    if (version !== 1) return false;

    const { toggled, speed }: { toggled: any; speed: any } = data;
    write(SpinningAnimation.toggled, eid, toggled);
    write(SpinningAnimation.speed, eid, speed);
    return true;
}

export const SpinningAnimationSchema: NetworkSchema = {
    componentName: "spinning-animation",
    serialize: runtimeSerde.serialize,
    deserialize: runtimeSerde.deserialize,
    serializeForStorage: function serializeForStorage(eid: EntityID) {
        return {
            version: 1,
            data: {
                toggled: read(SpinningAnimation.toggled, eid),
                speed: read(SpinningAnimation.speed, eid),
            },
        };
    },
    deserializeFromStorage: deserializerWithMigrations(migrations, apply),
};
