import React, { useEffect, useState } from "react";

import BrowseMenuContainer from "./left/BrowseMenuContainer";
import ExtendedModal from "./modals/ExtendedModal";
import SubmitPictureMenu from "./floating/SubmitPictureMenu";

export default function LeftMenu(props) {
    const [object, setObject] = useState(null);

    useEffect(() => {
        props.scene.addEventListener("left_menu_changed", el => {
            if (el.detail === null) {
                setObject(null);
            } else {
                setObject(el.detail);
            }
        });
    }, []);

    if (!object) {
        return null;
    }

    if (object === "student_picture_upload") {
        return (
            <ExtendedModal
                position="middle-start"
                title={"Picture Submission"}
                closePopover={() => {
                    setObject(null);
                }}
                content={<SubmitPictureMenu closePopover={() => setObject(null)} />}
            />
        );
    }

    if (["gif_menu", "model_menu", "upload_menu"].includes(object)) {
        return <BrowseMenuContainer selectedTab={object} closeMenu={() => setObject(null)} />;
    }

    return null;
}
