import React from "react";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import AudioButton from "../buttons/AudioButton";
import ReactionButton from "../buttons/ReactionButton";
import RaiseHandButton from "../buttons/RaiseHandButton";
import ShareButton from "../buttons/ShareButton";
import AddMediaButton from "../buttons/AddMediaButton";
import ToggleEditModeButton from "../buttons/ToggleEditModeButton";
import TeacherButton from "../buttons/TeacherButton";

export default function CenterToolbar(props) {
    return (
        <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup className="me-2" id="step-toolbar-communicate">
                <AudioButton audioStatus={props.audioStatus} scene={props.scene} tutorial={props.tutorial} />
                <RaiseHandButton
                    scene={props.scene}
                    tutorial={props.tutorial}
                    presences={props.presences}
                    sessionid={props.sessionId}
                />
                <ReactionButton tutorial={props.tutorial} />
                <ShareButton audioStatus={props.audioStatus} tutorial={props.tutorial} scene={props.scene} />
                <TeacherButton tutorial={props.tutorial} />
            </ButtonGroup>
            <ButtonGroup>
                <AddMediaButton tutorial={props.tutorial} />
                <ToggleEditModeButton
                    tutorial={props.tutorial}
                    isEditMode={props.isEditMode}
                    toggleEditMode={props.toggleEditMode}
                />
            </ButtonGroup>
        </ButtonToolbar>
    );
}
