export function createNearpodObject(link) {
    var iframe = document.createElement("iframe");

    iframe.style.border = "0px";
    iframe.style.pointerEvents = "all";
    iframe.src = "https://app.nearpod.com/?pin=" + link + "&he=false";

    console.log("Created nearpod object", link);

    // https://app.nearpod.com/presentation?pin= + ID

    return iframe;
}
