import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import useAvatars from "../../hooks/useAvatars";
import { toast } from "react-hot-toast";
import { ProfileStep } from "../../home/ProfileStep";

export default function UserDetailMenu(props) {
    const presence = window.APP.hubChannel.presence?.state[props.user.id]?.metas[0];

    const [showAvatarSelector, setShowAvatarSelector] = useState(false);

    if (!presence) {
        // If user disconnects or is kicked etc.
        props.setObject(null);
    }

    const canKick = window.APP.objectHelper.can("kick_users");

    const isMe = props.user.id === NAF.clientId;

    const handleSave = (avatar, displayName) => {
        window.APP.store.update({
            profile: { avatarId: avatar.id, displayName: displayName }
        });
        // Fetch new avatar
        toast.success("Saved new Avatar and Name");

        handleClose();
        props.setObject(null);
    };

    const handleClose = () => setShowAvatarSelector(false);

    return (
        <Modal size="sm" centered onHide={() => props.setObject(null)} show className="">
            <Modal.Header closeButton>
                <Modal.Title>Manage User</Modal.Title>
            </Modal.Header>
            {showAvatarSelector ? (
                <ProfileStep
                    fromUserList={true}
                    handleClose={handleClose}
                    handleBack={() => setShowAvatarSelector(false)}
                    handleSave={handleSave}
                />
            ) : (
                <Modal.Body className="d-flex flex-column align-items-center">
                    <Row>
                        <Col>
                            <img style={{ maxHeight: "170px" }} className="rounded-circle img-fluid" src={props.img} />
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col>
                            <h5 className="">{presence.profile.displayName}</h5>
                        </Col>
                    </Row>
                    {isMe && (
                        <Row>
                            <Col>
                                <Alert variant="warning">
                                    <p className="mb-0">
                                        Because you have selected yourself, some buttons may be disabled.
                                    </p>
                                </Alert>
                            </Col>
                        </Row>
                    )}
                    <Row className="w-100">
                        <Col xs={12}>
                            <ButtonGroup vertical className="w-100">
                                {isMe && (
                                    <Button variant="success" onClick={() => setShowAvatarSelector(true)}>
                                        Change Name & Avatar
                                    </Button>
                                )}
                                {canKick && (
                                    <>
                                        <Button
                                            disabled={isMe}
                                            variant="purple-dark"
                                            onClick={() => {
                                                window.APP.userHelper.kick(props.user.id);
                                                props.setObject(null);
                                            }}
                                        >
                                            Kick
                                        </Button>
                                        <Button
                                            disabled={isMe}
                                            onClick={() => {
                                                window.APP.hubChannel.mute(props.user.id);
                                            }}
                                            variant="purple-dark"
                                        >
                                            Mute
                                        </Button>
                                    </>
                                )}
                                <Button
                                    disabled={isMe}
                                    onClick={() => {
                                        window.APP.hubChannel.hide(props.user.id);
                                    }}
                                    variant="purple-dark"
                                >
                                    Block
                                </Button>
                                <Button
                                    disabled={isMe}
                                    onClick={() => {
                                        window.APP.hubChannel.unhide(props.user.id);
                                    }}
                                    variant="purple-dark"
                                >
                                    Unblock
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Modal.Body>
            )}
        </Modal>
    );
}
