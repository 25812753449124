import React, { useState, useEffect } from "react";
import { getMicrophonePresences } from "../../utils/microphone-presence";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHand, faVolumeXmark, faVolumeHigh, faVolumeOff } from "@fortawesome/free-solid-svg-icons";

import useAvatars from "../hooks/useAvatars";
import { fakePeople } from "./fakePeople";
import brokenAvatar from "../../assets/megaminds/images/avatar-broken.png";

export function userFromPresence(sessionId, presence, micPresences, mySessionId) {
    const meta = presence.metas[presence.metas.length - 1];

    if (!micPresences) {
        return {
            id: sessionId,
            isMe: mySessionId === sessionId,
            micPresence: null,
            ...meta
        };
    }

    const micPresence = micPresences.get(sessionId);

    return {
        id: sessionId,
        isMe: mySessionId === sessionId,
        micPresence,
        ...meta
    };
}

function usePeopleList(presences, mySessionId, micUpdateFrequency = 500) {
    const [people, setPeople] = useState([]);

    useEffect(() => {
        let timeout;

        function updateMicrophoneState() {
            const micPresences = getMicrophonePresences();

            const peopleList = Object.entries(presences).map(([id, presence]) => {
                return userFromPresence(id, presence, micPresences, mySessionId);
            });

            const filteredBotList = peopleList.filter(user => user.profile.displayName !== "teacher_bot_2df");

            setPeople(filteredBotList);

            timeout = setTimeout(updateMicrophoneState, micUpdateFrequency);
        }

        updateMicrophoneState();

        return () => {
            clearTimeout(timeout);
        };
    }, [presences, micUpdateFrequency, setPeople, mySessionId]);

    return people;
}

export default function TopMenu({ presences, sessionId }) {
    const people = usePeopleList(presences, sessionId);
    // const people = fakePeople

    const [avatars, isLoading, isError] = useAvatars();

    const [expanded, setExpanded] = useState(true);

    const getAvatarPicture = avatarId => {
        const avi = avatars.find(avi => avi.id === avatarId);
        if (!avi) {
            return brokenAvatar;
        }

        if (avi.thumbnail.startsWith("/")) {
            return "http://localhost:8000" + avi.thumbnail;
        } else {
            return avi.thumbnail;
        }
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }

    const compressedList = people.length > 5;

    if (!expanded) {
        return (
            <p
                className="position-fixed clickable ui text-white"
                style={{ top: "24px", left: "24px" }}
                onClick={() => setExpanded(true)}
            >
                Users
            </p>
        );
    }

    return (
        <table className="position-fixed clickable ui" style={{ top: "24px", left: "24px" }}>
            {people.length > 7 && (
                <p className="mb-0 clickable ui text-white" onClick={() => setExpanded(false)}>
                    Hide Userlist
                </p>
            )}
            <tbody>
                {people.map(user => {
                    return (
                        <tr
                            className={
                                compressedList
                                    ? "d-flex align-items-center animate__animated animate__bounceIn pointer-cursor"
                                    : "d-flex align-items-center pb-1 animate__animated animate__bounceIn pointer-cursor"
                            }
                            onClick={() => {
                                APP.scene.emit("right_menu_changed", {
                                    variant: "user_settings",
                                    payload: { user: user, img: getAvatarPicture(user.profile.avatarId) }
                                });
                            }}
                            key={user.id}
                        >
                            <td
                                style={{
                                    width: compressedList ? "20px !important" : "30px !important",
                                    height: compressedList ? "20px !important" : "30px !important",
                                    maxWidth: compressedList ? "20px" : "30px"
                                }}
                            >
                                <img
                                    className={
                                        user.micPresence?.talking
                                            ? "rounded-circle border border-3 border-success img-fluid"
                                            : "rounded-circle border border-3 border-secondary img-fluid"
                                    }
                                    src={getAvatarPicture(user.profile.avatarId)}
                                />
                            </td>
                            <td className="text-truncate text-white ps-2 pe-2" title={user.profile.displayName}>
                                {compressedList ? (
                                    <p className="mb-0">
                                        <small>{user.profile.displayName}</small>
                                    </p>
                                ) : (
                                    <p className="mb-0">{user.profile.displayName}</p>
                                )}
                            </td>
                            <td className="align-middle">
                                {user.hand_raised && (
                                    <FontAwesomeIcon
                                        className="animate__animated animate__wobble animate__infinite"
                                        icon={faHand}
                                        color="#f0ad4e"
                                    />
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
