import React, { useState, useEffect } from "react";
import { hasComponent } from "bitecs";
import { Owner, Description, MediaLoader } from "../../../bit-components";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

function SketchfabInfo({ metadata }) {
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Model Information & Attribution</Accordion.Header>
                <Accordion.Body>
                    <p className="fw-bold">
                        {metadata.name} by{" "}
                        <a target="_blank" rel="noopener noreferrer" href={metadata.user.profileUrl}>
                            {metadata.user.displayName}
                        </a>
                    </p>
                    <p>
                        Licence:{" "}
                        <a rel="noreferrer noopener" href={metadata.license.url} target="_blank">
                            {metadata.license.label}
                        </a>
                    </p>
                    <p className="fw-light">{metadata.license.requirements}</p>
                    <p>
                        Face Count: {metadata.faceCount}, Material Count: {metadata.materialCount}, Vertex Count:{" "}
                        {metadata.vertexCount}, Texture Count: {metadata.textureCount}
                    </p>
                    <a href={metadata.viewerUrl} target="_blank" rel="noopener" className="fw-light">
                        <small>Open on Sketchfab.com</small>
                    </a>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default function ObjectDetailMenu({ eid, setObject, closePopover, isPreview }) {
    window.APP.scene.emit("tip-finished", "has-opened-object-detail");

    const [isInspecting, setIsInspecting] = useState(false);
    const obj = window.APP.world.eid2obj.get(eid);
    const world = window.APP.world;

    const getDescription = () => {
        if (obj.parent.name === "Interactable Media" && hasComponent(world, Description, obj.parent.eid)) {
            return APP.getString(Description.value[obj.parent.eid]);
        } else if (hasComponent(world, Description, eid)) {
            return APP.getString(Description.value[eid]);
        } else {
            return null;
        }
    };
    

    useEffect(() => {
        return () => {
            setObject(null);
            window.APP.scene.systems["hubs-systems"]["cameraSystem"].helper.visible = false;
            closePopover();
        };
    }, []);

    console.log("Metadata before sketchfabinfo", obj.userData);

    return (
        <div className="p-3 step-detail-menu">
            {isInspecting ? (
                <Button
                    variant="purple-dark"
                    className="w-100"
                    onClick={() => {
                        window.APP.scene.systems["hubs-systems"].cameraSystem.uninspect();
                        setIsInspecting(false);
                    }}
                >
                    <i className="bi bi-arrow-return-left" />
                    <span className="ms-2">Stop Inspecting</span>
                </Button>
            ) : (
                <Button
                    variant="purple-dark"
                    className="w-100"
                    onClick={() => {
                        window.APP.scene.systems["hubs-systems"].cameraSystem.inspect(obj);
                        setIsInspecting(true);
                    }}
                >
                    <i className="bi bi-zoom-in"></i>
                    <span className="ms-2">Inspect</span>
                </Button>
            )}
            <div className="mt-2">
                {hasComponent(world, Owner, eid) && (
                    <p className="fw-light">Created By {APP.getString(Owner.value[eid])}</p>
                )}

                {obj.userData.sketchfab_meta && <SketchfabInfo metadata={obj.userData.sketchfab_meta} />}
                {!!getDescription() ? (
                    <>
                        <p className="fw-bold">Description</p>
                        <p>{getDescription()}</p>
                    </>
                ) : (
                    <>
                        This object has no description.
                        {window.APP.objectHelper.can("can_create") && !isPreview && (
                            <p className="text-muted">Use Edit Mode to add a description.</p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
