import merge from "deepmerge";
import detectMobile, { isAndroid, isMobileVR } from "../utils/is-mobile";

const LOCAL_STORE_KEY = "___hubs_store";
const STORE_STATE_CACHE_KEY = Symbol();
import { EventTarget } from "event-target-shim";
import { NO_DEVICE_ID } from "../utils/media-devices-utils.js";

const defaultMaterialQuality = (function () {
    const MATERIAL_QUALITY_OPTIONS = ["low", "medium", "high"];

    const isMobile = window.AFRAME && (AFRAME.utils.device.isMobile() || AFRAME.utils.device.isMobileVR());

    if (isMobile) {
        return "low";
    }

    return "high";
})();

// WebAudio on Android devices (only non-VR devices?) seems to have
// a bug and audio can be broken if there are many people in a room.
// We have reported the problem to the Android devs. We found that
// using equal power panning mode can mitigate the problem so we
// use low audio panning quality (= equal power mode) by default
// on Android as workaround until the root issue is fixed on
// Android end. See
//   - https://github.com/mozilla/hubs/issues/5057
//   - https://bugs.chromium.org/p/chromium/issues/detail?id=1308962
const defaultAudioPanningQuality = () => {
    return isAndroid() && !isMobileVR() ? "Low" : "High";
};

//workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=1626081 : disable echoCancellation, noiseSuppression, autoGainControl
const isFirefoxReality = window.AFRAME?.utils.device.isMobileVR() && navigator.userAgent.match(/Firefox/);

// Durable (via local-storage) schema-enforced state that is meant to be consumed via forward data flow.
// (Think flux but with way less incidental complexity, at least for now :))
export const SCHEMA = {
    id: "/HubsStore",

    definitions: {
        profile: {
            type: "object",
            additionalProperties: false,
            properties: {
                displayName: { type: "string", pattern: "^[A-Za-z0-9_~ -øæåØÆÅ]{3,32}$" },
                avatarId: { type: "number" }
            }
        },

        credentials: {
            type: "object",
            additionalProperties: false,
            properties: {
                token: { type: ["null", "string"] },
                auth_token: { type: ["null", "string"] }
            }
        },

        activity: {
            type: "object",
            additionalProperties: true,
            properties: {
                finishedOnboarding: { type: "boolean" },
                entryCount: { type: "number" }
            }
        },

        settings: {
            type: "object",
            additionalProperties: false,
            properties: {
                lastUsedMicDeviceId: { type: "string" },
                micMuted: { type: "bool" }
            }
        },

        preferences: {
            type: "object",
            additionalProperties: true,
            properties: {
                // Preferred media will be set dynamically
                preferredMic: { type: "string", default: NO_DEVICE_ID },
                preferredSpeakers: { type: "string", default: NO_DEVICE_ID },
                preferredCamera: { type: "string", default: NO_DEVICE_ID },
                muteMicOnEntry: { type: "bool", default: false },
                disableLeftRightPanning: { type: "bool", default: false },
                audioNormalization: { type: "bool", default: 0.0 },
                invertTouchscreenCameraMove: { type: "bool", default: true },
                enableOnScreenJoystickLeft: { type: "bool", default: detectMobile() },
                enableOnScreenJoystickRight: { type: "bool", default: false },
                enableGyro: { type: "bool", default: true },
                lazyLoadSceneMedia: { type: "bool", default: false },
                // if unset, maxResolution = screen resolution
                maxResolutionWidth: { type: "number", default: undefined },
                maxResolutionHeight: { type: "number", default: undefined },
                resolutionMultiplier: { type: "string", default: 1 },
                globalVoiceVolume: { type: "string", default: 100 },
                globalMediaVolume: { type: "string", default: 100 },
                globalSFXVolume: { type: "number", default: 100 },
                snapRotationDegrees: { type: "number", default: 45 },
                materialQualitySetting: { type: "string", default: defaultMaterialQuality },
                enableDynamicShadows: { type: "bool", default: false },
                disableSoundEffects: { type: "bool", default: false },
                disableMovement: { type: "bool", default: false },
                disableBackwardsMovement: { type: "bool", default: false },
                disableStrafing: { type: "bool", default: false },
                disableTeleporter: { type: "bool", default: false },
                disableAutoPixelRatio: { type: "bool", default: false },
                disableEchoCancellation: { type: "bool", default: isFirefoxReality },
                disableNoiseSuppression: { type: "bool", default: isFirefoxReality },
                disableAutoGainControl: { type: "bool", default: isFirefoxReality },
                showRtcDebugPanel: { type: "bool", default: false },
                showAudioDebugPanel: { type: "bool", default: false },
                enableAudioClipping: { type: "bool", default: false },
                audioClippingThreshold: { type: "number", default: 0.015 },
                audioPanningQuality: { type: "string", default: defaultAudioPanningQuality() },
                cursorSize: { type: "string", default: 0.6 },
                hideHints: { type: "bool", default: false },
                nametagVisibility: { type: "string", default: "showAll" },
                nametagVisibilityDistance: { type: "number", default: 5 },
                avatarVoiceLevels: { type: "object" }
            }
        }
    },

    type: "object",

    properties: {
        profile: { $ref: "#/definitions/profile" },
        credentials: { $ref: "#/definitions/credentials" },
        activity: { $ref: "#/definitions/activity" },
        settings: { $ref: "#/definitions/settings" },
        preferences: { $ref: "#/definitions/preferences" }
    },

    additionalProperties: false
};

export default class Store extends EventTarget {
    constructor() {
        super();

        this._preferences = {};

        if (localStorage.getItem(LOCAL_STORE_KEY) === null) {
            localStorage.setItem(LOCAL_STORE_KEY, JSON.stringify({}));
        }

        // When storage is updated in another window
        window.addEventListener("storage", e => {
            if (e.key !== LOCAL_STORE_KEY) return;
            delete this[STORE_STATE_CACHE_KEY];
            this.dispatchEvent(new CustomEvent("statechanged"));
        });

        this.update({
            activity: {},
            settings: {},
            credentials: {},
            profile: {},
            preferences: {}
        });
    }

    get state() {
        if (!this.hasOwnProperty(STORE_STATE_CACHE_KEY)) {
            const state = (this[STORE_STATE_CACHE_KEY] = JSON.parse(localStorage.getItem(LOCAL_STORE_KEY)));
            if (!state.preferences) state.preferences = {};
            this._preferences = { ...state.preferences }; // cache prefs without injected defaults
            // inject default values
            for (const [key, props] of Object.entries(SCHEMA.definitions.preferences.properties)) {
                if (!props.hasOwnProperty("default")) continue;
                if (!state.preferences.hasOwnProperty(key)) {
                    state.preferences[key] = props.default;
                } else if (state.preferences[key] === props.default) {
                    delete this._preferences[key];
                }
            }
        }

        return this[STORE_STATE_CACHE_KEY];
    }

    update(newState, mergeOpts) {
        const finalState = merge({ ...this.state, preferences: this._preferences }, newState, mergeOpts);

        localStorage.setItem(LOCAL_STORE_KEY, JSON.stringify(finalState));
        delete this[STORE_STATE_CACHE_KEY];

        if (newState.profile !== undefined) {
            this.dispatchEvent(new CustomEvent("profilechanged"));
        }

        this.dispatchEvent(new CustomEvent("statechanged"));


        return finalState;
    }

    get schema() {
        return SCHEMA;
    }
}
