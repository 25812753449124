import React, { useEffect } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export function AvatarPicker({ results, avatar, setAvatar }) {

    const handleAvatarClick = (avi) => {
        setAvatar(avi);
    };

    return (
        <Row className="pe-3 ps-3">
            {results.map((avi) => {
                if (avi.thumbnail.startsWith("/")) {
                    avi.thumbnail = "http://localhost:8000" + avi.thumbnail;
                }
                return (
                    <Col
                        key={avi.id}
                        xs={4}
                        sm={3}
                        md={3}
                        lg={3}
                        xxl={3}
                        className={
                            avi.id === avatar.id
                                ? "border-success border bg-success p-0"
                                : "bg-white border rounded p-0"
                        }
                        onClick={() => handleAvatarClick(avi)}
                    >
                        <img
                            draggable="false"
                            className="img-fluid"
                            src={avi.thumbnail}
                        />
                    </Col>
                );
            })}
        </Row>
    );
}
