import React, { useState, useEffect, useCallback } from "react";
import "react-tooltip/dist/react-tooltip.css";

import ReactGA from "react-ga4";
import { Toaster } from "react-hot-toast";

import ClassroomInterface from "./ClassroomInterface";
import EditOnboardingFlow from "./onboarding/EditOnboardingFlow";
import ViewOnboardingFlow from "./onboarding/ViewOnboardingFlow";

// Legacy Hubs stuff
import { ChatContextProvider } from "../misc/ChatContext";
import { RootContainer } from "../misc/RoomLayout";

import { Tooltip } from "react-tooltip";

import RightMenu from "../menus/RightMenu";
import LeftMenu from "../menus/LeftMenu";
import TopMenu from "./TopMenu";
import MobileTopMenu from "./MobileTopMenu";
import AIHelperMenu from "./AIHelperMenu";
import InstructionsMenu from "./InstructionsMenu";
import ObjectList from "../editor/ObjectList";

function RoomInterface(props) {
    const [onboardingStep, setOnboardingStep] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false);
    const [counter, setCounter] = useState(0);

    const [finishedViewModeOnboarding, setFinishedViewModeOnboarding] = useState(
        window.APP.store.state.activity.finishedViewModeOnboarding
    );

    const [finishedEditModeOnboarding, setFinishedEditModeOnboarding] = useState(
        window.APP.store.state.activity.finishedOnboarding
    );

    const [editModeEid, setEditModeEid] = useState(null);

    const resetTutorials = () => {
        setFinishedEditModeOnboarding(false);
        setFinishedViewModeOnboarding(false);
    };

    window.APP.editMode = isEditMode;

    const increaseCounter = useCallback(() => {
        console.log("Increase counter by 1", counter);
        setCounter(counter + 1);
    }, [counter]);

    useEffect(() => {
        window.APP.scene.addEventListener("setEditMode", event => {
            setIsEditMode(true);
            setEditModeEid(event.detail);
        });
        window.APP.scene.addEventListener("finished_permissions_sync", event => {
            console.log("got new permissions, refresh interface");
            increaseCounter();
        });
    }, []);

    return (
        <RootContainer scene={props.scene} store={props.store}>
            <ChatContextProvider messageDispatch={props.messageDispatch}>
                <Toaster />
                <Tooltip id="tooltip-container" />
                {isEditMode ? (
                    <ObjectList sessionId={props.sessionId} presences={props.presences} />
                ) : (
                    <>
                        {AFRAME.utils.device.isMobile() ? (
                            <MobileTopMenu
                                onboardingStep={onboardingStep}
                                sessionId={props.sessionId}
                                presences={props.presences}
                            />
                        ) : (
                            <TopMenu
                                onboardingStep={onboardingStep}
                                sessionId={props.sessionId}
                                presences={props.presences}
                            />
                        )}
                    </>
                )}
                {props.room.distributed_parent ? (
                    <InstructionsMenu />
                ) : (
                    !!window.APP.objectHelper.is_teacher() && (
                        <AIHelperMenu
                            finishedViewModeOnboarding={finishedViewModeOnboarding}
                            onboardingStep={onboardingStep}
                        />
                    )
                )}
                <RightMenu scene={props.scene} audioStatus={props.audioStatus} />
                <LeftMenu scene={props.scene} />
                {!finishedViewModeOnboarding && (
                    <ViewOnboardingFlow
                        presences={props.presences}
                        scene={props.scene}
                        sessionId={props.sessionId}
                        toggleEditMode={() => setIsEditMode(!isEditMode)}
                        setOnboardingStep={setOnboardingStep}
                        skippedOnboarding={() => {
                            ReactGA.event({
                                category: "support",
                                action: "skipped_viewmode_tutorial",
                                label: "Someone skipped the tutorial for View Mode"
                            });
                            window.APP.isOnboarding = false;
                            window.APP.store.update({
                                activity: { finishedViewModeOnboarding: true }
                            });
                            setFinishedViewModeOnboarding(true);
                        }}
                        finishedOnboarding={() => {
                            ReactGA.event({
                                category: "support",
                                action: "finished_viewmode_tutorial",
                                label: "Someone completed the entire tutorial for View Mode" // optional
                            });
                            window.APP.isOnboarding = false;
                            window.APP.store.update({
                                activity: { finishedViewModeOnboarding: true }
                            });
                            setFinishedViewModeOnboarding(true);
                        }}
                    />
                )}
                {!finishedEditModeOnboarding && isEditMode && (
                    <EditOnboardingFlow
                        editModeEid={editModeEid}
                        skippedOnboarding={() => {
                            ReactGA.event({
                                category: "support",
                                action: "skipped_editmode_tutorial",
                                label: "Someone skipped the tutorial for Edit Mode"
                            });
                            window.APP.isOnboarding = false;
                            window.APP.store.update({
                                activity: { finishedOnboarding: true }
                            });
                            setFinishedEditModeOnboarding(true);
                        }}
                        finishedOnboarding={() => {
                            ReactGA.event({
                                category: "support",
                                action: "finished_viewmode_tutorial",
                                label: "Someone completed the entire tutorial for View Mode" // optional
                            });
                            window.APP.isOnboarding = false;
                            window.APP.store.update({
                                activity: { finishedOnboarding: true }
                            });
                            setFinishedEditModeOnboarding(true);
                        }}
                    />
                )}
                <ClassroomInterface
                    toggleEditMode={() => setIsEditMode(!isEditMode)}
                    audioStatus={props.audioStatus}
                    onboardingStep={onboardingStep}
                    setOnboardingStep={setOnboardingStep}
                    finishedViewModeOnboarding={finishedViewModeOnboarding}
                    isEditMode={isEditMode}
                    store={props.store}
                    room={props.room}
                    hub={props.hub}
                    sessionId={props.sessionId}
                    presences={props.presences}
                    scene={props.scene}
                    resetTutorials={resetTutorials}
                />
            </ChatContextProvider>
        </RootContainer>
    );
}

export default RoomInterface;
