import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Tooltip } from "react-tooltip";

export default function OnboardingSlide({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps
}) {
    console.log(step);
    return (
        <div className="card" style={{ maxWidth: "23rem" }} {...tooltipProps}>
            <Tooltip id="tooltip-container" />
            <div className="card-body">{step.content}</div>
            <div className="card-footer bg-white">
                {index > 0 && (
                    <Button className="btn-sm" variant="white" onClick={() => step.setPrevStep()}>
                        Back
                    </Button>
                )}
                {index === 0 && (
                    <Button className="btn-sm" variant="white" onClick={() => step.skipOnboarding()}>
                        Skip
                    </Button>
                )}
                {continuous && step.hideNext && (
                    <span
                        className="d-inline-block float-end"
                        data-tooltip-id="tooltip-container"
                        data-tooltip-content="Complete the task before you can continue."
                    >
                        <Button
                            disabled={step.hideNext}
                            variant="purple-dark"
                            className="btn-sm"
                            onClick={() => {
                                console.log(step.setNextStep);
                                step.setNextStep();
                            }}
                        >
                            {step.lastStep ? "Finish" : <>{index === 0 ? "Start" : "Next"}</>}
                        </Button>
                    </span>
                )}
                {continuous && !step.hideNext && (
                    <Button
                        variant="purple-dark"
                        className="float-end btn-sm"
                        onClick={() => {
                            console.log(step.setNextStep);
                            step.setNextStep();
                        }}
                    >
                        {step.lastStep ? "Finish" : <>{index === 0 ? "Start" : "Next"}</>}
                    </Button>
                )}
            </div>
        </div>
    );
}
