import React, { useState, useEffect } from "react";
import { LandingModal } from "./LandingModal";
import { MobileWarningModal } from "./MobileWarningModal";
import ReactGA from "react-ga4";

import { AudioStep } from "./AudioStep";
import { InviteBox } from "./InviteBox";
import { InitialClassroomSetupFlow } from "../create/InitialClassroomSetupFlow";

export default function RoomEntryModal(props) {
    // const [step, setStep] = useState(AFRAME.utils.device.isMobile() ? "mobile" : "profile");
    const [step, setStep] = useState("profile");
    const [loaded, setLoaded] = useState(true);

    const isTeacher = window.APP.objectHelper.can("fresh_classroom");
    const isFresh = props.room.is_fresh;

    const continueToAudioSetup = () => {
        setStep("audio");
    };

    const enterRoom = async () => {
        ReactGA.event({
            category: "entry",
            action: "enter_classroom",
            label: "Entered a classroom", // optional
            nonInteraction: true // optional, true/false
        });

        props.entryManager.enterScene(true);
        props.setEntered(true);

        // Check if user wants to mute on entry
        if (window.APP.store.state.preferences.muteMicOnEntry) {
            console.log("Mute on entry, should mute this!");
            APP.dialog.enableMicrophone(false);
        }
    };

    if (!loaded) {
        return <p>Loading...</p>;
    }
    if (isTeacher && isFresh) {
        console.log("IS FRESH");
        return (
            <InitialClassroomSetupFlow
                setStep={setStep}
                continueToAudio={continueToAudioSetup}
                onEnterRoom={enterRoom}
            />
        );
    }

    if (step === "invite") {
        return <InviteBox setStep={setStep} />;
    }

    if (step === "mobile") {
        return <MobileWarningModal onFinished={() => setStep("profile")} />;
    }

    if (step === "profile") {
        return (
            <LandingModal
                room={props.room}
                audioStatus={props.audioStatus}
                scene={props.scene}
                entryManager={props.entryManager}
                setStep={setStep}
                onEnterRoom={enterRoom}
            />
        );
    }

    if (step === "audio") {
        return <AudioStep audioStatus={props.audioStatus} setStep={setStep} scene={props.scene} />;
    }

    return <p>Uh oh</p>;
}
