import React, { useEffect, useState } from "react";

export default function usePresence(scene, initialPresence) {
    const [presence, setPresence] = useState(initialPresence);

    const onPresenceUpdate = ({ detail: presence }) => {
        if (presence.sessionId === NAF.clientId) setPresence(presence);
    };
    useEffect(
        () => {
            scene.addEventListener("presence_updated", onPresenceUpdate);
            return () =>
                scene.removeEventListener("presence_updated", onPresenceUpdate);
        },
        [scene]
    );

    return presence;
}
