import React, { useState, useRef } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import HelpButton from "../buttons/HelpButton";
import ChangeAvatarButton from "../buttons/ChangeAvatarButton";
import PreferencesButton from "../buttons/PreferencesButton";
import ResetViewOnboardingButton from "../buttons/ResetViewOnboardingButton";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

export default function RightToolbar(props) {
    const [show, setShow] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    return (
            <ButtonGroup id="step-toolbar-help">
                <Dropdown bsPrefix="none" className="d-flex">
                    <Dropdown.Toggle
                        data-tooltip-id="tooltip-container"
                        data-tooltip-content="Change avatar, preferences, and more."
                        variant="white"
                        className="caret-off"
                    >
                        <FontAwesomeIcon icon={faCog} color="#6f6ec4" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu onToggle={() => setShow(!show)} show={show}>
                        <ChangeAvatarButton />
                        <PreferencesButton />
                        <ResetViewOnboardingButton resetOnboarding={props.resetOnboarding} />
                        <HelpButton />
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonGroup>
    );
}
