import React, { useState, useEffect, useRef } from "react";
import Card from "react-bootstrap/Card";
import useAudioSystem from "../hooks/useAudioSystem";

export function IdleDetected() {
    const [shouldMute, setShouldMute] = useState(false);
    const [countdown, setCountdown] = useState(20);

    const [audioLoaded, audioStatus] = useAudioSystem(APP.scene);

    console.log(audioStatus);

    useEffect(() => {
        if (countdown === 0) {
            console.log("Countdown reached, muting user...");
            setShouldMute(true);
            setCountdown(null);
            if (audioLoaded) {
                APP.dialog.enableMicrophone(false);
                APP.dialog.enableWebcam(false);
            }
        }
        if (!countdown) return;

        const intervalId = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [countdown]);

    return (
        <div className="idle-detected-warning clickable">
            <Card>
                <Card.Body className="text-center">
                    <p className="fw-bold">Are you still here?</p>
                    {shouldMute ? (
                        <>
                            <p>You have been muted due to inactivity.</p>
                            <p className="fw-light">
                                <small>Click anywhere to hide this message</small>
                            </p>
                        </>
                    ) : (
                        <p>You will be muted in {countdown} seconds unless you click or move</p>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
}
