import React, { useState, useEffect } from "react";

import Joyride from "react-joyride";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import OnboardingSlide from "./OnboardingSlide";
import ReactGA from "react-ga4";
import AnchorPoint from "../../menus/modals/AnchorPoint";
import image from "../../../assets/megaminds/images/example-picture.jpg";
import { spawnFromUrl } from "../../../load-media-on-paste-or-drop";

import { createNetworkedEntity } from "../../../utils/create-networked-entity";
import { Vector3 } from "three";

import { removeEntity, addComponent, hasComponent } from "bitecs";
import { IsLocalOnly } from "../../../bit-components";

export default function EditOnboardingFlow(props) {
    window.APP.isOnboardingEditor = true;
    const initialState = JSON.parse(window.localStorage.getItem("__megaminds_finished_tips"));

    const [stepIndex, setStepIndex] = useState(0);

    const [hasMoved, setHasMoved] = useState(initialState ? initialState.locomotion : false);
    const [hasDeletedImage, setHasDeletedImage] = useState(initialState ? initialState.locomotion : false);

    const [tempImage, setTempImage] = useState(null);

    const [hasMovedObject, setHasMovedObject] = useState(false);

    const [hasOpenedEditMenu, setHasOpenedEditMenu] = useState(false);

    const [hasLooked, setHasLooked] = useState(initialState ? initialState.look : false);

    const [hasUploaded, setHasUploaded] = useState(false);

    const addExampleImage = () => {
        // Dont add more if already spawned. From for example clicking "Back"
        if (!tempImage) {
            if (props.editModeEid) {
                setTempImage(props.editModeEid);
            } else {
                const eid = createNetworkedEntity(APP.world, "media", {
                    src: image,
                    recenter: true,
                    resize: true
                });
                const avatarPov = document.querySelector("#avatar-pov-node").object3D;
                const obj = APP.world.eid2obj.get(eid);

                console.log("Adding local only", eid);
                addComponent(APP.world, IsLocalOnly, eid);

                console.log(hasComponent(APP.world, IsLocalOnly, eid));
                obj.position.copy(avatarPov.localToWorld(new Vector3(0, 0, -1.5)));
                obj.lookAt(avatarPov.getWorldPosition(new Vector3()));
                setTempImage(eid);
            }
        }
    };

    const removeExampleImage = () => {
        removeEntity(APP.world, tempImage);
    };

    useEffect(() => {
        window.APP.scene.addEventListener("right_menu_changed", event => {
            setHasOpenedEditMenu(false);
        });
        window.APP.scene.addEventListener("tip-finished", event => {
            if (event.detail === "has-opened-object-menu") {
                setHasOpenedEditMenu(true);
            }
            if (event.detail === "delete-image") {
                setHasDeletedImage(true);
            }
            if (event.detail === "has_grabbed") {
                setHasMovedObject(true);
            }
            console.log(event);
        });
    }, []);

    const finishedAllTips = hasLooked && hasMoved;

    const steps = [
        {
            target: ".root",
            placement: "center",
            disableScrolling: true,
            disableOverlayClose: true,
            skipOnboarding: () => {
                props.skippedOnboarding();
            },
            setNextStep: () => setStepIndex(stepIndex + 1),
            setPrevStep: () => setStepIndex(stepIndex - 1),
            title: "Welcome",
            content: (
                <div>
                    <p className="fw-bold">Edit Mode Tutorial</p>
                    <p>
                        This tutorial will introduce you to the basics of adding, moving and modifying content in your
                        3D Space.
                    </p>
                    <small className="text-muted">This tutorial takes approximately 3 minutes to complete.</small>
                </div>
            )
        },
        {
            target: "#step-toolbar-edit",
            disableScrolling: true,
            disableOverlayClose: true,
            setNextStep: () => {
                addExampleImage();
                setStepIndex(stepIndex + 1);
            },
            setPrevStep: () => setStepIndex(stepIndex - 1),
            title: "Content",
            content: (
                <div>
                    <p className="fw-bold">Toggling Edit Mode</p>
                    <p>The button turns green when Edit Mode is enabled.</p>
                    <p>You can at any time enable and disable Edit Mode from this button.</p>
                </div>
            )
        },
        {
            target: ".anchor-top-start",
            placement: "bottom",
            isFixed: true,
            floaterProps: {
                hideArrow: true
            },
            setNextStep: () => setStepIndex(stepIndex + 1),
            setPrevStep: () => setStepIndex(stepIndex - 1),
            disableOverlay: true,
            disableScrolling: true,
            disableOverlayClose: true,
            title: "Content",
            hideNext: !hasMovedObject,
            content: (
                <div>
                    <p className="fw-bold">Moving Objects</p>
                    {hasMovedObject ? (
                        <>
                            <Alert variant="success">
                                <p className="mb-0">🎉 Good job!</p>
                            </Alert>
                            <Alert variant="purple-dark">
                                <p>
                                    <i className="bi bi-check-square-fill text-success me-2"></i>
                                    {props.editModeEid ? (
                                        <span className="mb-0">Click-and-drag your item to move it around.</span>
                                    ) : (
                                        <span className="mb-0">Click-and-drag the image to move it around.</span>
                                    )}
                                </p>
                                <small className="mb-0 text-muted">
                                    Tip: Combine with Arrow Keys to move objects over big distances
                                </small>
                            </Alert>
                        </>
                    ) : (
                        <>
                            {!props.editModeEid && (
                                <Alert variant="warning">
                                    <p className="mb-0">🖼️ We just put an image in front of you.</p>
                                </Alert>
                            )}
                            <Alert variant="purple-dark">
                                <i className="bi bi-square me-2"></i>
                                {props.editModeEid ? (
                                    <span className="mb-0">Click-and-drag your item to move it around.</span>
                                ) : (
                                    <span className="mb-0">Click-and-drag the image to move it around.</span>
                                )}
                            </Alert>
                        </>
                    )}
                </div>
            )
        },
        {
            target: ".anchor-top-start",
            placement: "bottom",
            isFixed: true,
            floaterProps: {
                hideArrow: true
            },
            setNextStep: () => {
                setStepIndex(stepIndex + 1);
            },
            setPrevStep: () => setStepIndex(stepIndex - 1),
            disableOverlay: true,
            disableScrolling: true,
            disableOverlayClose: true,
            title: "Content",
            hideNext: !hasOpenedEditMenu,
            content: (
                <div>
                    <p className="fw-bold">Editing Objects</p>
                    {hasOpenedEditMenu ? (
                        <>
                            <Alert variant="light">
                                <p className="mb-0">
                                    <i className="bi bi-check-square-fill text-success me-2"></i>
                                    <span>
                                        Click on the object to open the <span className="fw-bold">Edit Menu</span>
                                    </span>
                                </p>
                            </Alert>
                            <Alert variant="success">
                                <p className="mb-0">🎉 Good job!</p>
                                <small className="mb-0 text-muted">Click Next to continue</small>
                            </Alert>
                        </>
                    ) : (
                        <Alert variant="light">
                            <p className="mb-0">
                                <i className="bi bi-square me-2"></i>
                                <span>
                                    Click on the object to open the <span className="fw-bold">Edit Menu</span>
                                </span>
                            </p>
                        </Alert>
                    )}
                </div>
            )
        },
        {
            target: ".anchor-top-start",
            placement: "bottom",
            isFixed: true,
            floaterProps: {
                hideArrow: true
            },
            disableScrolling: true,
            disableOverlay: true,
            hideBackButton: true,
            setNextStep: () => {
                window.APP.scene.emit("right_menu_changed", null);
                removeEntity(APP.world, tempImage);
                setStepIndex(stepIndex + 1);
            },
            setPrevStep: () => {
                setStepIndex(stepIndex - 1);
            },
            title: "Editor",
            hideNext: !hasDeletedImage,
            content: (
                <div>
                    <p>
                        The <span className="fw-bold">Edit Menu</span> allows you to change the properties of your
                        object.
                    </p>
                    <p>
                        Try changing the scale, position, and explore the "Place..." menu, interactivity, and other
                        options.
                    </p>
                    <p>
                        You can also add a <span className="fw-italic">description</span> to your object to let students
                        read information similar to a museum tag.
                    </p>
                    {hasDeletedImage ? (
                        <Alert variant="success">
                            <p>🎉 Good job!</p>
                            <p className="mb-0">
                                <i className="bi bi-check-square-fill text-success me-2"></i>
                                <span className="mb-0">Delete the object</span>
                            </p>
                        </Alert>
                    ) : (
                        <>
                            <Alert variant="purple-dark">
                                <i className="bi bi-square me-2"></i>
                                <span className="mb-0">Delete the object</span>
                            </Alert>
                        </>
                    )}
                </div>
            )
        },
        {
            target: ".a-canvas",
            floaterProps: {
                hideArrow: true
            },
            isFixed: true,
            disableOverlay: false,
            hideBackButton: true,
            lastStep: true,
            setNextStep: () => {
                ReactGA.event({
                    category: "support",
                    action: "finished_editor_tutorial",
                    label: "Someone completed the entire tutorial", // optional
                    nonInteraction: false // optional, true/false
                });
                window.APP.isOnboardingEditor = false;
                props.finishedOnboarding();
                setStepIndex(stepIndex + 1);
            },
            setPrevStep: () => setStepIndex(stepIndex - 1),
            content: (
                <div>
                    <Alert variant="success">
                        <p className="mb-0">
                            🎉 Awesome! All done! You have explored the tools to move, add and edit your content. This
                            concludes the tutorial.
                        </p>
                    </Alert>
                    <p>If you want some ideas for the next things to try:</p>
                    <ul>
                        <li>Add Text</li>
                        <li>Add a Youtube Video</li>
                        <li>Add an Assessment</li>
                        <li>Uploading your own files</li>
                    </ul>
                </div>
            )
        }
    ];

    const defaultOptions = {
        arrowColor: "#fff",
        backgroundColor: "#fff",
        beaconSize: 36,
        disableOverlayClose: true,
        overlayColor: "rgba(0, 0, 0, 0.5)",
        primaryColor: "#6f6ec4",
        spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
        textColor: "#333",
        width: undefined,
        zIndex: 100
    };

    return (
        <>
            <AnchorPoint position="top-start" />
            <Joyride
                disableBeacon={true}
                disableScrolling={true}
                disableCloseOnEsc={true}
                stepIndex={stepIndex}
                tooltipComponent={OnboardingSlide}
                continuous={true}
                steps={steps}
                style={defaultOptions}
            />
        </>
    );
}
