/** @jsx createElementEntity */
import { createElementEntity } from "../utils/jsx-entity";
import { ProjectionMode } from "./projection-mode";
import { loadTextureCancellable } from "./textures/load-texture";
import { renderAsEntity } from "../utils/jsx-entity";
import { HubsWorld } from "../app";
import { Texture } from "three";
import { AlphaMode } from "./create-image-mesh";
import { MEDIA_LOADER_FLAGS } from "../bit/systems/media-loading";

export function* loadImage(world: HubsWorld, url: string, contentType: string, equi: any) {
    const project = equi & MEDIA_LOADER_FLAGS.IS_EQUI;

    const { texture, ratio, cacheKey }: { texture: Texture; ratio: number; cacheKey: string } =
        yield loadTextureCancellable(url, 1, contentType);

    return renderAsEntity(
        world,
        <entity
            name="Image"
            image={{
                originalSrc: url,
                texture,
                ratio,
                projection: project ? ProjectionMode.SPHERE_EQUIRECTANGULAR : ProjectionMode.FLAT,
                alphaMode: AlphaMode.Opaque,
                cacheKey
            }}
        />
    );
}
