export function createQuizizzObject(link) {
    var iframe = document.createElement("iframe");


    iframe.style.border = "0px";
    iframe.style.pointerEvents = "all";
    iframe.src = link

    console.log("Created peardeck object", link);

    return iframe;
}
