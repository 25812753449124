import { removeEntity } from "bitecs";
import { createNetworkedEntity } from "./create-networked-entity";
import emoji0 from "../assets/images/emojis/emoji_0.png";
import emoji1 from "../assets/images/emojis/emoji_1.png";
import emoji2 from "../assets/images/emojis/emoji_2.png";
import emoji3 from "../assets/images/emojis/emoji_3.png";
import emoji4 from "../assets/images/emojis/emoji_4.png";
import emoji5 from "../assets/images/emojis/emoji_5.png";
import emoji6 from "../assets/images/emojis/emoji_6.png";

export const availableEmojis = [
    { id: "smile", particle: emoji0 },
    { id: "laugh", particle: emoji1 },
    { id: "clap", particle: emoji2 },
    { id: "heart", particle: emoji3 },
    { id: "wave", particle: emoji4 },
    { id: "angry", particle: emoji5 },
    { id: "cry", particle: emoji6 }
];

export async function spawnReaction(particle) {
    const eid = createNetworkedEntity(APP.world, "reaction", {
        src: new URL(particle, window.location).href
    });

    const avatarPov = document.querySelector("#avatar-pov-node").object3D;
    const obj = APP.world.eid2obj.get(eid);
    obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
    obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));
    obj.updateMatrix();

    setTimeout(() => removeEntity(APP.world, eid), 4000);
}
