import { Description } from "../../bit-components";
import { defineNetworkSchema } from "../../utils/define-network-schema";
import {
    deserializerWithMigrations,
    Migration,
    NetworkSchema,
    read,
    StoredComponent,
    write,
} from "../../utils/network-schemas";
import type { EntityID } from "../../utils/networking-types";

const runtimeSerde = defineNetworkSchema(Description);

const migrations = new Map<number, Migration>();

function apply(eid: EntityID, { version, data }: StoredComponent) {
    if (version !== 1) return false;

    const { value }: { value: string } = data;

    write(Description.value, eid, value);
    return true;
}

export const DescriptionSchema: NetworkSchema = {
    componentName: "description",
    serialize: runtimeSerde.serialize,
    deserialize: runtimeSerde.deserialize,
    serializeForStorage: function serializeForStorage(eid: EntityID) {
        return {
            version: 1,
            data: {
                value: read(Description.value, eid)
            },
        };
    },
    deserializeFromStorage: deserializerWithMigrations(migrations, apply),
};
