import React, { useState } from "react";
import { addComponent } from "bitecs";
import { Nickname } from "../../../../bit-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Tooltip } from "react-tooltip";

import { createNetworkedEntity } from "../../../../utils/create-networked-entity";

export default function CreateLinkMenu(props) {
    const [link, setLink] = useState("");

    const handleSubmit = () => {
        let validUrl = link;

        if (!(validUrl.startsWith("https://") || validUrl.startsWith("http://"))) {
            validUrl = "https://" + validUrl;
        }

        const eid = createNetworkedEntity(APP.world, "link", { url: validUrl });
        // Add question component to eid, or inflate?

        const avatarPov = document.querySelector("#avatar-pov-node").object3D;
        const obj = APP.world.eid2obj.get(eid);
        obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
        obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

        addComponent(APP.world, Nickname, eid);
        Nickname.value[eid] = APP.getSid(validUrl);

        setTimeout(() => {
            window.APP.objectHelper.save(eid, null, validUrl, validUrl, "link");
        }, 1000);

        props.onFinish();
    };

    return (
        <div className="p-3">
            <Tooltip id="addMediaTooltip" />
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Back to list"
                variant="light"
                onClick={() => props.onBack()}
            >
                <i className="bi bi-arrow-left"></i>
            </Button>
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Guide to Components"
                href="https://megaminds.tawk.help/article/components"
                target="_blank"
                variant="light"
            >
                <i className="bi bi-question-circle-fill"></i>
            </Button>
            <hr />
            <p className="fw-bold">Create Link</p>
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Control
                    type="url"
                    name="url"
                    placeholder="https://google.com"
                    onChange={e => setLink(e.target.value)}
                />
            </Form.Group>
            <Button onClick={() => handleSubmit()} variant="success">
                Add
            </Button>
        </div>
    );
}
