import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { toast } from "react-hot-toast";
import MegamindsLogo from "../../../assets/megaminds/images/megaminds-logo.png";

export function InviteBox(props) {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location.origin + "/" + window.APP.hub.hub_id)
        toast.success("Copied to clipboard");
        // Pass
    };

    return (
        <div className="position-absolute top-50 start-50 translate-middle ui">
            <Container className="d-flex justify-content-center p-0">
                <Card
                    style={{ maxWidth: "26rem", minWidth: "16rem", maxHeight: "70vh", overflow: "auto" }}
                    className="clickable"
                >
                    <Card.Body>
                        <Row>
                            <Col>
                                <p className="text-center">
                                    Join at <span className="fw-bold mb-0">megaminds.world</span>
                                </p>
                            </Col>
                        </Row>
                        <Alert variant="success" className="text-center">
                            <Col>
                                <p style={{ fontSize: "3rem" }} className="fw-bold mb-0">
                                    {window.APP.hub.hub_id}
                                </p>
                            </Col>
                        </Alert>

                        <Button className="w-100 mb-2" variant="link" onClick={() => copyToClipboard(props.url)}>
                            Copy direct link
                        </Button>
                        <Button className="w-100" variant="purple-dark" onClick={() => props.setStep("profile")}>
                            Back
                        </Button>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}
