import { paths } from "./userinput/paths";

const VALID = 1;
const FINISH = 2;

const LOCAL_STORAGE_KEY = "__megaminds_finished_tips";

let localStorageCache = null;

const isMobile = AFRAME.utils.device.isMobile();
const isMobileVR = AFRAME.utils.device.isMobileVR();

function markTipFinished(tip) {
    console.log("WAS FINISHED", tip);
    // const storeData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    // storeData[tip] = { finished: true };
    // localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storeData));
    // localStorageCache = null;
    window.APP.scene.emit("tip-finished", tip);
}

const VALIDATORS = {
    look: function (userinput) {
        const cameraDelta =
            userinput.get(paths.device.smartMouse.cameraDelta) || userinput.get(paths.actions.cameraDelta);
        return cameraDelta ? FINISH : VALID;
    },
    locomotion: function (userinput) {
        const accel = userinput.get(paths.actions.characterAcceleration);

        // User moved
        return accel && (accel[0] !== 0 || accel[1] !== 0) ? FINISH : VALID;
    }
};

AFRAME.registerSystem("tips", {
    init: function () {
        // Always reset tips, let other systems control this
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({}));
    },

    tick: function () {
        if (!window.APP.isOnboarding) return;
        if (isMobileVR || isMobile) return; // Optimization for now, don't bother with this on mobile VR until we have real tips

        if (!this._userinput) {
            this._userinput = this.el.systems.userinput;

            if (!this._userinput) return;
        }

        const tips = ["look", "locomotion"];

        tips.forEach(tip => {
            if (localStorageCache === null) {
                localStorageCache = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
            }

            if (localStorageCache[tip] && localStorageCache[tip].finished) {
                return;
            }

            switch (VALIDATORS[tip](this._userinput, this.el, window.APP.hub)) {
                case FINISH:
                    markTipFinished(tip);
                    break;
            }
        });
    }
});
