import { defineQuery, hasComponent, Changed, removeComponent, enterQuery } from "bitecs";
import { FileReplaced, Equirectangular, MediaLoader, MediaImage, MediaVideo } from "../../bit-components";
import { inflateImageLoader } from "../../inflators/image-loader";
import { inflateVideoLoader } from "../../inflators/video-loader";

const changedSourceQuery = defineQuery([Changed(FileReplaced)]);
const changedEquirectangularQuery = defineQuery([Changed(Equirectangular)]);

export function fileReplacedSystem(world) {
    changedSourceQuery(world).forEach(function (eid) {
        const obj = APP.world.eid2obj.get(eid);
        world.eid2obj.get(eid).children = [];

        inflateImageLoader(world, eid, {
            src: APP.getString(FileReplaced.newSrc[eid]),
            recenter: false,
            resize: false,
            animateLoad: false,
            isObjectMenuTarget: false,
            equi: Equirectangular.toggled[eid]
        });
    });
    changedEquirectangularQuery(world).forEach(function (eid) {
        const obj = APP.world.eid2obj.get(eid);

        if (hasComponent(world, MediaVideo, obj.children[0].eid)) {
            const payload = {
                src: APP.getString(MediaVideo.originalSource[obj.children[0].eid]),
                recenter: false,
                resize: false,
                animateLoad: false,
                isObjectMenuTarget: false,
                equi: Equirectangular.toggled[eid]
            };

            console.log("Got video", payload);
            inflateVideoLoader(world, eid, payload);
        }
        if (hasComponent(world, MediaImage, obj.children[0].eid)) {
            const payload = {
                src: APP.getString(MediaImage.originalSource[obj.children[0].eid]),
                recenter: false,
                resize: false,
                animateLoad: false,
                isObjectMenuTarget: false,
                equi: Equirectangular.toggled[eid]
            };

            console.log("Got image", payload);
            inflateImageLoader(world, eid, payload);
        }

        // remove original
        world.eid2obj.get(eid).children = [];
    });
}
