import { defineQuery, removeComponent } from "bitecs";
import { MediaPDF, PDFSettingsChanged } from "../../bit-components";

const mediaPDFQuery = defineQuery([MediaPDF, PDFSettingsChanged]);
// const mediaPDFEnterQuery = enterQuery(mediaPDFQuery);

export function pdfSystem(world) {
    mediaPDFQuery(world).forEach(function (eid) {
        const pdf = world.eid2obj.get(eid);
        const newIndex = PDFSettingsChanged.newIndex[eid];
        console.log(newIndex);
        const pageRef = APP.getString(MediaPDF.pageRef[eid]);
        const canvas = pdf.material.map.image;
        const ctx = canvas.getContext("2d");

        pageRef.pdf.getPage(newIndex).then((page) => {
            const viewport = page.getViewport({ scale: 3 });
            page.render({
                canvasContext: ctx,
                viewport: viewport,
            }).promise.then(() => {
                pageRef.texture.needsUpdate = true;
            });
        });

        removeComponent(world, PDFSettingsChanged, eid);
    });
}
