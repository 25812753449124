import { addComponent } from "bitecs";
import { addObject3DComponent } from "../utils/jsx-entity";
import { Text as TroikaText } from "troika-three-text";
import { Text, Link } from "../bit-components";

export function inflateLink(world, eid, params) {
    addComponent(world, Link, eid);
    Link.url[eid] = APP.getSid(params.link);

    addComponent(world, Text, eid);
    const text = new TroikaText();
    text.text = params.link;
    text.textAlign = "center";
    text.overflowWrap = "break-word";
    text.maxWidth = 0.5;
    text.fontSize = 0.06;

    text.sync();
    addObject3DComponent(world, eid, text);

    return eid;
}
