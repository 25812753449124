import React from "react";
import ToolbarButton from "./ToolbarButton";
import { Tooltip } from "react-tooltip";

export default function ShareButton(props) {
    if (!props.tutorial && !window.APP.objectHelper.can("can_share_video")) {
        return null;
    }

    const {
        canShareCamera,
        cameraLoading,
        currentlySharingCamera,
        currentlySharingScreen,
        canShareScreen,
        toggleShareCamera,
        toggleShareScreen
    } = props.audioStatus.video;

    return (
        <>
            <Tooltip id="share-tooltips" />
            {canShareCamera && (
                <ToolbarButton
                    tipKey={"share-tooltips"}
                    disabled={cameraLoading}
                    title="share-camera"
                    loading={cameraLoading}
                    className={cameraLoading ? "border-0" : ""}
                    icon={
                        cameraLoading ? (
                            <>
                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                <span className="visually-hidden" role="status">
                                    Loading...
                                </span>
                            </>
                        ) : (
                            <i className="bi bi-camera-video-fill"></i>
                        )
                    }
                    onClick={toggleShareCamera}
                    variant={currentlySharingCamera ? "success" : "purple-dark"}
                    tipTitle={"Toggle Camera"}
                    tipBody={currentlySharingCamera ? "Stop sharing camera" : "Share webcam."}
                />
            )}
            {canShareScreen && (
                <ToolbarButton
                    tipKey={"share-tooltips"}
                    title="share-screen"
                    icon={<i className="bi bi-display" />}
                    onClick={toggleShareScreen}
                    tipTitle={"Toggle Screenshare"}
                    variant={currentlySharingScreen ? "success" : "purple-dark"}
                    tipBody={currentlySharingScreen ? "Stop screen sharing" : "Share screen."}
                />
            )}
        </>
    );
}
