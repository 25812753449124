import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Popover from "react-bootstrap/Popover";

import question_model from "../../../../assets/models/question.glb";
import { createNetworkedEntity } from "../../../../utils/create-networked-entity";
import { Tooltip } from "react-tooltip";
import { useAuth0 } from "@auth0/auth0-react";

export default function CreateMCQMenu(props) {
    const [inputFields, setInputFields] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

    const addQuestion = () => {
        // Create the question on server object

        const post_payload = {
            current_classroom: window.APP.classroom.id,
            mcqquestion_set: inputFields
        };

        const token = getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        }).then(token => {
            fetch(process.env.REACT_APP_MM_API_URL + "assessments/mcq/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(post_payload)
            })
                .then(resp => {
                    console.log(resp);
                    return resp.json();
                })
                .then(data => {
                    console.log(data);

                    const eid = createNetworkedEntity(APP.world, "question", {
                        object_id: null,
                        variant: "multiplechoice",
                        bundle: { mcqquestion_set: data.mcqquestion_set }
                    });

                    const avatarPov = document.querySelector("#avatar-pov-node").object3D;
                    const obj = APP.world.eid2obj.get(eid);
                    obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
                    obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

                    setTimeout(() => {
                        window.APP.objectHelper.save(eid, null, "question", null, "question", data.id);
                    }, 1000);

                    props.onFinish();
                    return eid;
                });
        });
    };

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["question"] = event.target.value;
        setInputFields(data);
    };

    const handleAnswerChange = (index, answerIndex, event) => {
        console.log("Got answer change on", index, answerIndex, event);
        let data = [...inputFields];
        console.log(data);
        data[index]["mcqanswer_set"][answerIndex]["answer"] = event.target.value;
        setInputFields(data);
    };

    const handleCorrectChange = (index, answerIndex, correct) => {
        let data = [...inputFields];
        data[index]["mcqanswer_set"][answerIndex]["correct"] = correct;
        setInputFields(data);
    };

    const addFields = () => {
        let newfield = {
            question: "Question",
            mcqanswer_set: [
                { answer: "Answer 1", correct: true },
                { answer: "Answer 2", correct: false },
                { answer: "Answer 3", correct: false },
                { answer: "Answer 4", correct: false }
            ]
        };
        setInputFields([...inputFields, newfield]);
    };

    const removeFields = index => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };

    const disabled = inputFields.length <= 0;

    return (
        <div className="p-3">
            <Tooltip id="addMediaTooltip" />
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Back to list"
                variant="light"
                onClick={() => props.onBack()}
            >
                <i className="bi bi-arrow-left"></i>
            </Button>
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Guide to Components"
                href="https://megaminds.tawk.help/article/components"
                target="_blank"
                variant="light"
            >
                <i className="bi bi-question-circle-fill"></i>
            </Button>
            <hr />
            <p className="fw-bold">Questions</p>
            <>
                <Row className="pb-1">
                    <Col>
                        {inputFields.map((input, index) => {
                            return (
                                <>
                                    <Row className="pb-1 mb-2" key={index}>
                                        <Col xs={10}>
                                            <input
                                                value={input.question}
                                                className="form-control"
                                                placeholder="What is your favorite color?"
                                                maxlength="440"
                                                type="text"
                                                onChange={event => handleFormChange(index, event)}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <button
                                                className="btn btn-danger w-100 d-flex justify-content-center align-content-center"
                                                onClick={() => removeFields(index)}
                                            >
                                                <i className="bi bi-trash-fill"></i>
                                            </button>
                                        </Col>
                                    </Row>
                                    <Row className="border-bottom pb-3 pt-3 mb-4" key={index}>
                                        {input.mcqanswer_set.map((answer, answerIndex) => (
                                            <>
                                                <Col xs={6}>
                                                    <div className="input-group mb-3">
                                                        <div
                                                            className={
                                                                answer.correct
                                                                    ? "bg-success input-group-text"
                                                                    : "bg-danger input-group-text"
                                                            }
                                                        >
                                                            <input
                                                                className="btn-success"
                                                                type="checkbox"
                                                                checked={answer.correct}
                                                                onClick={() =>
                                                                    handleCorrectChange(
                                                                        index,
                                                                        answerIndex,
                                                                        !answer.correct
                                                                    )
                                                                }
                                                            ></input>
                                                        </div>
                                                        <input
                                                            value={answer.answer}
                                                            className="form-control"
                                                            placeholder="What is your favorite color?"
                                                            type="text"
                                                            onChange={event =>
                                                                handleAnswerChange(index, answerIndex, event)
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                            </>
                                        ))}
                                    </Row>
                                </>
                            );
                        })}
                    </Col>
                </Row>
                <Row className="pb-1 pt-1">
                    <Col xs={12}>
                        <button
                            className="btn btn-primary w-100 d-flex justify-content-center align-content-center"
                            onClick={addFields}
                        >
                            + Add question
                        </button>
                    </Col>
                </Row>
                {/*
                <div className="mb-3 form-check">
                    <input
                        id="hideCheckbox"
                        className="form-check-input"
                        type="checkbox"
                        onChange={(e) =>
                            setHideQuestionObject(e.target.checked)
                        }
                    />
                    <label className="form-check-label" htmlFor="hideCheckbox">
                        Hide Questionmark in sessions (for scavenger hunts,
                        secret questions, etc)
                    </label>
                </div>
                */}
                <Row className="d-grid g-1 pt-3 border-top">
                    <Button variant="success" type="button" disabled={disabled} onClick={() => addQuestion()}>
                        Add
                    </Button>
                </Row>
            </>
        </div>
    );
}
