import React, { useEffect, useState, useRef } from "react";

import { defineQuery, hasComponent, addComponent, removeComponent, removeEntity } from "bitecs";
import {
    MediaFrame,
    MediaLoader,
    MediaPDF,
    IsLocalOnly,
    MediaImage,
    Link,
    Locked,
    Billboard,
    TextUpdated,
    Text,
    Owner,
    Question,
    StudentsCanMove,
    LiveFeed,
    MediaAudio,
    Nickname,
    Equirectangular,
    PDFSettingsChanged,
    Description,
    YouTube,
    SpinningAnimation
} from "../../../../bit-components";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";

import { toast } from "react-hot-toast";

const allMediaZones = defineQuery([MediaFrame]);

export default function QuickActions({ object, world }) {
    const clonedObjectPosition = useRef(object.position.clone());
    const clonedObjectRotation = useRef(object.rotation.clone());

    const [startedPlacementFlow, setStartedPlacementFlow] = useState(false);

    const setUpright = () => {
        object.rotation.set(0, object.rotation.y + 0.001, 0);
        object.updateMatrix();
        window.APP.objectHelper.change(object.eid);
    };

    const mirrorVertical = () => {
        object.rotateY((Math.PI / 180) * 180);
        object.updateMatrix();
        window.APP.objectHelper.change(object.eid);
    };

    const mirrorHorizontal = () => {
        object.rotateX((Math.PI / 180) * 180);
        object.updateMatrix();
        window.APP.objectHelper.change(object.eid);
    };

    const flip = (deg, axis) => {
        if (axis === "Y") {
            object.rotateY((Math.PI / 180) * deg);
        }
        if (axis === "X") {
            object.rotateX((Math.PI / 180) * deg);
        }
        if (axis === "Z") {
            object.rotateZ((Math.PI / 180) * deg);
        }
        object.updateMatrix();
        window.APP.objectHelper.change(object.eid);
    };

    const faceUser = () => {
        const target = document.getElementById("avatar-pov-node").object3D.parent.position;
        object.lookAt(target.x, object.position.y + 0.001, target.z);
        // object.rotation.set(0, object.rotation.y, 0);
        object.updateMatrix();

        window.APP.objectHelper.change(object.eid);
    };

    const updatePosition = (newPosition, newRotation) => {
        console.log(newPosition, newRotation);
        object.position.copy(newPosition);
        object.quaternion.copy(newRotation);
        object.updateMatrix();
        window.APP.objectHelper.change(object.eid);
        setStartedPlacementFlow(false);
    };

    if (startedPlacementFlow) {
        const frames = window.APP.classroom.scene.sceneposition_set;
        return (
            <ul>
                <Button variant="purple-dark" className="btn-sm" onClick={() => setStartedPlacementFlow(false)}>
                    <i className="bi bi-arrow-left"></i>
                    <span className="fw-bold ms-2">Back</span>
                </Button>
                <ButtonGroup vertical>
                    {frames.map(zone => {
                        console.log(zone, zone.position[0], zone.position[1], zone.rotation[3]);

                        const newPosition = new THREE.Vector3(zone.position[0], zone.position[1], zone.position[2]);
                        const newRotation = new THREE.Quaternion(
                            zone.rotation[0],
                            zone.rotation[1],
                            zone.rotation[2],
                            zone.rotation[3]
                        );

                        return (
                            <Button
                                variant="purple-dark"
                                className="btn-sm"
                                key={zone.id}
                                onClick={() => updatePosition(newPosition, newRotation)}
                                onMouseEnter={() => {
                                    object.position.copy(newPosition);
                                    object.quaternion.copy(newRotation);
                                    object.updateMatrix();
                                }}
                                onMouseLeave={() => {
                                    object.position.copy(clonedObjectPosition.current);
                                    object.quaternion.copy(clonedObjectRotation.current);
                                    object.updateMatrix();
                                }}
                            >
                                {zone.title}
                            </Button>
                        );
                    })}
                </ButtonGroup>
            </ul>
        );
    }

    return (
        <div>
            <div className="d-grid gap-2 d-md-block mb-3">
                <p className="fw-bold">Quick Actions</p>
            </div>
            <Row className="mt-3">
                <Col>
                    <div className="d-grid gap-2">
                        <Button
                            variant="purple-dark"
                            className="text-start btn-sm"
                            onClick={() => setStartedPlacementFlow(true)}
                        >
                            <i className="bi bi-bullseye" />
                            <span className="ms-2">Place ...</span>
                        </Button>
                        <Button variant="purple-dark" className="text-start btn-sm" onClick={() => setUpright()}>
                            <i className="bi bi-rulers" />
                            <span className="ms-2">Straighten</span>
                        </Button>
                        <Button variant="purple-dark" className="text-start btn-sm" onClick={() => faceUser()}>
                            <i className="bi bi-person-bounding-box" />
                            <span className="ms-2">Face Me</span>
                        </Button>
                    </div>
                </Col>
                <Col>
                    <div className="d-grid gap-2">
                        <Button variant="purple-dark" className="text-start btn-sm" onClick={() => mirrorVertical()}>
                            <i className="bi bi-symmetry-vertical" />
                            <span className="ms-2">Flip</span>
                        </Button>
                        <Button variant="purple-dark" className="text-start btn-sm" onClick={() => mirrorHorizontal()}>
                            <i className="bi bi-symmetry-horizontal" />
                            <span className="ms-2">Flip</span>
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
