import React from "react";
import { detect } from "detect-browser";

export function KeyHints() {
    const os = detect().os;

    return (
        <div
            className="position-fixed ms-4 me-4 ignore-cursor disable-select"
            style={{ right: 0, bottom: "92px" }}
        >
            <table>
                <tbody>
                    <tr>
                        <td className="text-end pe-2">
                            <span className="badge bg-semitransparent">
                                Click an Object
                            </span>
                        </td>
                        <td className="text-white">
                            <p className="fw-bold mb-0">Info</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-end pe-2">
                            <span className="badge bg-semitransparent">
                                Click
                            </span>
                            <span className="fw-bold text-white"> + </span>
                            <span className="badge bg-semitransparent">
                                Drag
                            </span>
                        </td>
                        <td className="text-white">
                            <p className="fw-bold mb-0">Look</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-end pe-2">
                            <span className="badge bg-semitransparent me-1">
                                W
                            </span>
                            <span className="badge bg-semitransparent me-1">
                                A
                            </span>
                            <span className="badge bg-semitransparent me-1">
                                S
                            </span>
                            <span className="badge bg-semitransparent">D</span>
                            <span className="fw-bold text-white"> or </span>
                            <span className="badge bg-semitransparent me-1">
                                Arrow Keys
                            </span>
                        </td>
                        <td className="text-white">
                            <p className="fw-bold mb-0">Move</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
