import { StickyNote } from "../../bit-components";
import { defineNetworkSchema } from "../../utils/define-network-schema";
import { deserializerWithMigrations, Migration, NetworkSchema, read, StoredComponent, write } from "../../utils/network-schemas";
import type { EntityID } from "../../utils/networking-types";

const runtimeSerde = defineNetworkSchema(StickyNote);

const migrations = new Map<number, Migration>();

function apply(eid: EntityID, { version, data }: StoredComponent) {
    if (version !== 1) return false;

    const { toggled }: { toggled: any } = data;
    write(StickyNote.toggled, eid, toggled);
    return true;
}

export const StickyNoteSchema: NetworkSchema = {
    componentName: "stickynote",
    serialize: runtimeSerde.serialize,
    deserialize: runtimeSerde.deserialize,
    serializeForStorage: function serializeForStorage(eid: EntityID) {
        return {
            version: 1,
            data: {
                toggled: read(StickyNote.toggled, eid)
            }
        };
    },
    deserializeFromStorage: deserializerWithMigrations(migrations, apply)
};
