import EventEmitter from "eventemitter3";
import { Room } from "livekit-client";

export const DIALOG_CONNECTION_CONNECTED = "dialog-connection-connected";
export const DIALOG_CONNECTION_ERROR_FATAL = "dialog-connection-error-fatal";

export class DialogAdapter extends EventEmitter {
    constructor() {
        super();

        console.info("Constructing RTC Adapter");
        this.room = undefined;
        this.connected = false;
        this._clientId = undefined;
    }

    async initialize({ clientId }) {
        this._clientId = clientId;

        this.room = new Room();
    }

    async connectToRoom(token) {
        const wsURL = process.env.REACT_APP_LIVEKIT_LINK;

        console.log("Attempting to connect to LiveKit", token.token);
        this.room.connect(wsURL, token.token).then(() => {
            // Turns microphone track on on connecting

            // TODO: Probably better to create a custom audiotrack than using the setMicrophoneEnabled wrapper, since
            // we might not want to actually enable and broadcast the mic yet.
            //
            console.log("Connecting to room on the basis of the following permissions", window.APP.classroom);
            
            this.room.localParticipant
                .setMicrophoneEnabled(window.APP.objectHelper.can("can_share_microphone"))
                .then(() => {
                    // Pass
                    if (window.APP.objectHelper.can("can_share_microphone")) {
                        // todo
                    }
                    setTimeout(() => {
                        window.APP.scene.emit("livekit_loaded");
                    });
                })
                .catch(error => {
                    console.log("Got error when trying to set mic enabled, most likely blocked");
                    setTimeout(() => {
                        window.APP.scene.emit("livekit_loaded");
                    }, 500);
                });

            this.connected = true;

            console.log("Connected to LiveKit");
            this.emit(DIALOG_CONNECTION_CONNECTED);
        });
    }

    getLiveKitUser(clientId) {
        return this.room.remoteParticipants.get(clientId);
    }

    async getMediaStream(clientId, kind = "audio") {
        if (!this.connected) {
            console.log("Attempted to get Media Stream before connecting");
        }
        // This function allows you to request a mediastream from yourself (local)
        // or a remote user that is also connected to livekit.

        let track;

        if (this._clientId === clientId) {
            // This is our own local stream
            console.log("Local user shared their stream", kind);

            if (kind === "audio") {
                track = this.room.localParticipant.getTrackPublication("microphone").track.mediaStream;
            } else if (kind === "video") {
                track = this.room.localParticipant.getTrackPublication("camera").track.mediaStream;
            } else if (kind === "screen") {
                track = this.room.localParticipant.getTrackPublication("screen_share").track.mediaStream;
            }
        } else {
            // Someone else in the room has shared a stream
            let user = this.getLiveKitUser(clientId);

            if (user && kind === "audio") {
                console.log("Remote user shared their microphone", kind, user);
                track = user.getTrackPublication("microphone").track.mediaStream;
            } else if (user && kind === "video") {
                console.log("Remote user shared their camera", kind, user);
                track = user.getTrackPublication("camera").track.mediaStream;
            } else if (user && kind === "screen") {
                console.log("Remote user shared their camera", kind, user);
                track = user.getTrackPublication("screen_share").track.mediaStream;
            }
        }

        if (track) {
            // Found mediastream successfully
            return Promise.resolve(track);
        } else {
            console.error("Could not get mediastream");
            return Promise.resolve(null);
        }
    }

    async enableMicrophone(enabled) {
        console.log("Setting microphone enabled to ", enabled);
        this.room.localParticipant.setMicrophoneEnabled(enabled);
        this.emit("mic-state-changed", { enabled });
    }

    async enableWebcam(enabled) {
        this.room.localParticipant.setCameraEnabled(enabled);
    }

    async enableScreenShare(enabled) {
        this.room.localParticipant.setScreenShareEnabled(enabled);
    }

    kick(clientId) {
        document.body.dispatchEvent(new CustomEvent("kicked", { detail: { clientId: clientId } }));
    }

    block(clientId) {
        document.body.dispatchEvent(new CustomEvent("blocked", { detail: { clientId: clientId } }));
    }

    unblock(clientId) {
        document.body.dispatchEvent(new CustomEvent("unblocked", { detail: { clientId: clientId } }));
    }
}
