import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-hot-toast";

export default function useAudioSystem(scene, permissionSystemLoaded) {
    const audioSystem = scene.systems["hubs-systems"].audioSystem;

    const [audioAndMicLoaded, setAudioAndMicLoaded] = useState(false);

    const [permissionStatus, setPermissionStatus] = useState("prompt");
    const [audioTrack, setAudioTrack] = useState(null);
    const [cameraLoading, setCameraLoading] = useState(null);
    const [screenLoading, setScreenLoading] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const [micMuted, setMicMuted] = useState(false);

    const [selectedMicDevice, setSelectedMicDevice] = useState(audioTrack?.id);
    const [selectedOutputDevice, setSelectedOutputDevice] = useState();
    const [selectedVideoDevice, setSelectedVideoDevice] = useState();

    const [availableMicDevices, setAvailableMicDevices] = useState([]);
    const [availableVideoDevices, setAvailableVideoDevices] = useState([]);
    const [availableOutputDevices, setAvailableOutputDevices] = useState([]);

    const [currentlySharingCamera, setCurrentlySharingCamera] = useState(false);
    const [currentlySharingScreen, setCurrentlySharingScreen] = useState(false);

    const [canShareCamera, setCanShareCamera] = useState();
    const [canShareScreen, setCanShareScreen] = useState(!!navigator.mediaDevices.getDisplayMedia);

    useEffect(() => {
        console.log("Audio Track Set", audioTrack);
    }, [audioTrack]);

    const buildDeviceLists = () => {
        navigator.mediaDevices.enumerateDevices().then(mediaDevices => {
            mediaDevices = mediaDevices.filter(d => d.label !== "");

            // Microphone List
            setAvailableMicDevices(
                mediaDevices
                    .filter(d => d.deviceId !== "default" && d.kind === "audioinput")
                    .map(d => ({
                        value: d.deviceId,
                        label: d.label || `Mic Device (${d.deviceId.substring(0, 9)})`
                    }))
            );

            // Webcam List
            const webcamDevices = mediaDevices
                .filter(d => d.deviceId !== "default" && d.kind === "videoinput")
                .map(d => ({
                    value: d.deviceId,
                    label: d.label || `Camera Device (${d.deviceId.substring(0, 9)})`
                }));

            setAvailableVideoDevices(webcamDevices);

            setCanShareCamera(webcamDevices.length > 0);

            // Speaker List
            const outputDevices = mediaDevices
                .filter(d => d.deviceId !== "default" && d.kind === "audiooutput")
                .map(d => ({
                    value: d.deviceId,
                    label: d.label || `Audio Output (${d.deviceId.substring(0, 9)})`
                }));
            setAvailableOutputDevices(outputDevices);

            APP.defaultOutputDeviceId = outputDevices.length > 0 ? outputDevices[0].value : "none";
        });
    };

    const onDeviceChange = event => {
        console.log("Got device change! Should refresh lists, etc.", event);
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(() => {
            buildDeviceLists();
        });
        toast.success("New device detected");
    };

    const enableMicrophone = () => {
        // Try to find the ideal microphone and set up constraints
        const preferredMic = APP.store.state.preferences.preferredMic;

        buildDeviceLists();

        if (audioTrack) {
            audioTrack.stop();
            setAudioTrack(null);
        }

        let constraints = { audio: {} };
        if (preferredMic) {
            constraints = { audio: { deviceId: { ideal: [preferredMic] } } };
        }
        constraints.audio.echoCancellation = !APP.store.state.preferences.disableEchoCancellation;
        constraints.audio.noiseSuppression = !APP.store.state.preferences.disableNoiseSuppression;
        constraints.audio.autoGainControl = !APP.store.state.preferences.disableAutoGainControl;

        // Set up local stream
        try {
            navigator.mediaDevices.getUserMedia(constraints).then(stream => {
                // We got a mic and stream, now lets share it
                // Connect local microphone audio stream to the audioSystem outbound audio
                audioSystem.addStreamToOutboundAudio("microphone", stream);
                const audioTrack = stream.getAudioTracks()[0];
                setAudioTrack(audioTrack);

                // Set the mic as preferred
                APP.store.update({
                    preferences: {
                        preferredMic: preferredMic
                    }
                });

                setAudioAndMicLoaded(true);
            });
        } catch (e) {
            // Error fetching audio track, most likely a permission denial.
            console.error("Error during getUserMedia: ", e);
            this.audioTrack = null;
            return false;
        }
    };

    useEffect(() => {
        if (permissionSystemLoaded) {
            // Audio Initialization

            console.log("Initializing Audio Tracks");

            if (window.APP.objectHelper.can("can_share_video")) {
                APP.scene.addEventListener("camera_entity_destroyed", () => {
                    console.log("Got video entity destroyed");
                    setCameraLoading(false);
                });

                APP.scene.addEventListener("camera_entity_created", () => {
                    console.log("Got video entity created");
                    setTimeout(() => {
                        // Prevent users from "unsharing" before the entity is loaded
                        setCameraLoading(false);
                    }, 1000);
                });
            }

            navigator.mediaDevices.addEventListener("devicechange", onDeviceChange);

            navigator.mediaDevices
                .getUserMedia({
                    audio: window.APP.objectHelper.can("can_share_microphone"),
                    video: window.APP.objectHelper.can("can_share_video")
                })
                .then(() => {
                    // Microphone and Camera Permissions gained, continue with microphone if permissions
                    setPermissionStatus("allowed");

                    if (window.APP.objectHelper.can("can_share_microphone") === false) {
                        // Still build device list for webcams even though mics are not allowed in the room
                        buildDeviceLists();
                        setAudioAndMicLoaded(true);
                    }

                    if (window.APP.objectHelper.can("can_share_microphone")) {
                        // Listen for microphone toggle states
                        APP.dialog.on("mic-state-changed", (obj) => {
                            console.log("MICSTATE CHANGED", obj);
                            setMicMuted(!obj.enabled);
                        });

                        enableMicrophone();
                    }
                })
                .catch(function (err) {
                    //log to console first
                    if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError") {
                        //required track is missing
                        setPermissionStatus("error");
                        console.log(err); /* handle the error */
                    } else if (err.name == "NotReadableError" || err.name == "TrackStartError") {
                        //webcam or mic are already in use
                        setPermissionStatus("error");
                        console.log(err); /* handle the error */
                    } else if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError") {
                        //constraints can not be satisfied by avb. devices
                        setPermissionStatus("error");
                        console.log(err); /* handle the error */
                    } else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError") {
                        // pass
                        setPermissionStatus("error");
                        console.log(err); /* handle the error */
                    } else if (err.name == "TypeError" || err.name == "TypeError") {
                        // pass
                        setPermissionStatus("error");
                        console.log(err); /* handle the error */
                    } else {
                        // Pass
                        setPermissionStatus("error");
                        console.log(err); /* handle the error */
                    }
                    setAudioAndMicLoaded(true);
                });
        }
    }, [selectedMicDevice, permissionSystemLoaded]);

    const changeOutputDevice = newDevice => {
        const mic = availableOutputDevices.filter(d => d.value === newDevice).map(d => d.label)[0];
        toast("Changing output to " + mic);
        // We only need to set the store here.
        // There is an event listener on store changes in AudioSystem that will set correct sink settings, etc.
        APP.store.update({
            preferences: {
                preferredSpeakers: newDevice
            }
        });

        APP.store.dispatchEvent(new CustomEvent("sinkchanged"));
    };

    const stopSharingCamera = () => {
        APP.dialog.enableWebcam(false);

        setCurrentlySharingCamera(false);

        scene.emit("camera_share_ended");

        setCameraLoading(false);
        return;
    };

    const changeInputDevice = newDevice => {
        setSelectedMicDevice(newDevice);
        const mic = availableMicDevices.filter(d => d.value === newDevice).map(d => d.label)[0];
        console.log(mic);
        toast("Changing input to " + mic);

        APP.store.update({
            preferences: {
                preferredMic: newDevice
            }
        });
    };

    const toggleMic = () => {
        APP.dialog.enableMicrophone(micMuted);
    };

    const stopSharingScreen = () => {
        APP.dialog.enableScreenShare(false);

        setCurrentlySharingScreen(false);
        scene.emit("share_video_disabled");
        scene.emit("screen_share_ended");
        scene.removeState("sharing_video");

        setScreenLoading(false);
        return;
    };

    const toggleShareScreen = useCallback(() => {
        window.APP.userHelper.register_event("com_share_screen");

        setScreenLoading(true);

        if (currentlySharingCamera) {
            console.log("Camera active, disable this first");
            stopSharingCamera();
        }

        if (currentlySharingScreen) {
            stopSharingScreen();
        } else {
            try {
                console.log("Trying toggle screen...");
                APP.dialog.room.localParticipant.setScreenShareEnabled(true).then(resp => {
                    const mediaStream = resp.track.mediaStream;

                    console.log("Sending stream to scene entry manager");
                    scene.emit("action_share_screen", { newStream: mediaStream });
                    setCurrentlySharingScreen(true);
                    setScreenLoading(false);
                });
            } catch (e) {
                console.log("Could not share screen!");
                scene.emit("screen_share_ended");
                setCurrentlySharingScreen(false);
                setScreenLoading(false);
            }
        }
    }, [scene, currentlySharingScreen, currentlySharingCamera]);

    const toggleShareCamera = useCallback(() => {
        window.APP.userHelper.register_event("com_share_webcam");

        setCameraLoading(true);

        if (currentlySharingScreen) {
            stopSharingScreen();
        }

        if (currentlySharingCamera) {
            stopSharingCamera();
        } else {
            try {
                APP.dialog.room.localParticipant.setCameraEnabled(true).then(resp => {
                    const mediaStream = resp.track.mediaStream;
                    scene.emit("action_share_camera", { newStream: mediaStream });
                    setCurrentlySharingCamera(true);
                    setCameraLoading(false);
                });
            } catch (e) {
                console.error("Could not share camera!", e);
                scene.emit("camera_share_ended");
                setCurrentlySharingCamera(false);
                setCameraLoading(false);
            }
        }
    }, [scene, currentlySharingCamera, currentlySharingScreen]);

    const audioStatus = {
        output: {
            availableOutputDevices,
            selectedOutputDevice
        },
        input: {
            audioTrack,
            selectedMicDevice,
            availableMicDevices
        },
        video: {
            selectedVideoDevice,
            availableVideoDevices,
            canShareCamera,
            canShareScreen,
            toggleShareScreen,
            toggleShareCamera,
            currentlySharingCamera,
            currentlySharingScreen,
            cameraLoading
        },
        permissionStatus,
        changeInputDevice,
        changeOutputDevice,
        toggleMic,
        micMuted
    };

    return [audioAndMicLoaded, audioStatus];
}
