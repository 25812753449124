export function createLegacyQuestionObject() {
    const container = document.createElement("div");
    container.className = "css2d-container";
    container.style.opacity = 100;
    container.style.pointerEvents = "none";
    const label = document.createElement("label");
    label.className = "css2d-label";
    label.textContent =
        "This question was created before the new and improved assessment system. Please create a new one to use the improved assessment dashboard and reporting tools.";

    container.appendChild(label);

    return container;
}
