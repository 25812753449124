import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ReactGA from "react-ga4";

export const useAssignment = code => {
    const [roomLoading, setRoomLoading] = useState(true);
    const [roomError, setRoomError] = useState(null);
    const [room, setRoom] = useState(null);
    const [counter, setCounter] = useState(0);

    const roomRefresh = () => {
        setCounter(counter + 1);
    };

    const fetchAsGuest = () => {
        fetch(process.env.REACT_APP_MM_API_URL + "assignments/inside/" + code)
            .then(resp => {
                console.log(resp);
                if (!resp.ok) {
                    setRoom(null);
                    setRoomLoading(false);
                    setRoomError(resp.status);
                    return;
                }
                return resp.json();
            })
            .then(data => {
                console.log(data);
                setRoom(data);
                setRoomLoading(false);
            })
            .catch(e => {
                console.error("Error", e);
                setRoom(null);
                setRoomError(e);
                setRoomLoading(false);
            });
    };

    useEffect(() => {
        fetchAsGuest();
    }, [counter, code]);

    return [room, roomLoading, roomError, roomRefresh];
};
